import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCategoryPopup, getProductCategories, deleteProductCategory } from '../../store/productCategorySlice';
import Icon from '../common/Icon';
import ProductCategoryPopup from './ProductCategoryPopup';
import InlinePopup from '../common/InlinePopup';
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';
import { useTranslation } from 'react-i18next';

export default function ProductCategories() {
    const { t } = useTranslation();
    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let dispatch = useDispatch();
    let [fieldSearch, setFieldSearch] = useState(null);
    let [keySearch, setKeySearch] = useState(null);
    let [orderBy, setOrderBy] = useState(null);
    let [pageSize, setPageSize] = useState(null);

    useEffect(() => {
        dispatch(getProductCategories({page: '1', keySearch: null}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let list = useSelector( state => state.productCategory.list);
    let payload = useSelector( state => state.productCategory.payload);

    const paginationOnChange = (page) => {
        dispatch(getProductCategories({page: page, fieldSearch: fieldSearch, keySearch: keySearch, size: pageSize, orderBy: orderBy}));
    }

    const fieldSearchList = [
        {value: 'name', label: t('category.name')},
        {value: 'description', label: t('category.description')},
    ];

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getProductCategories({keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy}));
    }
    
    const onFieldSearchChange = (input) => {
        setFieldSearch(input);
        dispatch(getProductCategories({keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy}));
    }

    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getProductCategories({keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input}));
    }

    const onPageSizeChange = (input) => {
        setPageSize(input);
        dispatch(getProductCategories({keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy}));
    }

    const createNewCategory = (e) => {
        e.preventDefault();
        dispatch(showCategoryPopup());
    };

    const editCategoryClick = (productCategory) => (e) => {
        e.preventDefault();
        dispatch(showCategoryPopup(productCategory));
    }

    const deleteCategoryClick = (productCategory) => (e) => {
        e.preventDefault();
        setSelected(productCategory);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="6">{ t('shared.noData') }</td>
                </tr>
            );
        }
        return list.map((productCategory, index) => {
            return (
                <tr key={index}>
                    <td className="important">{productCategory.name}</td>
                    <td>{productCategory.description}</td>
                    <td className="table-actions">
                        <a href="/#" onClick={ editCategoryClick(productCategory) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a href="/#" onClick={ deleteCategoryClick(productCategory) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">{ t('category.title') }</h3>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewCategory}>
                        <Icon size="16" icon="plus"/>
                        { t('category.newCategory') }
                    </a>
                </div>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                onPageSizeChange: onPageSizeChange,
                onFieldSearchChange: onFieldSearchChange,
                fieldSearchList: fieldSearchList,
                }) 
            }
            
            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th>{ t('category.name') }</th>
                            <th>{ t('category.description') }</th>
                            <th>{ t('shared.actions') }</th>
                        </thead>
                        <tbody>
                            { renderRows() }
                        </tbody>
                    </table>
                </div>

                { renderPagination() }
            </div>

            <ProductCategoryPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title={ t('category.popupTitle') }
                message={ t('category.popupMessage') }
                action={deleteProductCategory(selected)} />
        </div>
    );
}