import { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import ChangeLanguage from "../i18n/ChangeLanguage"
import { useTranslation } from 'react-i18next';
import InlinePopup from "../common/InlinePopup";

export default function Menu() {
  const { t } = useTranslation();
  let superAdmin = false;
  let handbooqAdmin = false;
  let handbooqProductAdmin = false;
  let publisherAdmin = false;
  let publisherProductManager = false;
  let dispatch = useDispatch();
  let name = useSelector(state => state.auth?.access?.name);
  
  let [showingConfirmation, setShowConfirmation] = useState(false);
  let version = useSelector(state => state.auth.version);
  if (version !== '3') {
    dispatch(logout());
  }

  useSelector(state => {
    let role = state.auth.role;
    let type = state.auth.type;
    

    if (role === "Admin") {
      superAdmin = (type === 'SuperAdmin') ? true : false;
      handbooqAdmin = (type === 'Admin') ? true : false;
      handbooqProductAdmin = (type === 'SubAdmin') ? true : false;
      publisherAdmin = false;
      publisherProductManager = false;
    }

    if (role === "Staff") {
      superAdmin = false;
      handbooqAdmin = false;
      handbooqProductAdmin = false;
      publisherAdmin = (type === 'Admin') ? true : false;
      publisherProductManager = (type === 'SubAdmin') ? true : false;
    }
  });

  const showPopupConfirmation = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  }

  const hidePopupConfirmation = (e) => {
    e.preventDefault();
    setShowConfirmation(false);
  }

  function SideNavItem({ title, icon, path, disabled }) {

    let match = useRouteMatch({
      path: path,
      exact: false,
      strict: false
    });

    let imgSrc = '/img/sidebar_' + icon + '.svg';

    return (
      <Link to={path} className={match ? "active" : "" + (disabled ? " disabled" : "")}>
        <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src={imgSrc} alt="icon-sidebar" />
         <span className="nav-menu-text">{title}</span>
      </Link>
    );
  }

  const superAdminSidebar = () => {
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <SideNavItem title={t('sideBar.admins')} icon="profile" path="/admins" />
        </li>
        <li >
          <a className="nav-menu-text" href="/#" onClick={showPopupConfirmation}>
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src='/img/sidebar_logout.svg' alt="sidebar-logout" />
            {t('sideBar.signOut')}
          </a>
        </li>
      </ul>
    );
  }

  const handbooqAdminSidebar = () => {
    return (
      <ul className="navbar-nav mr-auto">
        {/* <li className="nav-item">
                    <SideNavItem title={t('sideBar.profile')} icon="profile" path="/admins/profile" />
                </li> */}
        <li className="nav-item">
          <SideNavItem title={t('sideBar.publishers')} icon="profile" path="/publishers" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.staffs')} icon="profile" path="/staffs" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.products')} icon="product" path="/products" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.productCategories')} icon="product" path="/product-categories" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.authors')} icon="profile" path="/authors" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.students')} icon="profile" path="/students" />
        </li>
        {/* <li className="nav-item">
                    <SideNavItem title={t('sideBar.transactions')} icon="profile" path="/transactions" />
                </li> */}
        <li >
          <a className="nav-menu-text" href="/#" onClick={showPopupConfirmation}>
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src='/img/sidebar_logout.svg' alt="sidebar=logout" />
            {t('sideBar.signOut')}
          </a>
        </li>
      </ul>
    );
  }

  const handbooqProductAdminSidebar = () => {
    return (
      <ul className="navbar-nav mr-auto">
        {/* <li className="nav-item">
                    <SideNavItem title={t('sideBar.profile')} icon="profile" path="/admins/profile" />
                </li> */}
        <li className="nav-item">
          <SideNavItem title={t('sideBar.publishers')} icon="profile" path="/publishers" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.staffs')} icon="profile" path="/staffs" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.products')} icon="product" path="/products" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.productCategories')} icon="product" path="/product-categories" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.authors')} icon="profile" path="/authors" />
        </li>
        <li >
          <a className="nav-menu-text" href="/#" onClick={showPopupConfirmation}>
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src='/img/sidebar_logout.svg' alt="sidebar-logout" />
            {t('sideBar.signOut')}
          </a>
        </li>
      </ul>
    );
  }

  const publisherAdminSidebar = () => {
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <SideNavItem title={t('sideBar.products')} icon="product" path="/products" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.profile')} icon="profile" path="/profile" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.students')} icon="profile" path="/students" />
        </li>
        {/* <li className="nav-item">
                    <SideNavItem title={t('sideBar.transactions')} icon="profile" path="/transactions" disablejd="true"
                    />
                </li> */}
        <li >
          <a className="nav-menu-text" href="/#" onClick={showPopupConfirmation}>
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src='/img/sidebar_logout.svg' alt="sidebar-logout" />
            {t('sideBar.signOut')}
          </a>
        </li>
      </ul>
    );
  }

  const publisherProductManagerSidebar = () => {
    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <SideNavItem title={t('sideBar.products')} icon="product" path="/products" />
        </li>
        <li className="nav-item">
          <SideNavItem title={t('sideBar.profile')} icon="profile" path="/profile" />
        </li>
        <li >
          <a className="nav-menu-text" href="/#" onClick={showPopupConfirmation}>
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="icon-sidebar" src='/img/sidebar_logout.svg' alt="sidebar-logout" />
            {t('sideBar.signOut')}
          </a>
        </li>
      </ul>
    );
  }

  return (
    <>
      <Link to="/product" className="sidebar-brand nav-user-name">
        { name }
      </Link>

      <nav className="sidebar-content">
        {(superAdmin ? superAdminSidebar() : null)}
        {(handbooqAdmin ? handbooqAdminSidebar() : null)}
        {(handbooqProductAdmin ? handbooqProductAdminSidebar() : null)}
        {(publisherAdmin ? publisherAdminSidebar() : null)}
        {(publisherProductManager ? publisherProductManagerSidebar() : null)}
      </nav>

      <InlinePopup
        isShowing={showingConfirmation}
        hide={hidePopupConfirmation}
        title={t('shared.signOutTitle')}
        message={t('shared.signOutMessage')}
        action={logout()} />

      {/* <ChangeLanguage /> */}
    </>
  );
}
