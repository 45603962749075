const renderThead = (thead) => {
  if (thead.length === 0) {
    return (
      <th colSpan='100%'>NO DATA</th>
    )
  }

  if (!thead.map) {
    return (
      <th colSpan='100%'>RENDER THEAD ERROR !</th>
    )
  } else {
    return thead.map((key, value) => {
      return (
        <th> {key} </th>
      )
    })
  }
}

const renderBody = (data) => {
  if (data.length === 0) {
    return (
      <tr>
        <td colSpan="100%">NO DATA</td>
      </tr>
    )
  }

  if (!data.map) {
    return (
      <tr>
        <td colSpan='100%'>RENDER TABLE DATA ERROR !</td>
      </tr>
    )
  } else {
    return data.map((key, value) => {
      return (
        <tr>
          <td> { key.no }</td>
          <td> { key.name }</td>
          <td> { key.stat }</td>
        </tr>
      )
    })
  }
}

export default function DashboardTable({thead = [], data = []}) {

  return (
    <div className='card'>
      <div className='table-responsive'>
        <table className='table table-stripped'>
          <thead>
            <tr>
              { renderThead(thead) }
            </tr>
          </thead>
          <tbody>
            { renderBody(data) }
          </tbody>
        </table>
      </div>
    </div>
  )
}
