import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPublisherPopup, getPublishers, deletePublisher } from '../../store/publisherSlice';
import Icon from '../common/Icon';
import PublisherPopup from './PublisherPopup';
import InlinePopup from '../common/InlinePopup';
import Pagination from '../common/Pagination'
import SearchBar from '../common/SearchBar';
import { useTranslation } from 'react-i18next';

export default function Publishers() {
    const { t } = useTranslation();
    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let [fieldSearch, setFieldSearch] = useState(null);
    let [keySearch, setKeySearch] = useState(null);
    let [orderBy, setOrderBy] = useState(null);
    let [pageSize, setPageSize] = useState(null);

    let list = useSelector( state => state.publisher.list );
    let payload = useSelector(state => state.publisher.payload);
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPublishers({page: '1', keySearch: null}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fieldSearchList = [
        {value: 'name', label: t('publisher.searchBar.fieldSearch.name')},
        {value: 'companyName', label: t('publisher.searchBar.fieldSearch.companyName')}
    ];

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getPublishers({keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy}));
    }
    
    const onFieldSearchChange = (input) => {
        setFieldSearch(input);
        dispatch(getPublishers({keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy}));
    }
    
    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getPublishers({keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input}));
    }
    
    const onPageSizeChange = (input) => {
        setPageSize(input);
        dispatch(getPublishers({keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy}));
    }

    const paginationOnChange = (page) => {
        dispatch(getPublishers({page: page, fieldSearch: fieldSearch, keySearch: keySearch, size: pageSize, orderBy: orderBy}));
    }

    const createNewPublisher = (e) => {
        e.preventDefault();
        dispatch(showPublisherPopup());
    };

    const editPublisherClick = (publisher) => (e) => {
        e.preventDefault();
        dispatch(showPublisherPopup(publisher));
    }

    const deletePublisherClick = (publisher) => (e) => {
        e.preventDefault();
        setSelected(publisher);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="7">{ t('shared.noData') }</td>
                </tr>
            );
        }
        return list.map((publisher, index) => {
            let logo = (publisher.logo === null) ? "/img/unupload-logo.svg" : publisher.logo.link;
            let superAdmin = (publisher.id === 14) ? true : false;
            return (
                <tr key={index}>
                    <td className="important">{publisher.name}</td>
                    {/* <td>{ publisher.companyName }</td> */}
                    <td>{ publisher.companyDescription }</td>
                    <td>
                        <img className="logo" src={ logo } alt="logo" 
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}/>
                    </td>
                    <td className="table-actions">
                        <a href="/#" onClick={ editPublisherClick(publisher) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a className={(superAdmin) ? 'disabled' : ''} href="/#" onClick={ deletePublisherClick(publisher) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">{ t('publisher.title') }</h3>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewPublisher}>
                        <Icon size="16" icon="plus"/>
                        { t('publisher.newPublisher') }
                    </a>
                </div>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                onPageSizeChange: onPageSizeChange,
                onFieldSearchChange: onFieldSearchChange,
                fieldSearchList: fieldSearchList
                }) 
            }

            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th className="sortable sort-asc">{ t('publisher.name') }</th>
                            {/* <th>{ t('publisher.companyName') }</th> */}
                            <th>{ t('publisher.companyDescription') }</th>
                            <th>{ t('publisher.logo')}</th>
                            <th>{ t('shared.actions') }</th>
                        </thead>
                        <tbody>
                            { renderRows() }
                        </tbody>
                    </table>
                </div>

                { renderPagination() }
            </div>

            <PublisherPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title={ t('publisher.popupTitle') } 
                message={ t('publisher.popupMessage') }
                action={deletePublisher(selected)} />
        </div>
    );
}