import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const ProductSlice = createSlice({
    name: 'product',
    initialState: {
        coverImg: null,
        coverImgLink: null,
        corruptedPdf: null,
        loadingUploadPdf: false,
        loadingUploadImg: false,
        disableCreateBtn: false,
        error: null,
        list: [],
        form: null,
        listChanged: false,
        deleteMessage: '',
        payload: null,
        loading: false,
        pdfError: null,
        imageError: null,
    },
    reducers: {
        productsRequested: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.listChanged = false;
            state.coverImg = null;
            state.corruptedPdf = null;
            state.form = null;
            state.loading = false;
        },
        productsReceived: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.list = action.payload.data;
            state.listChanged = false;
            state.payload = action.payload;
            state.loading = false;
        },
        productsError: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.error = action.payload.error;
            state.listChanged = false;
            state.loading = false;
        },
        productRequested: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.listChanged = false;
            state.corruptedPdf = null;
            state.coverImg = null;
            state.loading = true;
        },
        productReceived: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.form = action.payload;
            state.listChanged = false;
            state.coverImg = null;
            state.corruptedPdf = null;
            state.loading = false;
        },
        productError: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.listChanged = false;
            state.coverImg = null;
            state.corruptedPdf = null;
            state.loading = false;
        },
        productCreated: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.form = null;
            state.coverImg = null;
            state.corruptedPdf = null;
            state.listChanged = true;
            state.loading = false;
        },
        productUpdated: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.form = null;
            state.corruptedPdf = null;
            state.coverImg = null;
            state.listChanged = true;
            const index = state.list.findIndex(product => product.id === action.payload.id);
            state.list[index] = action.payload;
            state.loading = false;
        },
        productDeleted: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.list = state.list.filter(product => product.id !== action.reData.id);
            state.deleteMessage = action.payload.message;
            state.loading = false;
        },
        coverImageRequested: (state) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = true;
            state.disableCreateBtn = true;
            state.coverImgLink = null;
            state.coverImg = null;
            state.loading = false;
            state.imageError = null;
        },
        coverImageUploaded: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.coverImgLink = action.payload.link;
            state.coverImg = action.payload.id;
            state.loading = false;
        },
        coverImageError: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.coverImg = null;
            state.coverImgLink = null;
            state.imageError = action.payload.error;
            state.loading = false;
        },
        pdfRequested: (state, action) => {
            state.loadingUploadPdf = true;
            state.loadingUploadImg = false;
            state.disableCreateBtn = true;
            state.loading = false;
            state.pdfError = null;
        },
        pdfUploaded: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.corruptedPdf = action.payload.id;
            state.loading = false;
            state.pdfError = null;
        },
        pdfError: (state, action) => {
            state.loadingUploadPdf = false;
            state.loadingUploadImg = false;
            state.disableCreateBtn = false;
            state.corruptedPdf = null;
            state.pdfError = action.payload.error;
            state.loading = false;
        }
    }
});

const {
    productsRequested,
    productsReceived,
    productsError,
    productRequested,
    productReceived,
    productError,
    productCreated,
    productUpdated,
    productDeleted,
    coverImageRequested,
    coverImageUploaded,
    coverImageError,
    pdfRequested,
    pdfUploaded,
    pdfError,
} = ProductSlice.actions;

const getProducts = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=title';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v2/products/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: productsRequested.type,
                onSuccess: productsReceived.type,
                onError: productsError.type
            }
        )
    );
}

const getProduct = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/' + data,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: productRequested.type,
                onSuccess: productReceived.type,
                onError: productError.type
            }
        )
    );
}

const uploadCoverImage = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    let coverImageFormData = new FormData();
    coverImageFormData.append('coverImage', data.img);
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/upload_cover_image',
                method: 'POST',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'multipart/form-data'
                },
                data: coverImageFormData,
                onStart: coverImageRequested.type,
                onSuccess: coverImageUploaded.type,
                onError: coverImageError.type
            }
        )
    );
}

const uploadPdf = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    let pdfFormData = new FormData();
    pdfFormData.append('pdf', data.pdf);
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v2/products/upload_pdf',
                method: 'POST',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'multipart/form-data'
                },
                data: pdfFormData,
                onStart: pdfRequested.type,
                onSuccess: pdfUploaded.type,
                onError: pdfError.type
            }
        )
    );
}

const updateProduct = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: productRequested.type,
                onSuccess: productUpdated.type,
                onError: productError.type
            }
        )
    );
}

const deleteProduct = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: productRequested.type,
                onSuccess: productDeleted.type,
                onError: productError.type
            }
        )
    );
}

const createProduct = (data) => (dispatch, getState) => {
    // prod default image
    if (data.coverImageId === null) { data.coverImageId = 690 };
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/',
                method: 'POST',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: productRequested.type,
                onSuccess: productCreated.type,
                onError: productError.type
            }
        )
    )
}

const publishProduct = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/products/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: {
                    status: 'published'
                },
                onStart: productRequested.type,
                onSuccess: productUpdated.type,
                onError: productError.type
            }
        )
    );
}

export { publishProduct, getProducts, getProduct, updateProduct, deleteProduct, createProduct, uploadCoverImage, uploadPdf };

export default ProductSlice.reducer;