const ErrorPage = () => {
  return (
    <div className="background-404-page">
      <div className='p-3'>
        <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className='img-fluid' src="/img/landing-page/logo.svg" alt="404-background"/>
      </div>   
    </div>
  )
}

export default ErrorPage;