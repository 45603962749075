import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login } from '../../store/authSlice';

export default function CustomerLogin() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken != null) {
            goToAdminPortal();
            return;
        }
    });

    let loading = useSelector(state => state.auth.loading);

    const dispatch = useDispatch();

    const goToAdminPortal = () => {
        history.push('/');
    }

    const onLogin = (e) => {
        e.preventDefault();

        if (username.length > 0 && password.length > 0) {
            dispatch(
                login('customer', username, password)
            );
        }
    }

    return (
        <div className="wrapper-login background-login">
            <div className="card-login">
                <div className="">
                    <h3 className="card-title mb-3">Login</h3>
                    <form>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        <div className="actions">
                            <button className="btn btn-primary btn-login" onClick={onLogin}>Login</button>

                            {loading &&
                                <div className="loading">
                                    <div className="threedots">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="actions mt-3">
                            <p>Don't have an account? <Link to="/sign-up">Register</Link> </p>
                        </div>

                        {/* { facebookLoginButton() }

                        { googleLoginButton() } */}
                        
                    </form>
                </div>
            </div>
        </div>
    );
}