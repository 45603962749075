import * as actions from "../api";
import { showMessage } from "../uiSlice";
import axios from "axios";

const defaultHeaders = {
    'X-App-Token': process.env.REACT_APP_APP_TOKEN,
};

const api = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== actions.apiCallBegan.type) {
        return next(action);
    }

    const { url, method, data, headers, onStart, onSuccess, onError } = action.payload;

    if (onStart) {
        dispatch({ type: onStart });
    }

    next(action);

    try {
        const response = await axios.request({
            baseURL: `${process.env.REACT_APP_BASE_URL}`,
            url,
            method,
            data,
            headers: { ...defaultHeaders, ...headers }
        });
        dispatch(actions.apiCallSuccess(response.data));
        if (onSuccess) {
            if (data?.responseAlert) {
                dispatch(showMessage({ type: 'success', message: data?.responseAlert }));
            } 
            if (response.data.message) {
                dispatch(showMessage({ type: 'success', message: response.data.message }));
            }
            dispatch({ type: onSuccess, payload: response.data, reData: data });
        }
    } catch (error) {
        dispatch(actions.apiCallFailed({ error: error.message }));
        let message = (typeof error?.response?.data?.errors === 'undefined') ? error.message : error?.response?.data?.errors[0]?.message;
        dispatch(showMessage({type: 'error', message: message }));
        if (onError) {
            dispatch({ type: onError, payload: { error: message } });
        }
    }
};

export default api;
