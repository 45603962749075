// import { useTranslation } from 'react-i18next';

const Policy = () => {
  // const { t } = useTranslation();

  return (
    <div className="container">
      <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="img/logo.svg" className='img img-fluid mb-3 mt-3' alt="handbooq-logo" />
      <div className='privacy-policy'>
        <h1 className='text-center mb-3'>นโยบายคุ้มครองข้อมูลส่วนบุคคล</h1>
        <div>
          ผู้ให้บริการแอปพลิเคชัน Handbooq (“เรา”) เคารพและให้ความสำคัญในสิทธิ์ความเป็นส่วนตัว และมีความมุ่งมั่นที่จะดำเนินการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการแอปพลิเคชันของเรา (“ท่าน”) โดยเราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่ท่านได้มอบให้แก่เราด้วยความไว้วางใจ เราจึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น เพื่อชี้แจงให้ท่านมั่นใจว่า ข้อมูลส่วนบุคคลที่ท่านมอบให้แก่เราจะถูกนำไปใช้ตรงตามความต้องการของท่าน ถูกต้องตามกฎหมาย และรักษาให้ปลอดภัยตามมาตรฐานสากลในการคุ้มครองข้อมูลส่วนบุคคล
        </div>
        <section>
          <h2 className='bg-secondary p-2'>1.  เราเก็บข้อมูลส่วนบุคคลอะไรบ้าง</h2>
          <div>
            <div>
              <span className='ml-4'>
                เราจะเก็บรวบรวม ใช้ เผยแพร่ (“ประมวลผล”) ข้อมูลส่วนบุคคลที่จำเป็นจะต้องใช้เพื่อให้บริการแก่ท่าน ซึ่งอยู่ในรูปแบบข้อมูลอิเล็กทรอนิกส์ โดยอาจให้ท่านกรอกข้อมูลลงในแพลตฟอร์มที่เราได้กำหนด ซึ่งได้แก่ข้อมูล ดังนี้
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.1  ข้อมูลส่วนบุคคลที่ได้รับจากการยืนยันตัวตน และการลงทะเบียนกับเรา และข้อมูลโปรไฟล์ โดยหากท่านเข้าถึง ในฐานะผู้ใช้ที่ลงทะเบียน เราจะรวบรวมข้อมูลที่ท่านให้กับเราเกี่ยวกับบัญชีของท่าน (โดยทั่วไปคือชื่อผู้ใช้  อายุ อีเมล รหัสผ่าน)
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.2  ข้อมูลการติดต่อ ในกรณีที่ท่านให้ข้อเสนอแนะหรือติดต่อเรา หรือให้ข้อมูลโดยสมัครใจแก่เรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.3  ข้อมูลเกี่ยวกับการชำระเงิน โดยหากท่านเป็นลูกค้าที่ชำระเงิน ข้อมูลนี้จำเป็นสำหรับการทำธุรกรรมเชิงพาณิชย์โดย เราใช้ข้อมูลนี้เพื่อเปิดใช้งานและดำเนินการธุรกรรมของท่าน หากท่านเลือกที่จะใช้บริการของบุคคลที่สามเพื่อสรุปผลและชำระเงินสำหรับการสั่งซื้อ ท่านจะต้องให้หมายเลขบัตรเครดิตของท่านกับบุคคลที่สามโดยตรง ซึ่งจะเป็นไปตามเงื่อนไขและนโยบายความเป็นส่วนตัวของบุคคลที่สาม
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.4  ข้อมูลประวัติการค้นหา เช่น ข้อมูลการเรียกดู ข้อมูลการขอใช้บริการ การตอบสนองต่อการโฆษณาของเรา รวมถึง เนื้อหาที่ท่านเข้าชม ลิงค์ที่กดเข้าชม ฟีเจอร์ (Features) ที่ท่านใช้
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.5  ข้อมูลการทำแบบสำรวจ แบบสอบถาม แบบประเมินความพึงพอใจ ข้อเสนอแนะ และข้อมูลการสำรวจความคิดเห็น รายละเอียดเกี่ยวกับการใช้สิทธิ์ และการร้องเรียนเกี่ยวกับบริการ หรือรายการอื่นในทำนองเดียวกัน
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.6  ข้อมูลการโต้ตอบ และข้อมูลการสื่อสาร รวมถึงข้อมูลที่ท่านเลือกจะแบ่งปันผ่านแอปพลิเคชัน และบริการต่าง ๆ ของเรา ไม่ว่าจะในรูปแบบ หรือวิธีใด ๆ ก็ตาม ซึ่งอาจเป็นภาพ หรือเสียงโดยไม่จำกัดเพียง โทรศัพท์ อีเมล ข้อความสนทนา และการสื่อสารทางสื่อสังคมออนไลน์ (Social Media)
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.7  ข้อมูลที่เรารวบรวมโดยอัตโนมัติ ตัวระบุและข้อมูลอุปกรณ์ โดยเมื่อท่านใช้บริการใด ๆ ของเรา เราจะรวบรวมข้อมูลบางอย่างเกี่ยวกับท่านโดยอัตโนมัติ รวมถึงประเภทเบราว์เซอร์ของท่าน และภาษา การตั้งค่าสถานที่ (ประเทศและภาษาที่ต้องการ)  ข้อมูลคุกกี้ และข้อมูลเกี่ยวกับอุปกรณ์ของท่าน รวมถึงประเภทและรุ่น ผู้ผลิต ระบบปฏิบัติการ รวมถึงเรายังรวบรวมข้อมูลเกี่ยวกับการโต้ตอบของแอพ เบราว์เซอร์ และอุปกรณ์ของท่าน รวมถึงที่อยู่อินเทอร์เน็ตโปรโตคอล รายงานข้อขัดข้อง กิจกรรมของระบบ และวันที่ เวลา และ URL อ้างอิงของคำขอของท่าน
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.8  ข้อมูลการใช้งานของท่าน โดยเราบันทึกกิจกรรมของท่านบนแอปพลิเคชันของเราโดยอัตโนมัติ เช่น การเปิดใช้งาน การคลิก การบันทึกภาพหน้าจอ การซื้อ การดาวน์โหลด การอ่าน การเขียน การจดบันทึก การให้คะแนนหนังสือ ระยะเวลาการใช้งาน บุ๊กมาร์ก การจัดการชั้นวางหนังสือ การแบ่งปันและการค้นหา รวมถึงการค้นหา หนังสือหรือคำสำคัญ) ประวัติการซื้อ ความคิดเห็นเกี่ยวกับหนังสือ การตั้งค่าการวิเคราะห์และการปรับปรุง และรายละเอียดอื่นๆ เกี่ยวกับการใช้
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>2.  เราเก็บข้อมูลส่วนบุคคลอ่อนไหวของท่านหรือไม่</h2>
          <div>
            <div>
              <span className='ml-4'>
                ไม่ว่ากรณีใด เราไม่มีนโยบายเก็บข้อมูลส่วนบุคคลอ่อนไหวของท่าน ที่เกี่ยวกับเชื้อชาติ เผ่าพันธ์ุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือข้อมูลอื่นใดซึ่งกระทบต่อท่านในทำนองเดียวกันตามประกาศของคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>3.  เราได้รับข้อมูลส่วนบุคคลของท่านจากช่องทางไหน</h2>
          <div>
            <span className='ml-4'>เราเก็บรวบรวมและรับข้อมูลส่วนบุคคลของท่านผ่านช่องทางต่าง ๆ ดังนี้</span>
          </div>
          <div>
            <span className='ml-5'>
              3.1  ข้อมูลส่วนบุคคลที่ท่านให้ไว้กับเราโดยตรง จากตอนที่ท่านสมัครใช้บริการ เข้าทำสัญญา โดยรวมถึงขั้นตอนที่ท่านดำเนินการยื่นคำร้องขอใช้สิทธิ์ต่างๆ กับเรา หรือเมื่อท่านติดต่อสื่อสาร สอบถามข้อมูล ให้ความเห็น หรือคำติชม ไม่ว่าจะเป็นอยู่ในรูปแบบลายลักษณ์อักษรหรือวาจา ผ่านทางเว็บไซต์ แอปพลิเคชัน โทรศัพท์ อีเมล หรือโดยวิธีการอื่นใด
            </span>
          </div>
          <div>
            <span className='ml-5'>
              3.2  เราอาจได้รับข้อมูลส่วนบุคคลของท่านมาจากบุคคลที่สาม เช่น สถาบันการศึกษา องค์กรหรือหน่วยงานอื่นใดที่มีสิทธิ หน้าที่ในการเปิดเผยข้อมูล หรือที่เราอาจได้รับมาจากช่องทางสื่อสังคมออนไลน์ (social media) แพลตฟอร์มออนไลน์ของบุคคลที่สาม รวมถึงพันธมิตรทางธุรกิจที่มีหน้าที่ให้บริการท่านร่วมกับบริการของเราและที่ท่านเป็นสมาชิกอยู่กับพันธมิตรเหล่านั้น โดยท่านประสงค์ให้พันธมิตรเหล่านั้นเปิดเผยข้อมูลของท่านแก่เรา
            </span>
          </div>
          <div>
            <span className='ml-5'>
              3.3  ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่านโดยอัตโนมัติ ผ่านช่องทางต่าง ๆ เช่น การใช้คุกกี้ (Cookies) หรือเทคโนโลยีอื่น ๆ ที่คล้ายคลึงกัน
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>4.  วิธีการคุ้มครองข้อมูลส่วนบุคคลสำหรับท่านที่มีอายุต่ำกว่า 10 ปี</h2>
          <div>
            <span className='ml-4'>
              ในกรณีที่ท่านเป็นผู้เยาว์ที่มีอายุต่ำกว่า 10 ปี หรือเป็นคนไร้ความสามารถ หรือคนเสมือนไร้ความสามารถ  ซึ่งต้องได้รับความยินยอมจากบิดามารดา ผู้ปกครอง ผู้อนุบาล หรือผู้พิทักษ์ (แล้วแต่กรณี) เราแนะนำให้บุคคลผู้มีอำนาจปกครองเหล่านั้นเป็นผู้สมัครแทนท่านเท่านั้น  ซึ่งเราเชื่อโดยสุจริตว่าข้อมูลที่เราได้รับจากบุคคลเหล่านั้น เป็นข้อมูลที่เรามีสิทธิ์ประมวลผลข้อมูลส่วนบุคคล และบุคคลเหล่านั้นมีสิทธิ์เปิดเผยให้แก่เราได้
            </span>
          </div>
          <div>
            <span className='ml-4'>
              ผู้มีอำนาจปกครองสามารถเลือกดำเนินการสมัครสมาชิก และเลือกรับข่าวสาร กิจกรรมเกี่ยวกับการตลาดแทนท่านได้ โดยหากได้ตกลงที่จะรับข้อมูลข่าวสารดังกล่าวจากเราแล้ว ภายหลังผู้มีอำนาจปกครองมีสิทธิ์ยกเลิกความยินยอมดังกล่าวได้ทุกเมื่อ โดยสามารถดำเนินการยกเลิกความยินยอมในการรับแจ้งข้อมูลข่าวสารได้ โดยการแก้ไขความยินยอม โดยเข้าไปที่ การตั้งค่าในแอปพลิเคชัน หรือกรณีไม่ต้องการรับอีเมลหรือจดหมายอื่น ๆ จากเรา โปรดคลิกลิงก์ <span className='text-danger'>"ยกเลิกการสมัคร"</span> ในจดหมายโต้ตอบทางอีเมลที่ได้รับ รวมถึงหากประสงค์จะใช้สิทธิ์ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลสามารถดำเนินการตามรายละเอียดที่ระบุในนโยบายฉบับนี้
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>5.  วิธีการคุ้มครองข้อมูลส่วนบุคคลสำหรับท่านที่อายุ 10 – 20 ปี</h2>
          <div>
            <span className='ml-4'>
              ในกรณีที่ท่านเป็นผู้เยาว์ที่มีอายุตั้งแต่ 10 -20 ปี เราอาจประมวลผลข้อมูลของท่านโดยคำนึงถึงการคุ้มครองข้อมูลส่วนบุคคลอย่างสูงสุด ซึ่งเรากำหนดเงื่อนไขให้ท่านสามารถดำเนินการสมัครสมาชิกได้ด้วยตนเองโดยไม่ต้องได้รับความยินยอมจากผู้ปกครอง แต่อย่างไรก็ตาม ท่านต้องให้การรับรองและรับประกันว่าท่านกำลังใช้บริการของเรา โดยได้แจ้งรายละเอียดการใช้บริการ และการให้ความยินยอมให้ผู้ปกครองของท่านทราบแล้ว
            </span>
          </div>
          <div>
            <span className='ml-4'>
              ทั้งนี้ หากในอนาคตท่านไม่ประสงค์จะรับข้อมูลและข่าวสารประชาสัมพันธ์ ท่านสามารถถอนความยินยอมที่ท่านเคยให้ไว้โดยเข้าไปที่ การตั้งค่าในแอปพลิเคชัน หรือหากท่านไม่ต้องการรับอีเมลหรือจดหมายอื่น ๆ จากเราโปรดคลิกลิงก์ <span className='text-danger'>"ยกเลิกการสมัคร"</span> ในจดหมายโต้ตอบทางอีเมลที่ท่านได้รับ 
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>6.  เราใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อะไร</h2>
          <div><span className='ml-4'>เราจะประมวลผลข้อมูลส่วนบุคคลที่จำเป็นต่อการดำเนินงานของเรา โดยมีวัตถุประสงค์ ดังนี้</span></div>
          <table className='table table-bordered'>
            <thead className='text-center'>
              <tr>
                <th>ลำดับ</th>
                <th>วัตถุประสงค์ที่กำหนด</th>
                <th>ฐานทางกฎหมาย</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(1)</td>
                <td>
                  เพื่อแจ้งให้ท่านทราบเกี่ยวกับการปรับปรุงบริการของเรา ข้อกำหนดในการให้บริการ แจ้งหนังสือเล่มใหม่ แคมเปญ ข้อเสนอและข้อมูลการตลาดอื่น ๆ เกี่ยวกับผลิตภัณฑ์และบริการของเรา เราจะขอความยินยอมจากท่านเพื่อส่งข้อมูลส่วนลด โปรโมชันพิเศษ ข่าวสาร ประชาสัมพันธ์ กิจกรรมการตลาด โดยการแจ้งเตือน ผ่านอีเมล อย่างไรก็ตาม ท่านสามารถเพิกถอนความยินยอมของท่านได้ตลอดเวลาคลิกลิงก์ <span className='text-danger'>"ยกเลิกการสมัคร"</span> ในจดหมายโต้ตอบทางอีเมลที่ท่านได้รับ
                </td>
                <td>ฐานความยินยอม</td>
              </tr>
              <tr>
                <td>(2)</td>
                <td>
                  เพื่อแสดงเนื้อหาที่เชื่อมโยงกับบัญชีของท่าน และตรวจสอบให้แน่ใจว่าสามารถใช้ได้เมื่อท่านใช้บริการของเรา
                </td>
                <td>ฐานการปฏิบัติตามสัญญา</td>
              </tr>
              <tr>
                <td>(3)</td>
                <td>
                  เพื่อช่วยเราตรวจจับการละเมิด การฉ้อโกง และกิจกรรมที่ผิดกฎหมาย  รวมถึงการคำนวณสถิติรวมเกี่ยวกับจำนวนอุปกรณ์ที่ใช้บริการของเรา และตรวจจับจำนวนครั้งการบันทึกภาพหน้าจอ และป้องกันการฉ้อโกงและการใช้อุปกรณ์เหล่านั้นในทางที่ผิด
                </td>
                <td>ฐานการปฏิบัติตามสัญญา/ฐานประโยชน์โดยชอบธรรม</td>
              </tr>
              <tr>
                <td>(4)</td>
                <td>
                  เพื่อตรวจสอบความถูกต้อง และใช้ในการยืนยันหรือระบุตัวตนของท่านก่อนการใช้บริการ และประมวลผลในขั้นตอนการลงทะเบียนการสมัครสมาชิก
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา
                </td>
              </tr>
              <tr>
                <td>(5)</td>
                <td>
                  เพื่ออำนวยความสะดวกในการซื้อของท่านและตรวจสอบชื่อจริงของท่านเพื่ออำนวยความสะดวกในการสร้างบัญชีชำระเงินของท่าน
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา
                </td>
              </tr>
              <tr>
                <td>(6)</td>
                <td>
                  เพื่อตรวจสอบประวัติการใช้งานของท่าน และปรับปรุงบริการของเราให้ดียิ่งขึ้น
                </td>
                <td>
                  ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(7)</td>
                <td>
                  เพื่อแจ้งข้อมูลข่าวสารอื่นใดที่เกี่ยวข้องกับการให้บริการประเภทเดียวกันกับที่ท่านมีอยู่กับเรา ซึ่งเป็นประโยชน์กับท่าน ผ่านการแจ้งเตือนในแอปพลิเคชัน
                </td>
                <td>
                  ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(8)</td>
                <td>
                  เพื่อการบริหารจัดการคำสั่งซื้อ ส่งมอบ ติดตาม จัดส่ง การเปลี่ยน จัดเตรียมบริการ รวมถึงการจัดการในเรื่องที่เกี่ยวข้องกับการปฏิบัติตามสัญญา ซึ่งหากไม่ได้ดำเนินการแล้วจะกระทบต่อการให้บริการของเรา หรือจะทำให้เราไม่สามารถให้บริการได้อย่างเป็นธรรมและต่อเนื่อง
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา/ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(9)</td>
                <td>
                  เพื่อดำเนินการให้เกิดผลสำเร็จของธุรกรรม การหักบัญชี และตรวจสอบความถูกต้องของเลขที่บัญชี และหมายเลขบัตรเครดิต หรือเดบิต และธุรกรรมที่เกี่ยวข้องกับการชำระเงิน การคืนเงิน การออกใบสำคัญรับเงิน ใบเสร็จรับเงิน ใบแจ้งหนี้ ใบกำกับภาษี ตามประมวลรัษฎากรและกฎหมายหรือประกาศอื่นใดที่เกี่ยวข้อง
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา/ฐานการปฏิบัติตามกฎหมาย
                </td>
              </tr>
              <tr>
                <td>(10)</td>
                <td>
                  การรับเรื่องร้องเรียน รับข้อเสนอแนะ ติดต่อสื่อสาร การทำแบบสอบถาม การสำรวจความเห็นเกี่ยวกับผลิตภัณฑ์และบริการ การดำเนินการตามคำสั่ง คำร้องขอ รวมถึงเพื่อบริหารจัดการความสัมพันธ์ เช่น ดูแลลูกค้า ประเมินความพึงพอใจ การให้คำปรึกษา การให้คำชี้แจง การตอบข้อสงสัย
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา/ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(11)</td>
                <td>
                  เพื่อวัตถุประสงค์ในการปฏิบัติตามกฎหมาย กฎระเบียบ ข้อบังคับ ทั้งในและต่างประเทศที่ใช้บังคับและที่เกี่ยวข้องกับธุรกิจของเรา รวมถึงการปฏิบัติตามคำสั่งโดยชอบด้วยกฎหมายของหน่วยงานของรัฐและเจ้าหน้าที่ที่เกี่ยวข้อง ผู้มีอำนาจตามกฎหมาย เช่น คำสั่งศาล หน่วยงานที่มีหน้าที่กำกับดูแล หรือพนักงานเจ้าหน้าที่ผู้มีอำนาจ
                </td>
                <td>
                  ฐานการปฏิบัติตามกฎหมาย
                </td>
              </tr>
              <tr>
                <td>(12)</td>
                <td>
                  เพื่อวัตถุประสงค์ในการก่อตั้งสิทธิเรียกร้องตามกฎหมาย การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องของเรา ในขั้นตอนต่าง ๆ ตามกฎหมาย เช่น การสอบสวน การไต่สวนโดยเจ้าหน้าที่รัฐ การเตรียมคดี การดำเนินคดี และ/หรือการต่อสู้คดีในชั้นศาล เป็นต้น
                </td>
                <td>
                  ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(13)</td>
                <td>
                  เพื่อการเรียกเก็บเงิน หรือหนี้ที่ค้างชำระ การเข้าทำธุรกรรม การดำเนินการรับชำระเงิน จัดการกับข้อเรียกร้องและข้อพิพาท รวมถึงการดำเนินการแก้ไขข้อพิพาท การก่อตั้งสิทธิเรียกร้องตามกฎหมาย การใช้สิทธิ์ หรือการโต้แย้งข้อเรียกร้องทางกฎหมาย การดำเนินคดีต่าง ๆ ตลอดจนการดำเนินการเพื่อบังคับคดีตามกฎหมาย
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา/ฐานประโยชน์โดยชอบธรรม
                </td>
              </tr>
              <tr>
                <td>(14)</td>
                <td>
                  เพื่อปฏิบัติตามหลักเกณฑ์ทางธุรกิจอันสมควร เช่น การจัดทำสถิติการใช้งานแบบไม่เฉพาะเจาะจงบุคคล การตรวจสอบ การรายงาน การควบคุม หรือบริหารความเสี่ยง การวิเคราะห์และวางแผนแนวโน้ม หรือกิจกรรมอื่น ๆ ที่เกี่ยวข้องหรือคล้ายคลึงกัน
                </td>
                <td>ฐานประโยชน์โดยชอบธรรม</td>
              </tr>
              <tr>
                <td>(15)</td>
                <td>
                  เพื่อประกาศเกี่ยวกับผลิตภัณฑ์ และการอัปเดตซอฟต์แวร์ แจ้งเตือนการใช้บริการ เมื่อใกล้สิ้นสุดระยะเวลาตามสัญญา การสร้าง และรักษาบัญชีผู้ใช้ รวมถึงการประมวลผล การตรวจสอบการใช้บริการ และการปิดบัญชีผู้ใช้
                </td>
                <td>
                  ฐานการปฏิบัติตามสัญญา
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>7.  เหตุใดเราจึงต้องเก็บข้อมูลส่วนบุคคลของท่าน</h2>
          <div>
            <span className='ml-4'>
              เรามีความจำเป็นต้องประมวลผลข้อมูลส่วนบุคคล ในกรณีที่ท่านสมัครลงทะเบียนใช้บริการของเรา เนื่องจาก เราต้องใช้ข้อมูลเพื่อประกอบการทำสัญญา หากท่านไม่ประสงค์ที่จะให้ข้อมูลส่วนบุคคลดังกล่าวแก่เรา อาจมีผลกระทบต่อหน้าที่ของเราตามกฎหมาย หรืออาจทำให้เราไม่สามารถปฏิบัติหน้าที่ตามสัญญาได้อย่างถูกต้อง และ/หรือ อาจทำให้มีข้อขัดข้อง ไม่สมบูรณ์ ในการปฏิบัติหน้าที่ตามข้อตกลงเงื่อนไขในสัญญาระหว่างเรากับท่านได้  
            </span>
          </div>
          <div>
            <span className='ml-4'>
              อย่างไรก็ตาม ท่านมีความเป็นอิสระในการให้ความยินยอม โดยเราจะไม่กำหนดเงื่อนไขในการให้ความยินยอม มาเป็นส่วนหนึ่งของการสมัครใช้บริการ ซึ่งท่านสามารถมั่นใจได้ว่าเราจะใช้ข้อมูลตามวัตถุประสงค์ที่เรากำหนด หรือที่ได้รับความยินยอมจากท่านเท่านั้น โดยในกรณีที่เราจะดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่านในลักษณะ และ/หรือ วัตถุประสงค์ที่ไม่สอดคล้องกับที่กำหนด เราจะจัดให้มีนโยบายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลเพิ่มเติม และประกาศให้ท่านทราบทางเว็ปไซต์หรือมีหนังสือไปยังท่านทางอีเมลเพื่ออธิบายการประมวลผลข้อมูลในลักษณะดังกล่าว  
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>8.  เราแบ่งปันข้อมูลส่วนบุคคลของท่านหรือไม่</h2>
          <div>
            <span className='ml-4'>เราอาจแบ่งปันข้อมูลส่วนบุคคลของท่านต่อบุคคล หรือองค์กร ดังต่อไปนี้</span>
          </div>
          <div>
            <span className='ml-4'>
              8.1  กลุ่มพันธมิตรทางธุรกิจซึ่งที่มีข้อตกลงร่วมกันเกี่ยวกับการเชื่อมโยงข้อมูลเพื่อวัตถุประสงค์ในการให้บริการแก่ท่าน หรือตามที่ท่านร้องขอ ได้แก่ กรณีที่ท่านลงทะเบียนเรียนกับ บริษัท อิกไนท์ เอ็ดดูเคชั่น จำกัด และบริษัท ออนดีมานด์ เอ็ดดูเคชั่น จำกัด เป็นต้น
            </span>
          </div>
          <div>
            <span className='ml-4'>
              8.2  ผู้ให้บริการและผู้ประมวลผลข้อมูลส่วนบุคคลที่เรามอบหมายหรือว่าจ้างให้ทำหน้าที่บริหารจัดการหรือประมวลผลข้อมูลส่วนบุคคล ในการให้บริการต่าง ๆ เพื่อดำเนินวัตถุประสงค์ที่เกี่ยวข้องตามที่ระบุในนโยบายฉบับนี้ และมีความจำเป็นต้องได้รับข้อมูลส่วนบุคคลของท่าน เช่น ผู้ให้บริการที่เก็บข้อมูลบนคลาวด์ เป็นต้น
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>9.  เราส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปต่างประเทศหรือไม่</h2>
          <div>
            <span className='ml-4'>
              เราอาจเก็บข้อมูลของท่านบนคอมพิวเตอร์เซิร์ฟเวอร์ หรือคลาวด์ที่ให้บริการโดยบุคคลอื่น และอาจใช้โปรแกรมหรือแอปพลิเคชันของบุคคลอื่นในรูปแบบของการให้บริการซอฟท์แวร์และรูปแบบของการให้บริการแพลตฟอร์มในการประมวลผลข้อมูลส่วนบุคคล แต่เราจะไม่อนุญาตให้บุคคลที่ไม่เกี่ยวข้องสามารถเข้าถึงข้อมูลส่วนบุคคลได้ และจะกำหนดให้บุคคลอื่นเหล่านั้นต้องมีมาตรการคุ้มครองความมั่นคงปลอดภัยที่เหมาะสม 
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>10.  การโฆษณา</h2> 
          <div>
            <span className='ml-4'>
              เราอนุญาตให้บริษัทอื่น ๆ ที่เรียกว่าผู้โฆษณาบุคคลที่สามและเครือข่ายโฆษณา ให้บริการโฆษณาภายในบริการของเราเพื่อส่งโฆษณาตามความสนใจเกี่ยวกับผลิตภัณฑ์และบริการของบุคคลที่สามแก่ท่าน ผู้โฆษณาและเครือข่ายโฆษณาบุคคลที่สามเหล่านี้ใช้เทคโนโลยีเพื่อส่งโฆษณาและลิงก์ที่ปรากฏในบริการของเราโดยตรงถึงท่าน เมื่อท่านใช้บริการของเรา เครื่องมือของบุคคลที่สาม ที่รวมอยู่ในบริการของเราอาจรวบรวมและใช้ข้อมูลบางอย่างที่รวบรวมจากอุปกรณ์ของท่านเพื่อวัตถุประสงค์ในการแสดงโฆษณา
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>11.  เราเก็บข้อมูลส่วนบุคคลของท่านไว้นานเท่าไหร่</h2>
          <div>
            <span className='ml-4'>
              11.1  	เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านตราบเท่าที่บัญชีของท่านยังใช้งานอยู่หรือเท่าที่จำเป็นเพื่อให้บริการของเราแก่ท่าน หากท่านต้องการยกเลิกบัญชีของท่านหรือขอให้เราไม่ใช้ข้อมูลส่วนบุคคลของท่านเพื่อให้บริการแก่ท่านอีกต่อไป โปรดติดต่อเราที่ <a href='mailto:handbooq@ondemand.in.th'>handbooq@ondemand.in.th</a> 
            </span>
          </div>
          <div>
            <span className='ml-4'>
              11.2  เมื่อพ้นระยะเวลาที่กำหนดแล้ว เราจะดำเนินการลบ ทำลาย ทำให้ข้อมูลส่วนบุคคลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ หรือดำเนินการอื่นใดตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด เพื่อให้การคุ้มครองข้อมูลส่วนบุคคลเป็นไปอย่างมีประสิทธิภาพ อย่างไรก็ตาม เราจะเก็บรักษาข้อมูลบางอย่างไว้นานกว่าที่ระบุข้างต้น หากจำเป็นที่จะต้องปฏิบัติตามกฎหมาย หรือการตรวจสอบเกี่ยวกับเรื่องลิขสิทธิ์ หรือเป็นการปฏิบัติตามคำสั่งของเจ้าพนักงาน หรือหน่วยงานของรัฐที่มีอำนาจผู้เกี่ยวข้อง และเพื่อวัตถุประสงค์ทางธุรกิจหรือโดยชอบตามกฎหมาย เช่น เพื่อความปลอดภัย เพื่อการป้องกันการละเมิดหรือการประพฤติมิชอบ เป็นต้น  
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>12.  ท่านมีสิทธิ์ใดบ้างตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</h2>
          <div>
            <span className='ml-4'>
              เราเคารพสิทธิส่วนบุคคลของท่าน และเปิดโอกาสให้ท่านสามารถใช้สิทธิ์ตามที่กฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลกำหนด ดังต่อไปนี้
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>1)	สิทธิ์ขอเพิกถอนความยินยอม:</span> 
              หากท่านได้ให้ความยินยอมในการประมวลผลข้อมูลส่วนบุคคล (ไม่ว่าจะเป็นความยินยอมที่ท่านให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น)  ท่านมีสิทธิ์ที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับเรา โดยการเพิกถอนความยินยอมไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมไว้แล้วโดยชอบ เว้นแต่มีข้อจำกัดสิทธิ์นั้นโดยกฎหมาย หรือโดยสภาพไม่สามารถถอนความยินยอมได้ หรือมีสัญญาระหว่างท่านกับเราที่ให้ประโยชน์แก่ท่านอยู่ หรืออาจส่งผลให้เราไม่สามารถดำเนินการเพื่อบรรลุวัตถุประสงค์บางส่วนหรือทั้งหมดตามที่ระบุไว้ในเอกสารฉบับนี้ได้
            </span>
          </div>
          <div>
            <span className='ml-5'>
              ทั้งนี้ การถอนความยินยอมของท่านอาจส่งผลกระทบต่อท่าน จากการใช้บริการต่าง ๆ เช่น ท่านจะไม่ได้รับสิทธิประโยชน์ โปรโมชั่น หรือข้อเสนอใหม่ ๆ ไม่ได้รับบริการที่สอดคล้องกับความต้องการของท่าน หรือไม่ได้รับข้อมูลข่าวสารอันเป็นประโยชน์ เป็นต้น เพื่อประโยชน์ของท่าน จึงควรศึกษาและสอบถามถึงผลกระทบก่อนใช้สิทธิ์ขอถอนความยินยอม
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>2)	สิทธิ์ในการเข้าถึงข้อมูลส่วนบุคคล:</span>
              ท่านมีสิทธิ์ขอเข้าถึงข้อมูลส่วนบุคคลของท่านที่อยู่ในความรับผิดชอบของเรา และขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่ท่าน รวมถึงขอให้เราแบ่งปันว่าเราได้ข้อมูลส่วนบุคคลของท่านมาได้อย่างไร เว้นแต่กรณีที่เรามีสิทธิ์ปฏิเสธคำขอของท่านตามกฎหมาย หรือคำสั่งของศาล หรือกรณีที่คำขอของท่านจะมีผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>3)	สิทธิ์ในการให้โอนย้ายข้อมูลส่วนบุคคล:</span>
              ท่านมีสิทธิ์ขอให้โอนข้อมูลส่วนบุคคลของท่านในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ และสามารถใช้หรือแบ่งปันข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิ์ขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิ์ขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยัง ผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>4)	สิทธิ์ขอคัดค้าน:</span>
              ท่านมีสิทธิ์ขอคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านในเวลาใดก็ได้ หากการประมวลผลข้อมูลส่วนบุคคลของท่านทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเรา หรือของบุคคลหรือนิติบุคคลอื่นโดยไม่เกินขอบเขตที่ท่านสามารถคาดหมายได้อย่างสมเหตุสมผล หรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์ หรือเพื่อวัตถุประสงค์เกี่ยวกับการตลาด หรือเพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ
            </span>
          </div>
          <div>
            <span className='ml-4'>
              หากท่านยื่นคัดค้าน เราจะยังคงดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่านต่อไป เฉพาะที่เราสามารถแสดงเหตุผลตามกฎหมายได้ว่ามีความสำคัญยิ่งกว่าสิทธิ์ขั้นพื้นฐานของท่าน หรือเป็นไปเพื่อการยืนยันสิทธิ์ตามกฎหมาย การปฏิบัติตามกฎหมาย หรือการต่อสู้ในการฟ้องร้องตามกฎหมาย ตามแต่ละกรณี  
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>5)	สิทธิ์ขอให้ลบหรือทำลายข้อมูล:</span>
              ท่านมีสิทธิ์ขอลบหรือทำลายข้อมูลส่วนบุคคลของท่าน หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้ หากท่านเชื่อว่าข้อมูลส่วนบุคคลของท่านถูกประมวลผลโดยไม่ถูกต้องตามกฎหมายที่เกี่ยวข้อง หรือเห็นว่าเป็นกรณีที่เราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อเราเห็นว่าสามารถปฏิบัติตามที่ท่านได้ใช้สิทธิ์ขอถอนความยินยอมหรือใช้สิทธิ์ขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>6)	สิทธิ์ขอให้ระงับการใช้ข้อมูล:</span>
              ท่านมีสิทธิ์ขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิ์ขอแก้ไขข้อมูลส่วนบุคคล หรือขอคัดค้านของท่าน หรือกรณีอื่นใด ที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของท่านตามกฎหมายที่เกี่ยวข้องแต่ท่านขอให้เราระงับการใช้ข้อมูลส่วนบุคคลแทน
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>7)	สิทธิ์ขอให้แก้ไขข้อมูล:</span>
              หากท่านเห็นว่า ข้อมูลส่วนบุคคลของท่านไม่ถูกต้องตามที่เป็นจริง ท่านสามารถแจ้งเราเพื่อให้แก้ไข หรือเปลี่ยนแปลงข้อมูลส่วนบุคคลของท่านให้ถูกต้อง หรือเพิ่มเติมข้อมูลที่ให้เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิดได้
            </span>
          </div>
          <div>
            <span className='ml-4'>
              ทั้งนี้ หากท่านประสงค์จะขอแก้ไขข้อมูลเกี่ยวกับภาพ เราจะทำการแก้ไขเฉพาะรายการข้อมูลที่เกี่ยวกับภาพของท่านเพื่อให้ถูกต้อง ตามความจำเป็นของเราที่ชอบด้วยกฎหมาย และในกรณีที่การดำเนินการตามคำขอก่อให้เกิดค่าใช้จ่ายเราอาจเรียกเก็บค่าใช้จ่ายดังกล่าว โดยในกรณีเรามีเหตุให้ปฏิเสธคำร้องขอของท่าน เราจะจัดทำบันทึกการปฏิเสธคำขอ พร้อมด้วยเหตุผลไว้เป็นหลักฐานด้วย
            </span>
          </div>
          <div>
            <span className='ml-4'>
              <span className='font-weight-bold'>8)	สิทธิ์ร้องเรียน:</span>
              ท่านมีสิทธิ์ร้องเรียนต่อเราผ่านช่องทางเว็ปไซต์ โดยส่งอีเมลมาที่ <a href="mailto:handbooq@ondemand.in.th">handbooq@ondemand.in.th</a> หากท่านเชื่อว่าการประมวลผลข้อมูลส่วนบุคคล เป็นการกระทำในลักษณะที่ฝ่าฝืน หรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>13.  ท่านจะใช้สิทธิ์ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้อย่างไร</h2>
          <div>
            <span className='ml-4'>
              13.1 ในกรณีที่ท่านประสงค์จะจัดการถอนความยินยอมที่ท่านให้ไว้ ท่านสามารถกดเข้าไปที่ ลิงก์ <span className='text-danger'>"ยกเลิกการสมัคร"</span> ในจดหมายโต้ตอบทางอีเมลที่ท่านได้รับ หรือในกรณีที่ท่านจะใช้สิทธิ์อื่นๆ ตามที่ระบุในข้อ 12 ท่านสามารถส่งอีเมลมาที่ <a href='mailto:handbooq@ondemand.in.th'>handbooq@ondemand.in.th</a> โดยเราจะพิจารณาและแจ้งผลการพิจารณาตามคำร้องของท่านภายใน 30 วัน นับแต่วันที่เราได้รับคำร้องขอดังกล่าว อย่างไรก็ตาม เราสามารถปฏิเสธการใช้สิทธิ์ของท่านได้ภายใต้เงื่อนไขตามที่กฎหมายกำหนด ทั้งนี้ หากเราไม่สามารถดำเนินการตามคำขอของท่านได้ เราจะทำการบันทึกการปฏิเสธคำขอพร้อมด้วยเหตุผลไว้
            </span>
          </div>
          <div>
            <span className='ml-4'>
              13.2 เราจะพยายามอย่างเต็มที่ตามความสามารถของระบบงานที่เกี่ยวข้องเพื่ออำนวยความสะดวกและดำเนินการตามคำร้องขอของท่าน เว้นแต่จะปรากฏข้อเท็จจริงว่า การดำเนินการตามคำร้องขอนั้นเสี่ยงต่อการละเมิดสิทธิและเสรีภาพของผู้ใช้งานรายอื่น หรือเป็นการขัดต่อกฎหมาย หรือนโยบายความปลอดภัยของระบบ หรือกรณีที่เป็นการพ้นวิสัยในทางปฏิบัติตามคำร้องขอเนื่องมาจากเหตุทางเทคนิค  
            </span>
          </div>
          <div>
            <span className='ml-4'>
              13.3 ในบางสถานการณ์เราอาจขอให้ท่านพิสูจน์ตัวตนของท่านก่อนการใช้สิทธิ์เพื่อความปลอดภัยของท่านเอง โดยบางครั้งอาจเกิดข้อจำกัดในการขอใช้สิทธิ์ของท่านบางประการหรืออาจเกิดค่าใช้จ่าย ซึ่งเราจะทำการชี้แจงให้ท่านทราบหากไม่สามารถปฏิบัติตามคำร้องขอใช้สิทธิ์ของท่านได้ หรือจะแจ้งให้ท่านทราบ หากเราจำเป็นต้องเรียกเก็บค่าใช้จ่ายเกี่ยวกับการดำเนินการตามที่ท่านร้องขอ
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>14.  เราดูแลข้อมูลส่วนบุคคลของท่านให้ปลอดภัยอย่างไร</h2>
          <div>
            <span className='ml-4'>
              เรากำหนดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลที่เหมาะสม เพื่อป้องกันการเข้าถึง การใช้ การเปลี่ยนแปลง การแก้ไข หรือการแบ่งปันข้อมูลส่วนบุคคลโดยไม่มีอำนาจหรือโดยขัดต่อกฎหมาย แม้ว่าเราจะใช้ความพยายามโดยสุจริตในการจัดเก็บข้อมูลที่รวบรวมบนบริการในสภาพแวดล้อมการทำงานที่ปลอดภัย แต่เราไม่สามารถรับประกันการรักษาความปลอดภัยอย่างสมบูรณ์ของข้อมูลนั้นในระหว่างการส่งหรือการจัดเก็บบนระบบของเรา เราไม่รับประกันหรือรับรองว่าข้อมูลของท่านจะได้รับการปกป้องจากการสูญหาย การใช้ในทางที่ผิด หรือการเปลี่ยนแปลงโดยบุคคลที่สาม เนื่องจากไม่มีวิธีการส่งผ่านอินเทอร์เน็ตหรือวิธีการจัดเก็บข้อมูลอิเล็กทรอนิกส์ที่ปลอดภัย 100%
            </span>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>15.  ท่านจะติดต่อสอบถามข้อมูลเพิ่มเติมได้อย่างไร</h2>
          <div>
            <span className='ml-4'>
              ในกรณีที่ท่านเห็นว่าการประมวลผลข้อมูลส่วนบุคคล ไม่เป็นไปตาม พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ 2562 หรือมีข้อเสนอแนะ ข้อสงสัย หรือต้องการสอบถามข้อมูลเกี่ยวกับรายละเอียดการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงการขอใช้สิทธิ์ตามนโยบายฉบับนี้ ท่านสามารถติดต่อสอบถาม หรือร้องเรียนได้ผ่านช่องทาง ดังนี้
            </span>
          </div>
          <div className='ml-5'>
            <table className='table table-borderless'>
              <tr>
                <td>อีเมล </td>
                <td>: DPO@Learn.co.th หรือ <a href='mailto:handbooq@ondemand.in.th'>handbooq@ondemand.in.th</a></td>
              </tr>
              <tr>
                <td>ที่อยู่ </td>
                <td>: 444 อาคารเอ็ม บี เค ทาวเวอร์ ชั้น 14 ถนนพญาไท แขวงวังใหม่ เขตปทุมวัน</td>
              </tr>
              <tr>
                <td></td>
                <td>กรุงเทพฯ 10330</td>
              </tr>
            </table>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>16.  นโยบายคุ้มครองข้อมูลส่วนบุคคลจะมีการแก้ไขเปลี่ยนแปลงหรือไม่</h2>
          <div>
            <span className='ml-4'>
              เราจะทำการพิจารณาทบทวนนโยบายคุ้มครองข้อมูลส่วนบุคคลเป็นประจำเพื่อให้สอดคล้องกับแนวทางการปฏิบัติ และกฎหมาย ข้อบังคับที่เกี่ยวข้อง ทั้งนี้ หากมีการเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ เราจะแจ้งให้ท่านทราบด้วยการอัพเดทข้อมูลลงในแอปพลิเคชันของเราโดยเร็วที่สุด ดังนั้น เราจึงขอแนะนำให้ท่านอ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลทุกครั้งที่เยี่ยมชม หรือใช้บริการจากเรา  
            </span>
          </div>
          <div className='mb-4'>ปัจจุบัน นโยบายคุ้มครองข้อมูลส่วนบุคคล ประกาศใช้เมื่อวันที่  01/06/2565</div>
        </section>
      </div>
    </div>
  );
};

export default Policy;