import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProducts, deleteProduct, publishProduct } from '../../store/productSlice';
import InlinePopup from '../common/InlinePopup';
import Icon from '../common/Icon';
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';
import ConvertFileSize from '../common/ConvertFileSize';
import { useTranslation } from "react-i18next";
import { formatDate } from '../common/Helper';

export default function Products() {
  const { t } = useTranslation();
  let [showingDelete, setShowDelete] = useState(false);
  let [showingPublish, setShowPublish] = useState(false);
  let [selected, setSelected] = useState(null);
  let list = useSelector(state => state.product.list);
  let payload = useSelector(state => state.product.payload);
  let [keySearch, setKeySearch] = useState(null);
  let [fieldSearch, setFieldSearch] = useState(null);
  let [orderBy, setOrderBy] = useState(null);
  let [pageSize, setPageSize] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts({ page: '1', keySearch: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldSearchList = [
    { value: 'title', label: t('product.searchBar.title') },
    { value: 'author', label: t('product.searchBar.author') },
    { value: 'productCategory', label: t('product.searchBar.productCategory') },
    // {value: 'publicationYear', label: t('product.searchBar.publicationYear')},
    // {value: 'isbn', label: t('product.searchBar.isbn')},
    // {value: 'description', label: t('product.searchBar.description')}
  ];

  const orderByList = [
    { value: '1', label: t('searchBar.orderBy.newestAdd') },
    { value: '2', label: t('searchBar.orderBy.oldestAdd') },
    { value: '3', label: t('searchBar.orderBy.newestModify') },
    { value: '4', label: t('searchBar.orderBy.oldestModify') },
    { value: '5', label: t('searchBar.orderBy.title') }
  ];


  const paginationOnChange = (page) => {
    dispatch(getProducts({ keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy, page: page }));
  }

  const onSearchChange = (input) => {
    setKeySearch(input);
    dispatch(getProducts({ keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy }));
  }

  const onFieldSearchChange = (input) => {
    setFieldSearch(input);
    dispatch(getProducts({ keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy }));
  }

  const onOrderByChange = (input) => {
    setOrderBy(input);
    dispatch(getProducts({ keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input }));
  }

  const onPageSizeChange = (input) => {
    setPageSize(input);
    dispatch(getProducts({ keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy }));
  }

  const deleteProductClick = (product) => (e) => {
    e.preventDefault();
    setSelected(product);
    setShowDelete(true);
  }

  const publishProductClick = (product) => (e) => {
    e.preventDefault();
    setSelected(product);
    setShowPublish(true);
  }

  const hideDeletePopup = (e) => {
    e.preventDefault();
    setShowDelete(false);
  };

  const hidePublishPopup = (e) => {
    e.preventDefault();
    setShowPublish(false);
  };

  const renderRows = () => {

    if (list.length === 0 || !list.map) {
      return (
        <div className='card p-4 mt-3'>{t('shared.noData')}</div>
      );
    }

    return list.map((product, index) => {
      let isDraft = true;
      let categoryName = (product.productCategory) ?
        product.productCategory.name : '-';
      let authorName = (product.author) ?
        product.author.name : '';
      if (product.status === "published") {
        isDraft = false;
      }

      let coverImg = (product.coverImage) ?
        product.coverImage.link : '/img/unupload-logo.svg';

      let fileSize = (product.pdf.fileSize) ?
        ConvertFileSize(product.pdf.fileSize) : '';

      return (
        <div className='card p-4 mt-3 table-responsive' key={index}>
          <div className='row'>
            <div className='col-3'>
              <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="img-product" src={coverImg} alt="product" />
            </div>
            <div className="col-7">
              <div className='p-1'>
                <h3>{product.title}</h3>
              </div>
              <div className='p-1 font-italic'>
                ERP ID: {product.erpId}
              </div>
              {/* <div className='p-1 font-italic'>
                                Author: { authorName }
                            </div> */}
              {/* <div className='p-1 font-italic'>
                                Category: { categoryName }
                            </div> */}
              <div className='p-1 font-italic text-success'>
                File size: {fileSize}
              </div>
              <div className='p-1 font-italic'>
                Created At: {formatDate(product.createdAt)}
              </div>
              <div className='p-1 font-italic'>
                Updated At: {formatDate(product.updatedAt)}
              </div>
              {/* <div className='p-1'>
                                Description: { product.description }
                            </div> */}
            </div>
            <div className="col-2">
              <div className="p-1">
                <Link to={"/products/edit/" + product.id} className='btn btn-warning btn-product'>
                  <Icon icon="edit-2" /> {t('shared.edit')}
                </Link>
              </div>
              <div className={"p-1 " + (isDraft ? "" : "d-none")}>
                <a href="/#" className="btn btn-primary btn-product" onClick={publishProductClick(product)}>
                  <Icon icon="file" /> {t('shared.publish')}
                </a>
              </div>
              <div className='p-1'>
                <a href="/#" className='btn btn-danger btn-product' onClick={deleteProductClick(product)}>
                  <Icon icon="trash" /> {t('shared.delete')}
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPagination = () => {
    if (list.length === 0 || !list.map) {
      return;
    }
    return (
      <div className='card p-3'>
        <div className="d-flex justify-content-center">
          {Pagination({
            totalPages: payload?.totalPages,
            currentPage: payload?.currentPage,
            onPageChange: paginationOnChange
          })
          }
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="card-header">
        <h3 className="card-title header-title">{t('product.title')}</h3>

        <div className="card-actions">
          <Link to="/products/create" className="btn btn-primary">
            <Icon size="16" icon="plus" />
            {t('product.newProduct')}
          </Link>
        </div>
      </div>

      {SearchBar({
        onSearchChange: onSearchChange,
        onOrderByChange: onOrderByChange,
        onPageSizeChange: onPageSizeChange,
        onFieldSearchChange: onFieldSearchChange,
        fieldSearchList: fieldSearchList,
        orderByList: orderByList,
      })
      }

      <div className='card-body p-3'>
        {renderRows()}
        {renderPagination()}
      </div>


      <InlinePopup
        isShowing={showingDelete}
        hide={hideDeletePopup}
        title={t('product.titleDeletePopup')}
        message={t('product.messageDeletePopup')}
        action={deleteProduct(selected)} />

      <InlinePopup
        isShowing={showingPublish}
        hide={hidePublishPopup}
        title={t('product.titlePublishPopup')}
        message={t('product.messagePublishPopup')}
        action={publishProduct(selected)} />
    </div>
  );
}