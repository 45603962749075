import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        accessToken: null,
        role: null,
        loading: false,
        error: null,
        access: null,
        version: null,
        type: null
    },
    reducers: {
        loginRequested: (state, action) => {
            state.loading = true;
            state.error = null;
            state.version = null;
            state.type  = null;
        },
        loginReceived: (state, action) => {
            state.loading = false;
            state.accessToken = "Bearer " + action.payload.token;
            state.role = action.payload.role;
            state.type = action.payload.type;
            state.access = action.payload;
            state.version = '3';
            state.error = null;
        },
        loginError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
            state.version = null;
            state.type  = null;
        },
        logoutRequested: (state, action) => {
            state.loading = true;
        },
        logoutSuccess: (state, action) => {
            state.accessToken = null;
            state.access = null;
            state.role = null;
            state.error = null;
            state.version = null;
            state.type  = null;
            state.loading = false;
        },
        logoutError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        }
    }
});

const {
    loginRequested,
    loginReceived,
    loginError,
    logoutRequested,
    logoutSuccess,
    logoutError,
} = authSlice.actions;

function getUrlByRole(role) {
    if (role === 'admin') {
        return process.env.REACT_APP_BASE_URL + '/api/v1/admins/sign_in';
    }

    if (role === 'staff') {
        return process.env.REACT_APP_BASE_URL + '/api/v1/staffs/sign_in';
    }

    if (role === 'customer') {
        return process.env.REACT_APP_BASE_URL + '/api/v1/customers/sign_in';
    }
    
    return process.env.REACT_APP_BASE_URL + '/api/v1/publishers/sign_in';
}

const login = (loginRole , email, password) => (dispatch) => {
    let urlLogin = getUrlByRole(loginRole);

    dispatch(
        actions.apiCallBegan(
            {
                url: urlLogin,
                method: "POST",
                data: {
                    email: email,
                    password: password
                },
                onStart: loginRequested.type,
                onSuccess: loginReceived.type,
                onError: loginError.type
            }
        )
    );
}

const logout = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/auth/sign_out',
                method: 'GET',
                headers: {
                    'Authorization': accessToken
                },
                onStart: logoutRequested.type,
                onSuccess: logoutSuccess.type,
                onError: logoutSuccess.type,
            }
        )
    )
} 

export { login, logout };

export default authSlice.reducer;
