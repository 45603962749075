import { t } from 'i18next';

export default function Pagination({totalPages, currentPage, onPageChange}) {
    currentPage = parseInt(currentPage);    
    totalPages = parseInt(totalPages);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    const prevPage = currentPage === 1 ? 1 : currentPage - 1;
    const nextPage = currentPage === totalPages ? totalPages : currentPage + 1;

    const handlePageChange = (page) => (e) => {
        e.preventDefault();
        if (onPageChange) {
            onPageChange(page);
        }
    };

    const renderPageItem = (label, page, isActive, isDisabled) => {
        return (
            <li key={label} className={"page-item" + (isActive ? " active" : "") + (isDisabled ? " disabled" : "")}>
                <button href="#" className="page-link" onClick={handlePageChange(page)}>{label}</button>
            </li>
        );
    };

    const renderPageItems = () => {
        let ret = [];
        
        if (totalPages <= 3) {
            for (let i=1; i<=totalPages; i++) {
                ret.push(renderPageItem(i, i, currentPage === i, false));
            }
            return ret;
        }

        let from = currentPage === totalPages ? totalPages - 2 : currentPage - 1;
        from = from < 1 ? 1 : from;
        let to = currentPage === 1 ? 3 : from + 2;

        // Item #1
        ret.push(renderPageItem(1, 1, currentPage === 1, false));

        // Ellipsize
        if (from > 2) {
            ret.push(renderPageItem('...', 0, false, true));
        }

        if (from === 1) {
            ret.push(renderPageItem(2, 2, currentPage === 2, false));
            ret.push(renderPageItem(3, 3, currentPage === 3, false));
        } else if (to === totalPages) {
            ret.push(renderPageItem(totalPages - 2, totalPages - 2, currentPage === totalPages - 2, false));
            ret.push(renderPageItem(totalPages - 1, totalPages - 1, currentPage === totalPages - 1, false));
        } else {
            for (let i=from; i<=to; i++) {
                ret.push(renderPageItem(i, i, currentPage === i, false));
            }
        }

        if (to < totalPages - 1) {
            ret.push(renderPageItem('...', 0, false, true));
        }

        ret.push(renderPageItem(totalPages, totalPages, currentPage === totalPages, false));

        return ret;
    };

    let first = t('pagination.first');
    let prev = t('pagination.prev');
    let next = t('pagination.next');
    let last = t('pagination.last');
    
    return (
        <ul className="pagination">
            { renderPageItem(first, 1, false, isFirstPage) }
            { renderPageItem(prev, prevPage, false, isFirstPage) }
            { renderPageItems() }
            { renderPageItem(next, nextPage, false, isLastPage) }
            { renderPageItem(last, totalPages, false, isLastPage) }
        </ul>
    );
};