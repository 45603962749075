import SearchBar from '../ui/SearchBar';
import Dashboard from '../dashboard/Dashboard';

export default function Test() {

  const listSearch = [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: '',
      forLabel: 'name',
      input: 'input'
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      placeholder: '',
      forLabel: 'email',
      input: 'input'
    }
  ];



  return (
    <div className='content'>
      <Dashboard />
    </div>
  )
}