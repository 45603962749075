import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAdminPopup, getAdmins, deleteAdmin } from '../../store/adminSlice';
import { useHistory } from 'react-router-dom'; 
import Icon from '../common/Icon';
import AdminPopup from './AdminPopup';
import InlinePopup from '../common/InlinePopup';
import { DateTime } from "luxon";
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';
import { useTranslation } from 'react-i18next';

export default function Admins() {
    const { t } = useTranslation();
    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let [fieldSearch, setFieldSearch] = useState(null);
    let [keySearch, setKeySearch] = useState(null);
    let [orderBy, setOrderBy] = useState(null);
    let [pageSize, setPageSize] = useState(null);
    let list = useSelector(state => state.admin.list);
    let payload = useSelector(state => state.admin.payload);
    let history = useHistory();
    let dispatch = useDispatch();

    const goToPublisher = () => {
        history.push('/publishers');
    }

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        let type = state.auth.type;
        if (accessToken != null && type !== 'SuperAdmin') {
            goToPublisher();
            return ;
        }
    });

    const paginationOnChange = (page) => {
        dispatch(getAdmins({page: page, fieldSearch: fieldSearch, keySearch: keySearch, size: pageSize, orderBy: orderBy}));
    }

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getAdmins({keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy}));
    }

    const onFieldSearchChange = (input) => {
        setFieldSearch(input);
        dispatch(getAdmins({keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy}));
    }

    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getAdmins({keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input}));
    }

    const onPageSizeChange = (input) => {
        setPageSize(input);
        dispatch(getAdmins({keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy}));
    }

    const fieldSearchList = [
        {value: 'name', label: t('admin.searchBar.fieldSearch.name')},
        {value: 'email', label: t('admin.searchBar.fieldSearch.email')},
    ];


    useEffect(() => {
        dispatch(getAdmins({page: '1', keySearch: null}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewAdmin = (e) => {
        e.preventDefault();
        dispatch(showAdminPopup());
    };

    const editAdminClick = (admin) => (e) => {
        e.preventDefault();
        dispatch(showAdminPopup(admin));
    }

    const deleteAdminClick = (admin) => (e) => {
        e.preventDefault();
        setSelected(admin);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="6">No data</td>
                </tr>
            );
        }

        return list.map((admin, index) => {
            let createdTime = DateTime.fromISO(admin.createdAt).toFormat('MM-dd-yyyy');
            let superAdmin = (admin.id === 7 || admin.type === 'SuperAdmin') ? true : false;
            return (
                <tr key={index}>
                    <td className="important">{ admin.name }</td>
                    <td>{ admin.email }</td>
                    <td>{ admin.type }</td>
                    <td>{ createdTime }</td>
                    <td className="table-actions">
                        {superAdmin ?
                            <div></div>
                        :
                            <div>
                                <a href="/#" onClick={ editAdminClick(admin) }>
                                    <Icon icon="edit-2" />
                                </a>

                                <a className={(superAdmin) ? 'disabled' : '' } href="/#" onClick={ deleteAdminClick(admin) }>
                                    <Icon icon="trash" />
                                </a>
                            </div> 
                        }
                    </td>
                </tr>
            );
        });
    };

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">{t('admin.title')}</h3>

            <div className="card-actions">
                    <button className="btn btn-outline-primary" onClick={createNewAdmin}>
                        <Icon size="16" icon="plus"/>
                        {t("admin.newAdmin")}
                    </button>
                </div>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                onPageSizeChange: onPageSizeChange,
                onFieldSearchChange: onFieldSearchChange,
                fieldSearchList: fieldSearchList,
                }) 
            }
            
            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th>{t("admin.name")}</th>
                            <th>{t("admin.email")}</th>
                            <th>{t("admin.role")}</th>
                            <th>{t("admin.registeredDate")}</th>
                            <th>{t("admin.actions")}</th>
                        </thead>
                        <tbody>
                            { renderRows() }
                        </tbody>
                    </table>
                 </div>

                { renderPagination() }
            </div>

            <AdminPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title={t("popupTitle")}
                message={t("popupMessage")}
                action={deleteAdmin(selected)} />
        </div>
    );
}