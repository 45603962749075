import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const AuthorsSlice = createSlice({
    name: 'author',
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
        payload: null,
    },
    reducers: {
        authorsRequested: (state, action) => {
            state.loading = true;
        },
        authorsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
        },
        authorsError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        authorCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        authorRequested: (state, action) => {
            state.loading = true;
        },
        authorUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex(author => author.id === action.payload.id);
            state.list[index] = action.payload;
        },
        authorDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter(author => author.id !== action.reData.id);
        },
        authorError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showAuthorPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    authorsRequested,
    authorsReceived,
    authorsError,
    authorRequested,
    authorCreated,
    authorUpdated,
    authorDeleted,
    authorError,
    showAuthorPopup,
    closePopup,
} = AuthorsSlice.actions;

const getAuthors = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=name';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/authors/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: authorsRequested.type,
                onSuccess: authorsReceived.type,
                onError: authorsError.type
            }
        )
    );
}

const createAuthor = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/authors',
                method: 'POST',
                headers: {
                    'Authorization' : accessToken
                },
                data: data,
                onStart: authorRequested.type,
                onSuccess: authorCreated.type,
                onError: authorError.type
            }
        )
    );
}

const updateAuthor = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/authors/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: authorRequested.type,
                onSuccess: authorUpdated.type,
                onError: authorError.type
            }
        )
    );
}

const deleteAuthor = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/authors/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: authorRequested.type,
                onSuccess: authorDeleted.type,
                onError: authorError.type
            }
        )
    );
}

export { getAuthors, createAuthor, updateAuthor, deleteAuthor, showAuthorPopup, closePopup };

export default AuthorsSlice.reducer;