import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const staffSlice = createSlice({
    name: "staff",
    initialState: {
        loading: false,
        error: null,
        form: null,
        list: [],
        payload: null,
        popup: false,
        info: null,
        loadingUploadLogo: false,
        logo: '',
        logoId: null,
        updateProfile: false,
    },
    reducers: {
        staffsRequested: (state, action) => {
            state.loading = true;
        },
        staffsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
            state.logoId = null;
            state.logo = '';
            state.loadingUploadLogo = false;
            state.error = null;
        },
        staffsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        staffRequested: (state, action) => {
            state.loading = true;
            state.updateProfile = false;
            state.logoId = null;
            state.error = null;
        },
        staffReceived: (state, action) => {
            state.loading = false;
            if (action.payload.logo != null) {
                state.logo = action.payload.logo.link;
            } else {
                state.logo = "/img/unupload-logo.svg";
            }
            state.form = action.payload;
            state.error = null;
        },
        staffCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
            state.error = null;
        },
        staffUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex(staff => staff.id === action.payload.id);
            state.list[index] = action.payload;
            state.error = null;
        },
        profileRequested: (state, action) => {
            state.loading = true;
            state.updateProfile = false;
            state.error = null;
        },
        profileUpdated: (state, action, ) => {
            if (action.payload.logo != null) {
                state.logo = action.payload.logo.link;
            } else {
                state.logo = "/img/unupload-logo.svg";
            }
            state.form = action.payload;
            state.updateProfile = true;
            state.error = null;
        },
        staffDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter(staff => staff.id !== action.reData.id);
            state.error = null;
        },
        staffError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.updateProfile = false;
        },
        showStaffPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        },
        logoError: (state, action) => {
            state.loadingUploadLogo = false;
            state.logo = '';
        },
        logoRequested: (state, action) => {
            state.loadingUploadLogo = true;
            state.logo = '';
        },
        logoUploaded: (state, action) => {
            state.loadingUploadLogo = false;
            state.logo = action.payload.link;
            state.logoId = action.payload.id;
        },
    }
});

const {
    staffsRequested,
    staffsReceived,
    staffsError,
    staffRequested,
    staffReceived,
    staffError,
    staffCreated,
    staffUpdated,
    staffDeleted,
    showStaffPopup,
    closePopup,
    logoError,
    logoRequested,
    logoUploaded,
    profileUpdated,
    profileRequested,
} = staffSlice.actions;

const getStaffs = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=name';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: staffsRequested.type,
                onSuccess: staffsReceived.type,
                onError: staffsError.type
            }
        )
    );
}

const getStaff = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const id = getState().auth.access.id;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/' + id,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: staffRequested.type,
                onSuccess: staffReceived.type,
                onError: staffError.type
            }
        )
    );
}

const createStaff = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs',
                method: 'POST',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: staffRequested.type,
                onSuccess: staffCreated.type,
                onError: staffError.type
            }
        )
    );
}

const updateStaff = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken; 
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: staffRequested.type,
                onSuccess: staffUpdated.type,
                onError: staffError.type
            }
        )
    );
}

const updateProfile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken; 
    const id = getState().auth.access.id;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/' + id + '/update_profile',
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: profileRequested.type,
                onSuccess: profileUpdated.type,
                onError: staffError.type
            }
        )
    );
}

const deleteStaff = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: staffRequested.type,
                onSuccess: staffDeleted.type,
                onError: staffError.type
            }
        )
    );
}

const uploadLogo = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    let logoFormData = new FormData();
    logoFormData.append('logo', data.logo);
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/staffs/upload_logo',
                method: 'POST',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'multipart/form-data'
                },
                data: logoFormData,
                onStart: logoRequested.type,
                onSuccess: logoUploaded.type,
                onError: logoError.type
            }
        )
    );
}

export { getStaff, getStaffs, createStaff, updateStaff, updateProfile, deleteStaff, uploadLogo, showStaffPopup, closePopup, profileUpdated };

export default staffSlice.reducer;