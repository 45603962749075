import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const initSlice = createSlice({
  name: 'init',
  initialState: {
    language: 'en',
    defaultLang: 'en',
  },
  reducers: {
    changeLanguageRequest: (state, action) => {
      state.language = action.payload.language;
    } 
  }

});

const {
  changeLanguageRequest,
} = initSlice.actions;

export { changeLanguageRequest }

export default initSlice.reducer;