import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from '../store/forgotPasswordSlice';
import { validateEmail } from "./common/Validation";

export default function ForgotPassword() {
    
    const [email, setEmail] = useState('');
    
    let [ emailAlert, setEmailAlert ] = useState();

    let loading = useSelector(state => state.auth.loading);

    const dispatch = useDispatch();

    const validation = (e) => {
        
        if (validateEmail(email)) {            
            setEmailAlert("Email invalid"); 
            return false;
        } else {
            setEmailAlert("");
        }

        return true;
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (!validation()){
            return;
        }

        dispatch(
            forgotPassword(email)
        );
    }
    
    return (
        <div className="wrapper-login background-login">
            <div className="card-login">
                <div className="">
                    <h3 className="card-title mb-3">Forgot password</h3>
                    <form>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                        </div>

                        <div className="actions">
                            <button className="btn btn-primary" onClick={onSubmit}>Submit</button>
                            <div className="fill"></div>

                            {loading &&
                                <div className="loading">
                                    <div className="threedots">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}