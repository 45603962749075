import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadLogo, closePopup, createPublisher, updatePublisher } from '../../store/publisherSlice';
import Icon from "../common/Icon";
import {
    validateName,
} from "../common/Validation";
import { t } from 'i18next';

const PublisherPopup = () => {

    let loading = useSelector(state => state.publisher.loading);
    let popup = useSelector(state => state.publisher.popup);
    let form = useSelector(state => state.publisher.form);
    let loadingUploadLogo = useSelector(state => state.publisher.loadingUploadLogo);
    let logoUploadedId = useSelector(state => state.publisher.logoId);

    const initialState = {
        name: '',
        companyName: '',
        companyDescription: '',
        logoImageId: null,
        type: 'SubAdmin',
        ...form
    };

    let [{ name, companyName, companyDescription, logoImageId }, setState] = useState(initialState);
    let [ nameAlert, setUserAlert ] = useState();
    let [ logoAlert, setLogoAlert ] = useState();

    useEffect(() => { 
        setState(initialState); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        setLogoAlert('');
        form = null;
        logoUploadedId = null;
        dispatch(closePopup());
    };
    
    const onChangeLogo = (data) => {
        if (typeof data.target.files[0] !== 'undefined') {
            dispatch(uploadLogo({
                logo: data.target.files[0],
            }));
            setLogoAlert(data.target.files[0].name);
        } else {
            setLogoAlert('');
        }
    }

    const validation = (e) => {
        if (validateName(name)) {
            setUserAlert(t('publisher.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }

        return true;
    }

    const create = (e) => {
        e.preventDefault();
        if (!validation()){
            return;
        }

        if (validateName(name)) {
            setUserAlert(t('publisher.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }
        logoImageId = (logoUploadedId) ? logoUploadedId : logoImageId;
        dispatch(createPublisher({
            name: name,
            companyDescription: companyDescription,
            logoImageId: logoImageId,
            responseAlert: t('publisher.popup.createSuccess')
        }));
    }

    const update = (e) => {
        e.preventDefault();
        logoImageId = (logoUploadedId) ? logoUploadedId : logoImageId;
        dispatch(updatePublisher({
            id: form.id,
            name: name,
            companyDescription: companyDescription,
            logoImageId: logoImageId,
            responseAlert: t('publisher.popup.updateSuccess')
        }));
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }))

        if (name === "name" && validateName(value)) {
            setUserAlert(t('publisher.popup.nameAlert')); 
            return false;
        } else if (name === "name") {
            setUserAlert("");
        }
    }

    const renderForm = () => {
        return (
            <form >
                <div className="form-group">
                    <label>{ t('publisher.popup.labName') }</label>
                    <input type="text" className="form-control" value={name} name="name" onChange={change} />
                    <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                </div>

                {/* <div className="form-group">
                    <label>{ t('publisher.popup.labCompanyName') }</label>
                    <textarea className="form-control" value={companyName} name="companyName" onChange={change} ></textarea>
                </div> */}

                <div className="form-group">
                    <label>{ t('publisher.popup.labCompanyDescription') }</label>
                    <textarea className="form-control" value={companyDescription} name="companyDescription" onChange={change} ></textarea>
                </div>

                <div className="form-group row">
                    <div className='col-7 col-md-7 input-upload'>
                        <label for='upload-pdf'>
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/upload-logo.svg" alt="Upload PDF" />
                        </label>
                        <input name="pdf" id="upload-pdf" type="file" className="form-control"  onChange={onChangeLogo} accept="image/png, image/jpeg"/>
                        {loadingUploadLogo &&
                            <div className="loading ml-3">
                                <div className="threedots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        }
                        <div className={"form-text" + (logoAlert ? "" : "hidden")}>
                            {logoAlert}
                        </div>
                    </div>
                    <div className='col-5 col-md-5'>
                        <div><small>[{ t('shared.imageType') }]</small></div>
                        <div><small>[ {t('shared.imageSize')} ]</small></div>
                    </div>
                </div>     
            </form>
        );
    }

    return (
        <div className={"popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">Edit Publisher</h5>
                                :
                                <h5 className="card-title">New Publisher</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">

                            { renderForm() }    
                        
                            <div className="actions">
                                {form ?
                                    <button onClick={update} className="btn btn-primary">{ t('shared.update') }</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">{ t('shared.create') }</button>
                                }
                                <div className="fill"></div>

                                {loading &&
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublisherPopup;