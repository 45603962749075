import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState: {
        user: null,
        accessToken: null,
        isReseted: false,
        loading: false,
        error: null
    },
    reducers: {
        resetPasswordRequested: (state) => {
            state.isReseted = false;
        },
        resetPasswordSuccess: (state) => {
            state.isReseted = true;
        },
        resetPasswordError: (state, action) => {
            state.isReseted = false;
            state.error = action.payload.error;
        },
        resetSuccess: (state) => {
            state.isReseted = false;
        },
        createPasswordRequested: (state) => {
            state.isReseted = false;
        },
        createPasswordSuccess: (state) => {
            state.isReseted = true;
        },
        createPasswordError: (state, action) => {
            state.isReseted = false;
            state.error = action.payload.error;
        },
        getOTPRequested: (state) => {
            state.loading = true;
        },
        getOTPSuccess: (state) => {
            state.loading = false;
        },
        getOTPError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        }
    }
});

const {
    resetPasswordRequested,
    resetPasswordSuccess,
    resetPasswordError,
    resetSuccess,
    createPasswordRequested,
    createPasswordSuccess,
    createPasswordError,
    getOTPRequested,
    getOTPSuccess,
    getOTPError,
} = resetPasswordSlice.actions;

const resetPassword = (token, password, confirmPassword) => (dispatch) => {
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/customers/reset_password',
                method: "POST",
                data: {
                    token: token,
                    password: password,
                    password_confirmation: confirmPassword,
                },
                onStart: resetPasswordRequested.type,
                onSuccess: resetPasswordSuccess.type,
                onError: resetPasswordError.type
            }
        )
    );
}

const createPassword = (data) => (dispatch) => {
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/customers/verify_otp',
                method: "POST",
                data: data,
                onStart: createPasswordRequested.type,
                onSuccess: createPasswordSuccess.type,
                onError: createPasswordError.type
            }
        )
    )
}

const getOTP = (data) => (dispatch) => {
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/customers/send_otp',
                method: "POST",
                data: {
                    token: data.token,
                    phone: data.countryCode + data.phone
                },
                onStart: getOTPRequested.type,
                onSuccess: getOTPSuccess.type,
                onError: getOTPError.type
            }
        )
    )
}

export { resetPassword, resetSuccess, createPassword, getOTP };

export default resetPasswordSlice.reducer;