import Icon from '../common/Icon';
import { Loading } from '../common/Loading';

export default function DashboardCard({ title, value, icon, loading = false, color = "primary" }) {
  return (
    <div className="card card-dashboard-fig">
      <div className="card-body dashboard-card">
        <div className="fig-wrapper">
          {loading ?
            <Loading />
            :
            <div className="fig">
              <h3>{value || '--'}</h3>
              <p>{title}</p>
            </div>
          }

          <div className={"illus illus-" + color}>
            <Icon icon={icon} size="24" />
          </div>
        </div>

      </div>
    </div>
  );
};