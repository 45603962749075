import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const StudentSlice = createSlice({
  name: "student",
  initialState: {
    loading: false,
    error: null,
    list: [],
    payload: null,
    popup: false,
    form: null,
    listProducts: [],
    reload: true,
    profilePopup: false,
    listPermission: []
  },
  reducers: {
    studentsRequested: (state) => {
      state.loading = true;
      state.reload = false;
    },
    studentsReceived: (state, action) => {
      state.loading = false;
      state.list = action.payload.data;
      state.payload = action.payload;
      state.reload = false;
    },
    studentsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.reload = false;
    },
    studentRequested: (state, action) => {
      state.loading = true;
      state.productList = [];
      state.reload = false;
    },
    studentSuccess: (state, action) => {
      state.loading = false;
      state.popup = false;
      state.form = null;
      state.reload = false;
    },
    studentInvited: (state, action) => {
      state.loading = false;
      state.popup = false;
      state.form = null;
      const index = state.list.findIndex(student => student.id === action.payload.id);
      state.list[index] = action.payload;
    },
    productsReceived: (state, action) => {
      state.loading = false;
      state.listProducts = action.payload.data;
      state.reload = false;
    },
    studentError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.reload = false;
    },
    studentUpdated: (state, action) => {
      state.loading = false;
      state.profilePopup = false;
      const index = state.list.findIndex(student => student.id === action.payload.customerId);
      const product = state.list[index].productOwnerships.findIndex(productOwnership => productOwnership.productId === action.payload.productId);
      state.list[index].productOwnerships[product].countScreenshot = action.payload.countScreenshot;
      state.reload = false;
    },
    studentRevokeSuccess: (state, action) => {
      state.loading = false;
      state.popup = false;
      const index = state.list.findIndex(student => student.id === action.reData.customerId);
      state.list[index].productOwnerships = state.list[index].productOwnerships.filter(productOwnership => productOwnership.productId !== action.reData.productId);
      state.reload = false;
    },
    studentChangeScreenPermissionSuccess: (state, action) => {
      state.loading = false;
      state.popup = false;
      state.reload = false;
      const index = state.list.findIndex(student => student.id === action.payload.id);
      state.list[index].haveScreenPermission = action.payload.haveScreenPermission;
    },
    changeScreenPermissionSuccess: (state, action) => {
      state.loading = false;
      state.popup = false;
      state.reload = false;
      // const index = state.list.findIndex(student => student.id === action.payload.id);
      // state.list[index].haveScreenPermission = action.payload.haveScreenPermission;
    },
    studentProfileUpdated: (state, action) => {
      state.loading = false;
      state.profilePopup = false;
      state.reload = false;
      const index = state.list.findIndex(student => student.id === action.payload.id);
      state.list[index].name = action.payload.name;
      state.list[index].email = action.payload.email;
    },
    showStudentPopup: (state, action) => {
      state.popup = true;
      state.form = action.payload;
      state.reload = false;
    },
    showStudentProfilePoup: (state, action) => {
      state.profilePopup = true;
      state.form = action.payload;
      state.reload = false;
    },
    studentDeleted: (state, action) => {
      state.popup = false;
      state.form = null;
      const index = state.list.findIndex(student => student.id === action.reData.id);
      state.list[index].deletedAt = action.payload.deletedAt;
    },
    closePopup: (state) => {
      state.popup = false;
      state.profilePopup = false;
      state.form = null;
      state.reload = false;
    },
    restoreRequested: (state, action) => {
      state.loading = true;
    },
    restoreSuccess: (state, action) => {
      state.loading = false;
      const index = state.list.findIndex(student => student.id === action.reData.id);
      state.list[index].deletedAt = null;
    },
    restoreError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    },
    getPermissionListRequested: (state) => {
      state.loading = true;
    },
    getPermissionListSuccess: (state, action) => {
      state.loading = false;
      state.listPermission = action.payload
    },
    getPermissionListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.listPermission = [];
    },
    studentResetPassSuccess: (state, action) => {
      state.loading = false;
      state.popup = false;
      state.reload = false;
    },
  }
});

const {
  studentsRequested,
  studentsReceived,
  studentsError,
  studentRequested,
  studentSuccess,
  studentInvited,
  studentResetPassSuccess,
  studentRevokeSuccess,
  studentChangeScreenPermissionSuccess,
  changeScreenPermissionSuccess,
  studentError,
  studentUpdated,
  showStudentPopup,
  studentProfileUpdated,
  showStudentProfilePoup,
  studentDeleted,
  productsReceived,
  closePopup,
  restoreRequested,
  restoreSuccess,
  restoreError,
  getPermissionListRequested,
  getPermissionListSuccess,
  getPermissionListError
} = StudentSlice.actions;

const getStudents = (params) => (dispatch, getState) => {  
  const accessToken = getState().auth.accessToken;
  const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
  const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=email';
  const page = params?.page ? '&page=' + params.page : '';
  const size = params?.size ? '&size=' + params.size : '&size=10';
  const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
  const invited = '&isInvited=true';
  const paramData = keySearch + fieldSearch + page + size + orderBy + invited;
  
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/customers/' + paramData,
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        onStart: studentsRequested.type,
        onSuccess: studentsReceived.type,
        onError: studentsError.type
      }
    )
  );
}

const getProducts = (params) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
  const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=title';
  const page = params?.page ? '&page=' + params.page : '';
  const size = params?.size ? '&size=' + params.size : '&size=10';
  const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
  const paramData = keySearch + fieldSearch + page + size + orderBy;
  
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v2/products/' + paramData,
        method: 'GET',
        headers: {
            'Authorization' : accessToken
        },
        onStart: studentsRequested.type,
        onSuccess: productsReceived.type,
        onError: studentsError.type
      }
    )
  );
}

const inviteStudent = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/customers/invite',
        method: 'POST',
        headers: {
          'Authorization' : accessToken
        },
        data: data,
        onStart: studentRequested.type,
        onSuccess: studentInvited.type,
        onError: studentError.type
      }
    )
  );
}

const resetPassword = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  dispatch(
    actions.apiCallBegan({
      url: '/api/v1/customers/forgot_password?email=' + data.email,
      method: 'GET',
      headers: {
        'Authorization': accessToken
      },
      data: data,
      onStart: studentRequested.type,
      onSuccess: studentSuccess.type,
      onError: studentError.type
    })
  )
}

const revokeProductOwnership = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/products/' + data.productId +'/customers/' + data.customerId + '/revoke_ownership',
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        data: data,
        onStart: studentRequested.type,
        onSuccess: studentRevokeSuccess.type,
        onError: studentError.type
      }
    )
  )
}

const resetScreenshotCount = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/products/' + data.productId + '/customers/' + data.customerId + '/reset_count_screenshot',
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        data: data,
        onStart: studentRequested.type,
        onSuccess: studentUpdated.type,
        onError: studentError.type
      }
    )
  )
}

const changeScreenPermission = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  let params = {
    haveScreenPermission: !data.haveScreenPermission,
    responseAlert: 'Update permission successfully'
  }
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/customers/' + data.id,
        method: 'PUT',
        headers: {
          'Authorization': accessToken
        },
        data: params,
        onStart: studentRequested.type,
        onSuccess: studentChangeScreenPermissionSuccess.type,
        onError: studentError.type
      }
    )
  )
}

const updateProfileStudent = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;

  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/customers/' + data.id,
        method: 'PUT',
        headers: {
          'Authorization': accessToken
        },
        data: data,
        onStart: studentRequested.type,
        onSuccess: studentProfileUpdated.type,
        onError: studentError.type
      }
    )
  )
}

const deleteStudent = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;

  dispatch(
    actions.apiCallBegan(
      {
        url: 'api/v1/customers/' + data.id,
        method: 'DELETE',
        headers: {
          'Authorization': accessToken
        },
        data: data,
        onStart: studentRequested.type,
        onSuccess: studentDeleted.type,
        onError: studentError.type
      }
    )
  )
}

const changePermission = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  // let params = {
  //   haveScreenPermission: !data.haveScreenPermission,
  //   responseAlert: 'Update permission successfully'
  // }
  dispatch(
    actions.apiCallBegan(
      {
        url: '',
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        // data: params,
        onStart: studentRequested.type,
        onSuccess: changeScreenPermissionSuccess.type,
        onError: studentError.type
      }
    )
  )
}

const restoreStudent = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;

  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/customers/' + data.id + '/restore',
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        data: data,
        onStart: restoreRequested.type,
        onSuccess: restoreSuccess.type,
        onError: restoreError.type
      }
    )
  )
}

const getPermissionList = (data) => (dispatch, getState) => {
  const accessToken = getState().auth.accessToken;
  dispatch(
    actions.apiCallBegan(
      {
        url: '/api/v1/groups/screen-permission/permissions',
        method: 'GET',
        headers: {
          'Authorization': accessToken
        },
        onStart: getPermissionListRequested,
        onSuccess: getPermissionListSuccess,
        onError: getPermissionListError
      }
    )
  )
}

export { 
  getStudents, showStudentPopup, 
  closePopup, inviteStudent, 
  revokeProductOwnership, resetScreenshotCount, 
  getProducts, resetPassword, 
  changeScreenPermission, updateProfileStudent,
  showStudentProfilePoup, deleteStudent,
  changePermission, restoreStudent,
  getPermissionList
};

export default StudentSlice.reducer;