import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showStaffPopup, getStaffs, deleteStaff } from '../../store/staffSlice';
import Icon from '../common/Icon';
import StaffPopup from './StaffPopup';
import InlinePopup from '../common/InlinePopup';
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';
import { useTranslation } from 'react-i18next';

export default function Staffs() {
    const { t } = useTranslation();
    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let [fieldSearch, setFieldSearch] = useState(null);
    let [keySearch, setKeySearch] = useState(null);
    let [orderBy, setOrderBy] = useState(null);
    let [pageSize, setPageSize] = useState(null);

    let list = useSelector( state => state.staff.list );
    let payload = useSelector(state => state.staff.payload);
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStaffs({page: '1', keySearch: null}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paginationOnChange = (page) => {
        dispatch(getStaffs({page: page, fieldSearch: fieldSearch, keySearch: keySearch, size: pageSize, orderBy: orderBy}));
    }

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getStaffs({keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy}));
    }

    const onFieldSearchChange = (input) => {
        setFieldSearch(input);
        dispatch(getStaffs({keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy}));
    }

    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getStaffs({keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input}));
    }

    const onPageSizeChange = (input) => {
        setPageSize(input);
        dispatch(getStaffs({keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy}));
    }

    const fieldSearchList = [
        {value: 'name', label: t('staff.searchBar.name')},
        {value: 'email', label: t('staff.searchBar.Email')},
        {value: 'publisherId', label: t('staff.searchBar.companyName')}
    ];

    const createNewStaff = (e) => {
        e.preventDefault();
        dispatch(showStaffPopup());
    };

    const editStaffClick = (staff) => (e) => {
        e.preventDefault();
        dispatch(showStaffPopup(staff));
    }

    const deleteStaffClick = (staff) => (e) => {
        e.preventDefault();
        setSelected(staff);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const renderRows = () => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="7">{ t('shared.noData') }</td>
                </tr>
            );
        }
        return list.map((staff, index) => {
            let superAdmin = (staff.id === 14) ? true : false;
            let type = staff.type === 'SubAdmin' ? 'SubAdmin' : staff.type;
            return (
                <tr key={index}>
                    <td className="important">{ staff.name }</td>
                    <td>{ staff.email }</td>
                    <td>{ staff.publisher?.name }</td>
                    <td>{ type }</td>
                    <td className="table-actions">
                        <a href="/#" onClick={ editStaffClick(staff) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a className={(superAdmin) ? 'disabled' : ''} href="/#" onClick={ deleteStaffClick(staff) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">Staffs</h3>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewStaff}>
                        <Icon size="16" icon="plus"/>
                        { t('staff.newStaff') }
                    </a>
                </div>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                onPageSizeChange: onPageSizeChange,
                onFieldSearchChange: onFieldSearchChange,
                fieldSearchList: fieldSearchList
                }) 
            }
            
            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th>{ t('staff.name') }</th>
                            <th>{ t('staff.email') }</th>
                            <th>{ t('staff.companyName') }</th>
                            <th>{ t('staff.role') }</th>
                            <th>{ t('shared.actions') }</th>
                        </thead>
                        <tbody>
                            { renderRows() }
                        </tbody>
                    </table>
                </div>

                { renderPagination() }
            </div>

            <StaffPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title={ t('staff.popupTitle') } 
                message={ t('staff.popupMessage') }
                action={deleteStaff(selected)} />
        </div>
    );
}