import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAuthorPopup, getAuthors, deleteAuthor } from '../../store/authorSlice';
import Icon from '../common/Icon';
import AuthorPopup from './AuthorPopup';
import InlinePopup from '../common/InlinePopup';
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';
import { useTranslation } from 'react-i18next';

export default function Authors() {
    const { t } = useTranslation();
    let [showingDelete, setShowDelete] = useState(false);
    let [selected, setSelected] = useState(null);
    let [fieldSearch, setFieldSearch] = useState(null);
    let [keySearch, setKeySearch] = useState(null);
    let [orderBy, setOrderBy] = useState(null);
    let [pageSize, setPageSize] = useState(null);
    let list = useSelector( state => state.author.list);
    let payload = useSelector(state => state.author.payload);
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAuthors({page: '1', keySearch: null}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getAuthors({keySearch: input, fieldSearch: fieldSearch, size: pageSize, orderBy: orderBy}));
    }

    const onFieldSearchChange = (input) => {
        setFieldSearch(input);
        dispatch(getAuthors({keySearch: keySearch, fieldSearch: input, size: pageSize, orderBy: orderBy}));
    }

    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getAuthors({keySearch: keySearch, fieldSearch: fieldSearch, size: pageSize, orderBy: input}));
    }

    const onPageSizeChange = (input) => {
        setPageSize(input);
        dispatch(getAuthors({keySearch: keySearch, fieldSearch: fieldSearch, size: input, orderBy: orderBy}));
    }

    const fieldSearchList = [
        {value: 'name', label: t('author.name')},
        {value: 'description', label: t('author.description')}
    ];

    const createNewAuthor = (e) => {
        e.preventDefault();
        dispatch(showAuthorPopup());
    };

    const editAuthorClick = (author) => (e) => {
        e.preventDefault();
        dispatch(showAuthorPopup(author));
    }

    const deleteAuthorClick = (author) => (e) => {
        e.preventDefault();
        setSelected(author);
        setShowDelete(true);
    }

    const hideDeletePopup = (e) => {
        e.preventDefault();
        setShowDelete(false);
    };

    const paginationOnChange = (page) => {
        dispatch(getAuthors({page: page, fieldSearch: fieldSearch, keySearch: keySearch, size: pageSize, orderBy: orderBy}));
    }

    const renderRows = () => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="6">{ t('shared.noData') }</td>
                </tr>
            );
        }
        return list.map((author, index) => {
            return (
                <tr key={index}>
                    <td className="important">{author.name}</td>
                    <td>{author.description}</td>
                    <td className="table-actions">
                        <a href="/#" onClick={ editAuthorClick(author) }>
                            <Icon icon="edit-2" />
                        </a>

                        <a href="/#" onClick={ deleteAuthorClick(author) }>
                            <Icon icon="trash" />
                        </a>
                    </td>
                </tr>
            );
        });
    };

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">{ t('author.title') }</h3>

                <div className="card-actions">
                    <a href="/#" className="btn btn-primary" onClick={createNewAuthor}>
                        <Icon size="16" icon="plus"/>
                        { t('author.newAuthor') }
                    </a>
                </div>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                onPageSizeChange: onPageSizeChange,
                onFieldSearchChange: onFieldSearchChange,
                fieldSearchList: fieldSearchList,
                }) 
            }

            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th className="sortable sort-asc">{ t('author.name') }</th>
                            <th>{ t('author.description') }</th>
                            <th>{ t('shared.actions') }</th>
                        </thead>
                        <tbody>
                            { renderRows() }
                        </tbody>
                    </table>
                    </div>

                { renderPagination() }
            </div>

            <AuthorPopup />

            <InlinePopup 
                isShowing={showingDelete} 
                hide={hideDeletePopup} 
                title={ t('author.popupTitle') }
                message={ t('author.popupMessage') }
                action={deleteAuthor(selected)} />
        </div>
    );
}