import Sidebar from "./ui/Sidebar";
import Navbar from "./ui/Navbar";
import { Route, Switch, useHistory } from "react-router-dom";   
import { useSelector } from "react-redux";
import Products from "./products/Products";
import ProductCreate from "./products/ProductCreate";
import ConfirmPopup from './common/ConfirmPopup';
import ProductUpdate from "./products/ProductUpdate";

const ProductPortal = () => {
    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken === null) {
            history.push('/login');
            return;
        }
    });

    return (
        <div className="wrapper">
            <Sidebar />

            <div className="main">
                <Navbar />

                <div className="content">
                    <Switch>
                        
                        <Route path="/products/create" component={ProductCreate} />

                        <Route path="/products/edit/:id" component={ProductUpdate} />
                        
                        <Route path="/products" component={Products} />
                        
                    </Switch>
                </div>

                <div className="footer">
                </div>

                <ConfirmPopup />
            </div>

            
        </div>
    );
};

export default ProductPortal;