import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../store/transactionSlice';
import { useHistory } from 'react-router-dom'; 
import { DateTime } from "luxon";
import { checkTransactionPermission } from '../common/UserPermission'
import Pagination from '../common/Pagination';
import SearchBar from '../common/SearchBar';

export default function Transactions() {
    let list = useSelector(state => state.transaction.list);
    let payload = useSelector(state => state.transaction.payload);
    let [keySearch, setKeySearch] = useState();
    let [orderBy, setOrderBy] = useState();
    // let [sizeParam, setSizeParam] = useState();
    let sizeParam = '10';
    let history = useHistory();
    let dispatch = useDispatch();

    const goToPublisher = () => {
        history.push('/staffs');
    }

    useSelector(state => {
        if (checkTransactionPermission(state.auth.type)) {
            goToPublisher();
            return ;
        }
    });

    const paginationOnChange = (page) => {
        dispatch(getTransactions({page: page, keySearch: keySearch, size: sizeParam}));
    }

    useEffect(() => {
        dispatch(getTransactions({page: '1', size: sizeParam}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderRows = (list) => {
        if (list.length === 0 || !list.map) {
            return (
                <tr key="0">
                    <td colSpan="4">No data</td>
                </tr>
            );
        }

        return list.map((transaction, index) => {
            let invitedAt = DateTime.fromISO(transaction.invitedAt).toFormat('MM-dd-yyyy');
            return (
                <tr key={index}>
                    <td className="important">{ transaction.name }</td>
                    <td>{ transaction.email }</td>
                    <td>{ invitedAt }</td>
                    <td className="table-actions">
                        
                    </td>
                </tr>
            );
        });
    };

    const onSearchChange = (input) => {
        setKeySearch(input);
        dispatch(getTransactions({keySearch: input, size: sizeParam}));
    }

    const onOrderByChange = (input) => {
        setOrderBy(input);
        dispatch(getTransactions({keySearch: input}));
    }

    const renderPagination = () => {
        if (list.length === 0 || !list.map) {
            return;
        }
        return (
            <div className='card p-3'>  
                <div className="d-flex justify-content-center">
                    { Pagination({
                        totalPages: payload?.totalPages, 
                        currentPage: payload?.currentPage,
                        onPageChange: paginationOnChange
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="">
            <div className="card-header">
                <h3 className="card-title header-title">Transactions</h3>
            </div>

            { SearchBar({
                onSearchChange: onSearchChange,
                onOrderByChange: onOrderByChange,
                })
            }
            
            <div className='card-body'>
                <div className='card table-responsive'>
                    <table className="table">
                        <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Invited At</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            { renderRows(list) }
                        </tbody>
                    </table>
                </div>
            
                { renderPagination() }
            </div>
            
        </div>
    );
}