import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublisher, updateProfile, uploadLogo } from '../../store/publisherSlice';
import { convertBlankInput, validateUploadImage } from '../common/Validation';
import { Loading } from '../common/Loading';
import { showMessage } from "../../store/uiSlice";
import { useTranslation } from "react-i18next";

export default function PublisherProfile() {
    const { t } = useTranslation();
    let form = useSelector(state => state.publisher.form);
    let logo = useSelector(state => state.publisher.logo);
    let loadingUploadLogo = useSelector(state => state.publisher.loadingUploadLogo);
    let logoImageUploadedId = useSelector(state => state.publisher.logoId);
    let successMessage = useSelector(state => state.publisher.updateProfile);

    const initialState = {
        companyName: '',
        companyDescription: '',
        logoImageId: null,
        ...form
    };

    let [{ companyName, companyDescription, logoImageId }, setState] = useState(initialState);
    let [ logoAlert, setLogoAlert ] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        setState(initialState);
        dispatch(getPublisher());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    if (successMessage) {
        dispatch(showMessage({ message: t('publisher.profile.profileUpdated') }))
    }
    
    const onChangeLogo = (data) => {
        let file = data.target.files[0];
        if (!validateUploadImage(file)) {
            dispatch(uploadLogo({
                logo: file,
            }));
            setLogoAlert(file.name);
        } else {
            setLogoAlert(t('publisher.profile.logoAlert'));
        }
    }

    const save = (e) => {
        e.preventDefault();
        logoImageUploadedId = (logoImageUploadedId) ? logoImageUploadedId : logoImageId;
        dispatch(updateProfile({
            companyName: convertBlankInput(companyName),
            companyDescription: convertBlankInput(companyDescription),
            logoImageId: logoImageUploadedId,
        }));
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3>{ t('publisher.profile.title') }</h3>
            </div>
            <div className="card-body">
                <form encType="multipart/form-data" className="p-5">
                    <div className="form-group">
                        <label>{ t('publisher.profile.labCompanyName') }</label>
                        <input name="companyName" type="text" className="form-control" value={companyName} onChange={change} />
                    </div>
                    
                    <div className="form-group">
                        <label>{ t('publisher.profile.labCompanyDescription') } </label>
                        <input name="companyDescription" type="text" className="form-control" value={companyDescription} onChange={change} />
                    </div>

                    <div className="row">
                        <div className="form-group col-7 mb-7 input-upload">
                            <label for='upload-pdf'>
                                <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/upload-logo.svg" alt="Upload PDF" />
                            </label>
                            <input name="pdf" id="upload-pdf" type="file" className="form-control"  onChange={onChangeLogo} accept="image/png, image/jpeg"/>
                            {loadingUploadLogo &&
                                <div className="loading ml-3">
                                    <Loading />
                                </div>
                            }
                            <div className={"form-text" + (logoAlert ? "" : "hidden")}>
                                {logoAlert}
                            </div>
                            
                            <div><small>[{ t('shared.imageType') }]</small></div>
                            <div><small>[{ t('shared.imageSize') }]</small></div>
                        </div>

                        <div className="form-group col-5 mb-5">
                            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="img-fluid img-product" src={ logo } alt="logo" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 md-8"></div>
                        <div className="actions col-2 md-2">
                            <button className="btn btn-secondary btn-submit" onClick={save}>{ t('shared.save') }</button>
                            <div className="fill"></div>
                        </div>
                    </div>                        
                </form>
            </div>
        </div>
    );
}