import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const publisherSlice = createSlice({
    name: "publisher",
    initialState: {
        loading: false,
        error: null,
        form: null,
        list: [],
        popup: false,
        info: null,
        loadingUploadLogo: false,
        logo: '',
        logoId: null,
        updateProfile: false,
        payload: null,
    },
    reducers: {
        publishersRequested: (state, action) => {
            state.loading = true;
        },
        publishersReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
            state.logoId = null;
            state.logo = '';
            state.loadingUploadLogo = false;
        },
        publishersError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        publisherRequested: (state, action) => {
            state.loading = true;
            state.updateProfile = false;
            state.logoId = null;
        },
        publisherReceived: (state, action) => {
            state.loading = false;
            if (action.payload.logo != null) {
                state.logo = action.payload.logo.link;
            } else {
                state.logo = "/img/unupload-logo.svg";
            }
            state.form = action.payload;
        },
        publisherCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        publisherUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex(publisher => publisher.id === action.payload.id);
            state.list[index] = action.payload;
        },
        profileRequested: (state, action) => {
            state.loading = true;
            state.updateProfile = false;
        },
        profileUpdated: (state, action, ) => {
            if (action.payload.logo != null) {
                state.logo = action.payload.logo.link;
            } else {
                state.logo = "/img/unupload-logo.svg";
            }
            state.form = action.payload;
            state.updateProfile = true;
        },
        publisherDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter(publisher => publisher.id !== action.reData.id);
        },
        publisherError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.updateProfile = false;
        },
        showPublisherPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        },
        logoError: (state, action) => {
            state.loadingUploadLogo = false;
            state.logo = '';
        },
        logoRequested: (state, action) => {
            state.loadingUploadLogo = true;
            state.logo = '';
        },
        logoUploaded: (state, action) => {
            state.loadingUploadLogo = false;
            state.logo = action.payload.link;
            state.logoId = action.payload.id;
        },
    }
});

const {
    publishersRequested,
    publishersReceived,
    publishersError,
    publisherRequested,
    publisherReceived,
    publisherError,
    publisherCreated,
    publisherUpdated,
    publisherDeleted,
    showPublisherPopup,
    closePopup,
    logoError,
    logoRequested,
    logoUploaded,
    profileUpdated,
    profileRequested,
} = publisherSlice.actions;

const getPublishers = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=name';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: publishersRequested.type,
                onSuccess: publishersReceived.type,
                onError: publishersError.type
            }
        )
    );
}

const getPublisher = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const id = getState().auth.access.publisherId;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/' + id,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: publisherRequested.type,
                onSuccess: publisherReceived.type,
                onError: publisherError.type
            }
        )
    );
}

const createPublisher = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers',
                method: 'POST',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: publisherRequested.type,
                onSuccess: publisherCreated.type,
                onError: publisherError.type
            }
        )
    );
}

const updatePublisher = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken; 
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: publisherRequested.type,
                onSuccess: publisherUpdated.type,
                onError: publisherError.type
            }
        )
    );
}

const updateProfile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken; 
    const id = getState().auth.access.publisherId;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/' + id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: profileRequested.type,
                onSuccess: profileUpdated.type,
                onError: publisherError.type
            }
        )
    );
}

const deletePublisher = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: publisherRequested.type,
                onSuccess: publisherDeleted.type,
                onError: publisherError.type
            }
        )
    );
}

const uploadLogo = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    let logoFormData = new FormData();
    logoFormData.append('logo', data.logo);
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/upload_logo',
                method: 'POST',
                headers: {
                    'Authorization': accessToken,
                    'Content-Type': 'multipart/form-data'
                },
                data: logoFormData,
                onStart: logoRequested.type,
                onSuccess: logoUploaded.type,
                onError: logoError.type
            }
        )
    );
}

export { getPublisher, getPublishers, createPublisher, updatePublisher, updateProfile, deletePublisher, uploadLogo, showPublisherPopup, closePopup, profileUpdated };

export default publisherSlice.reducer;