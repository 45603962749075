import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const ProductCategorySlice = createSlice({
    name: 'category',
    initialState: {
        loading: false,
        error: null,
        list: [],
        payload: null,
        form: null,
        popup: false,
    },
    reducers: {
        categoriesRequested: (state, action) => {
            state.loading = true;
        },
        categoriesReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
        },
        categoriesError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
        categoryRequested: (state, action) => {
            state.loading = true;
        },
        categoryCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        categoryUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex(category => category.id === action.payload.id);
            state.list[index] = action.payload;
        },
        categoryDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter(category => category.id !== action.reData.id);
        },
        categoryError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showCategoryPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    categoriesRequested,
    categoriesReceived,
    categoriesError,
    categoryRequested,
    categoryCreated,
    categoryUpdated,
    categoryDeleted,
    categoryError,
    showCategoryPopup,
    closePopup,
} = ProductCategorySlice.actions;

const getProductCategories = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=name';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/categories/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization' : accessToken
                },
                onStart: categoriesRequested.type,
                onSuccess: categoriesReceived.type,
                onError: categoriesError.type
            }
        )
    );
}

const createProductCategory = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/categories',
                method: 'POST',
                headers: {
                    'Authorization' : accessToken
                },
                data: data,
                onStart: categoryRequested.type,
                onSuccess: categoryCreated.type,
                onError: categoryError.type
            }
        )
    );
}

const updateProductCategory = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/categories/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: categoryRequested.type,
                onSuccess: categoryUpdated.type,
                onError: categoryError.type
            }
        )
    );
}

const deleteProductCategory = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/categories/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: categoryRequested.type,
                onSuccess: categoryDeleted.type,
                onError: categoryError.type
            }
        )
    );
}

export { getProductCategories, createProductCategory, updateProductCategory, deleteProductCategory, showCategoryPopup, closePopup };

export default ProductCategorySlice.reducer;