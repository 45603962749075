import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { resetPassword } from '../store/resetPasswordSlice';
import { validatePassword, validatePasswordConfirm } from "./common/Validation";
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    let history = useHistory();
    let [ passAlert, setPassAlert ] = useState();
    let [ passConfirmAlert, setPassConfirmAlert ] = useState();
    let token = useQuery().get("t");

    useSelector(state => {
        let isReseted = state.resetPassword.isReseted;
        if (isReseted) {
            history.push('/reset-password/success');
        }

    });

    let loading = useSelector(state => state.auth.loading);

    const dispatch = useDispatch();

    const validation = (e) => {
        if (validatePassword(password)){
            setPassAlert(t('staff.popup.passAlert'));
            return false;
        } else {
            setPassAlert("");
        }
  
        if (validatePasswordConfirm(password, confirmPassword)){
            setPassConfirmAlert(t('staff.popup.passConfirmAlert'));
            return false;
        } else {
            setPassConfirmAlert("");
        }
        return true;
    }
  
    const onSubmit = (e) => {
          e.preventDefault();
  
          if (!validation()){
            return;
          }

        dispatch(
            resetPassword(token, password, confirmPassword)
        );
    }

    const change = (e) => {
        let { name, value } = e.target;
        
        if (name === "password") {
            setPassword(value);
        }
        if (name === "confirmPassword") {
            setConfirmPassword(value);
        }
        if (name === "password" && validatePassword(value)){
            setPassAlert(t('staff.popup.passAlert'));
            return false;
        } else if (name === "password") {
            setPassAlert("");
        }

        if (name === "confirmPassword" && validatePasswordConfirm(password, value)){
            setPassConfirmAlert(t('staff.popup.passConfirmAlert'));
            return false;
        } else if (name === "confirmPassword") {
            setPassConfirmAlert("");
        }
    }
    
    return (
        <div className="wrapper-login background-login">
            <div className="card-login">
                <div className="">
                    <h3 className="card-title mb-3">Reset password</h3>
                    <form>
                        <div className="form-group">
                            <label>New password</label>
                            <input name="password" type="password" className="form-control" value={password} onChange={change} />
                            <small className={"form-text text-danger font-italic " + (passAlert ? "" : "hidden")}>{passAlert}</small>
                        </div>

                        <div className="form-group">
                            <label>Confirm password</label>
                            <input name="confirmPassword" type="password" className="form-control" value={confirmPassword} onChange={change} />
                            <small className={"form-text text-danger font-italic " + (passConfirmAlert ? "" : "hidden")}>{passConfirmAlert}</small>
                        </div>

                        <div className="actions">
                            <button className="btn btn-primary btn-login" onClick={onSubmit}>Submit</button>
                            <div className="fill"></div>

                            {loading &&
                                <div className="loading">
                                    <div className="threedots">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}