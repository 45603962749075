import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from '../../store/signUpSlice';
import { 
  validateName,
  validateEmail,
  validatePassword, 
  validatePasswordConfirm 
} from "../common/Validation";

export default function PublisherSignUp() {

  const [ email, setEmail ] = useState();
  const [ emailAlert, setEmailAlert ] = useState();
  const [ name, setName ] = useState();
  const [ nameAlert, setUserAlert ] = useState();
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ passAlert, setPassAlert ] = useState();
  const [ passConfirmAlert, setPassConfirmAlert ] = useState();

  let loading = useSelector(state => state.auth.loading);

  const dispatch = useDispatch();

  const validation = (e) => {

    if (validateEmail(email)) {
      setEmailAlert("Email invalid");
      return false;
    } else {
      setEmailAlert("");
    }

    if (validateName(name)) {
      setUserAlert("Name invalid");
      return false;
    } else {
      setUserAlert("");
    }

    if (validatePassword(password)){
      setPassAlert("Password minimum 8 characters and must contain one uppercase letter, one lowercase letter and one number");
      return false;
    } else {
      setPassAlert("");
    }

    if (validatePasswordConfirm(password, confirmPassword)){
      setPassConfirmAlert("Confirm password is not matched");
      return false;
    } else {
      setPassConfirmAlert("");
    }
    return true;
  }

  const onSubmit = (e) => {
      e.preventDefault();

      if (!validation()){
        return;
      }

      dispatch(
        signUp(email, name, password)
      );
  }
  
  return (
      <div className="wrapper-login background-login">
          <div className="card-login">
              <div className="">
                  <h3 className="card-title mb-3">Create an account</h3>
                  <form>
                      <div className="form-group">
                          <label>Email</label>
                          <input type="text" className="form-control" value={email} name="email" onChange={(e) => setEmail(e.target.value)} />
                          <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                      </div>

                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" value={name} name="name" onChange={(e) => setName(e.target.value)} />
                        <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                      </div>

                      <div className="form-group">
                          <label>New password</label>
                          <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                          <small className={"form-text text-danger font-italic " + (passAlert ? "" : "hidden")}>{passAlert}</small>
                      </div>

                      <div className="form-group">
                          <label>Confirm password</label>
                          <input type="password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                          <small className={"form-text text-danger font-italic " + (passConfirmAlert ? "" : "hidden")}>{passConfirmAlert}</small>
                      </div>

                      <div className="actions">
                          <button className="btn btn-primary btn-login" onClick={onSubmit}>SIGN IN</button>

                          {loading &&
                              <div className="loading">
                                  <div className="threedots">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                  </div>
                              </div>
                          }
                      </div>

                  </form>
              </div>
          </div>
      </div>
  );
}