import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const transactionSlice = createSlice({
    name: "transaction",
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
        payload: null,
    },
    reducers: {
        transactionsRequested: (state, action) => {
            state.loading = true;
        },
        transactionsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
        },
        transactionsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
});

const {
    transactionsRequested,
    transactionsReceived,
    transactionsError,
} = transactionSlice.actions;

const getTransactions = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '&keySearch=' + params.keySearch : '?keySearch=';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const paramData = keySearch + page + size;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/customers/?isInvited=true&' + paramData,
                method: 'GET',
                headers: {
                    'Authorization': accessToken
                },
                onStart: transactionsRequested.type,
                onSuccess: transactionsReceived.type,
                onError: transactionsError.type
            }
        )
    );
}


export { getTransactions };

export default transactionSlice.reducer;

