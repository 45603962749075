import Sidebar from "./ui/Sidebar";
import Navbar from "./ui/Navbar";
import { Route, Switch, useHistory } from "react-router-dom";   
import { useSelector } from "react-redux";
import Authors from "./author/Authors";
import ConfirmPopup from './common/ConfirmPopup';

const AuthorPortal = () => {
    let history = useHistory();

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        if (accessToken === null) {
            history.push('/login');
            return;
        }
    });

    return (
        <div className="wrapper">
            <Sidebar />

            <div className="main">
                <Navbar />

                <div className="content">
                    <Switch>
                        
                        <Route path="/authors" component={Authors} />
                        
                    </Switch>
                </div>

                <div className="footer">
                </div>

                <ConfirmPopup />
            </div>


        </div>
    );
};

export default AuthorPortal;