import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../common/Icon";
import { t } from "i18next";
import { inputIsEmpty, validateEmail } from "../common/Validation";
import Select from 'react-select';
import { closePopup, inviteStudent, getProducts } from "../../store/studentSlice";

const StudentPopup = () => {
  let loading = useSelector(state => state.student.loading);
  let popup = useSelector(state => state.student.popup);
  let form = useSelector(state => state.student.form);
  let listProducts = useSelector(state => state.student.listProducts);
 
  const initialState = {
    name: '',
    email: '',
    ...form
  };

  let [{name, email}, setState] = useState(initialState);
  let [nameAlert, setNameAlert] = useState();
  let [emailAlert, setEmailAlert] = useState();
  let [products, setProducts] = useState(null);

  useEffect(() => { 
    dispatch(getProducts({size: '999999'}));
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form] )

  const dispatch = useDispatch();

  const close = (e) => {
    e.preventDefault();
    dispatch(closePopup());
  }

  const productOptions = (list) => { 
    try {
      if (form?.productOwnerships && form?.productOwnerships.length > 0) {
        form?.productOwnerships.map((product) => {
          list = list.filter(book => book.id !== product.productId);
        });
      }

      let options = [];
      if (list === 'undefined') {
        return options;
      } else {
        try {
          list.map((product) => {
            let title = product.title + " | ERP: " + product.erpId;
            options.push({ value: product.id, label: title });
          }) 
        } catch {
          console.log('error product Options');
        }
      }
      return options;
    } catch (err) {
      console.log(err);
    }
    return [];
  }

  const validation = (e) => {
    if (inputIsEmpty(name)) {
      setNameAlert(t('student.nameAlert'))
      return false;
    } else {
      setNameAlert(''); 
    }

    if (validateEmail(email)) {
      setEmailAlert(t('student.emailAlert'))
      return false;
    } else {
      setEmailAlert('')
    }

    return true;
  }

  const invite = (e) => {
    if (!validation()) {
      return ;
    }
    
    let productIds = [];
    products.map((index) => {
      productIds.push(index.value);
    });

    dispatch(inviteStudent({
      name: name,
      email: email,
      productList: "[" + productIds + "]",
      responseAlert: t('student.inviteAlert')
    }));
  }

  const change = (e) => {
    let { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  return (
    <div className={"popup" + (popup ? " popup-show" : "")}>
      <div className="popup-overlay"></div>

      <div className="popup-wrapper">
        <div className="popup-content">
          <div className="card">
            <div className="card-header">
                <h5 className="card-title">{ t('student.inviteStudent') }</h5>
              <div>
                <a href="/#" onClick={close}>
                  <Icon icon="x" />
                </a>
              </div>
            </div>

            <div className="card-body">
              <form >
                <div className="form-group">
                  <label>{ t('student.name') }</label>
                  <input type="text" className="form-control" value={name} name="name" onChange={change} readOnly={form}/>
                  <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                </div>

                <div className="form-group">
                  <label>{ t('student.email') }</label>
                  <input type="email" className="form-control" value={email} name="email" onChange={change} readOnly={form} />
                  <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                </div>

                <div className="form-group">
                  <label>{ t('student.products') }</label>
                  <Select name="products" defaultValue={products} onChange={setProducts} options={productOptions(listProducts)} isMulti isSearchable />
                </div>
              </form>

              <div className="actions">
                  <button onClick={invite} className="btn btn-primary">{ t('student.invite') }</button>

                <div className="fill"></div>

                {loading &&
                  <div className="loading">
                    <div className="threedots">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default StudentPopup;