import React from 'react'
import { t } from 'i18next';

export default function SearchBar({fieldSearchList, orderByList, pageSizeList,
                                   onSearchChange, onOrderByChange, onPageSizeChange, onFieldSearchChange}) {
  orderByList = (orderByList?.length>0 && orderByList.map) 
                ? orderByList 
                : [
                    {value: '1', label: t('searchBar.orderBy.newestAdd')},
                    {value: '2', label: t('searchBar.orderBy.oldestAdd')}, 
                    {value: '3', label: t('searchBar.orderBy.newestModify')},
                    {value: '4', label: t('searchBar.orderBy.oldestModify')},
                  ];
  pageSizeList = (pageSizeList?.length>0 && pageSizeList.map) 
                ? pageSizeList 
                : [
                    {value: '10', label: t('searchBar.perPage.option1')},
                    {value: '20', label: t('searchBar.perPage.option2')},
                    {value: '50', label: t('searchBar.perPage.option3')}
                  ];
  fieldSearchList = (fieldSearchList?.length>0 && fieldSearchList.map) 
                ? fieldSearchList
                : [{value: '', label: 'Default'}];
  
  function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  
  const handleSearchChange = debounce((event) => {
    if (onSearchChange) {
      onSearchChange(event.target.value);
    }
  });

  const handleFieldSearchChange = debounce((event) => {
    if (onFieldSearchChange) {
      onFieldSearchChange(event.target.value);
    }
  });

  const handleOrderByChange = debounce((event) => {
    if (onOrderByChange) {
      onOrderByChange(event.target.value);
    }
  });

  const handlePageSizeChange = debounce((event) => {
    if (onPageSizeChange) {
      onPageSizeChange(event.target.value);
    }
  });

  const renderSearchInput = () => {
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-2">
        <input data-testid="search-input" type="text" placeholder={t('searchBar.keySearch')} className="form-control" onChange={event => handleSearchChange(event)}/>
      </div>
    );
  }

  const renderSelectOption = (data, dataTest=null) => {
    if (data.map) {
      return data.map((key) => {
        return (
          <option data-testid={dataTest} key={key.value} value={key.value}>{key.label}</option>
        )
      });
    }
  }

  const renderFieldSearch = () => {
    return (
      <div className="col-12 col-sm-4 col-md-4 col-lg-2 p-2">
        <select data-testid="pageSize" className="form-control" onChange={event => handleFieldSearchChange(event)}>
          { renderSelectOption(fieldSearchList, 'field-search-options') }
        </select>
      </div>
    )
  }

  const renderOrderBy = () => {
    return (
      <div className="col-12 col-sm-4 col-md-4 col-lg-2 p-2">
        <select data-testid="orderBy" className="form-control" onChange={event => handleOrderByChange(event)}>
          { renderSelectOption(orderByList, 'order-by-options') }
        </select>
      </div>
    );
  }
  
  const renderPageSize = () => {
    return (
      <div className="col-12 col-sm-4 col-md-4 col-lg-2 p-2">
        <select data-testid="pageSize" className="form-control" onChange={event => handlePageSizeChange(event)}>
          { renderSelectOption(pageSizeList, 'page-size-options') }
        </select>
      </div>
    )
  }

  return (
    <div className="row">
      { renderSearchInput() }
      { renderFieldSearch() }
      { renderOrderBy() }
      { renderPageSize() }
    </div>
  )
}