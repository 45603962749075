import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../store/studentSlice";
import { inputIsEmpty, validateEmail } from "../common/Validation";
import Icon from "../common/Icon";
import { t } from "i18next";
import { updateProfileStudent } from '../../store/studentSlice'

const StudentProfilePopup = () => {
  let loading = useSelector(state => state.student.loading);
  let popup = useSelector(state => state.student.profilePopup);
  let form = useSelector(state => state.student.form);

  const initialState = {
    id: '',
    name: '',
    email: '',
    ...form
  }

  const dispatch = useDispatch()

  let [{id, name, email}, setState] = useState(initialState);
  let [nameAlert, setNameAlert] = useState();
  let [emailAlert, setEmailAlert] = useState();

  useEffect(() => {
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const close = (e) => {
    e.preventDefault();
    dispatch(closePopup());
  }

  const validation = (e) => {
    if (inputIsEmpty(name)) {
      setNameAlert(t('student.nameAlert'));
      return false;
    } else {
      setNameAlert('');
    }

    if (validateEmail(email)) {
      setEmailAlert(t('student.emailAlert'));
      return false;
    } else {
      setEmailAlert('');
    }

    return true;
  }

  const update = (e) => {
    if (!validation()) {
      return ;
    }

    dispatch(updateProfileStudent({
      id: id,
      name: name,
      email: email,
      responseAlert: t('student.updateInfoSuccess')
    }));
  }

  const change = (e) => {
    let { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name] : value }))
  }

  return (
    <div className={"popup" + (popup ? " popup-show" : "")}>
      <div className="popup-overlay"></div>

      <div className="popup-wrapper">
        <div className="popup-content">
          <div className="card">
            <div className="card-header">
                <h5 className="card-title">{ t('student.updateInfo') }</h5>
              <div>
                <a href="/#" onClick={close}>
                  <Icon icon="x" />
                </a>
              </div>
            </div>

            <div className="card-body">
              <form >
                <div className="form-group">
                  <label>{ t('student.name') }</label>
                  <input type="text" className="form-control" value={name} name="name" onChange={change} />
                  <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                </div>

                <div className="form-group">
                  <label>{ t('student.email') }</label>
                  <input type="email" className="form-control" value={email} name="email" onChange={change} />
                  <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                </div>
              </form>

              <div className="actions">
                  <button onClick={update} className="btn btn-primary">{ t('shared.update') }</button>

                <div className="fill"></div>

                {loading &&
                  <div className="loading">
                    <div className="threedots">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default StudentProfilePopup;