import Menu from './Menu';

export default function Sidebar() {

  return (
    <div className="sidebar d-none d-md-none d-lg-block">
      <div className="box-sidebar">
        <Menu />        
      </div>
    </div>
  );
}
