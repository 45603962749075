import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../common/Icon";
import InlinePopup from "../common/InlinePopup";
import Pagination from '../common/Pagination'
import SearchBar from "../common/SearchBar";
import { formatDate } from "../common/Helper";
import CountDownTimer from "../common/CountDownTimer";
import { useTranslation } from "react-i18next";
import {
  revokeProductOwnership,
  getStudents,
  showStudentPopup,
  resetScreenshotCount,
  resetPassword,
  changeScreenPermission,
  showStudentProfilePoup,
  deleteStudent,
  restoreStudent,
  getPermissionList
} from "../../store/studentSlice";
import StudentPopup from "./StudentPopup";
import StudentProfilePopup from "./StudentProfilePopup";
// import PermissionDropdown from "./PermissionDropDown";

export default function Students() {
  const { t } = useTranslation();
  let list = useSelector(state => state.student.list);
  let payload = useSelector(state => state.student.payload);
  let reload = useSelector(state => state.student.reload);
  let role = useSelector(state => state.auth.role);
  // let listPermission = useSelector(state => state.student.listPermission);
  let [showingReset, setShowReset] = useState(null);
  let [showingRevoke, setShowRevoke] = useState(null);
  let [showingResetPassword, setShowResetPassword] = useState(null);
  let [showingScreenPermission, setShowScreenPermission] = useState(null);
  let [showingDeleteStudent, setShowDeleteStudent] = useState(null);
  let [showingRestoreStudent, setShowRestoreStudent] = useState(null);
  let [selected, setSelected] = useState(null);
  let [keySearch, setKeySearch] = useState(null);
  let [fieldSearch, setFieldSearch] = useState(null);
  let [orderBy, setOrderBy] = useState(null);
  let [pageSize, setPageSize] = useState(null);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getStudents({ page: '1', keySearch: null }));
    // dispatch(getPermissionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fieldSearchList = [
    { value: 'email', label: t('student.email') },
    { value: 'name', label: t('student.name') }
  ]
  
  const paginationOnChange = (page) => {
    dispatch(getStudents(
      {
        keySearch: keySearch,
        fieldSearch: fieldSearch,
        size: pageSize,
        orderBy: orderBy,
        page: page
      }
    ));
  }

  const onSearchChange = (input) => {
    setKeySearch(input);
    dispatch(getStudents(
      {
        keySearch: input,
        fieldSearch: fieldSearch,
        size: pageSize,
        orderBy: orderBy
      }
    ));
  }

  const onFieldSearchChange = (input) => {
    setFieldSearch(input);
    dispatch(getStudents(
      {
        keySearch: keySearch,
        fieldSearch: input,
        size: pageSize,
        orderBy: orderBy
      }
    ));
  }

  const onOrderByChange = (input) => {
    setOrderBy(input);
    dispatch(getStudents(
      {
        keySearch: keySearch,
        fieldSearch: fieldSearch,
        size: pageSize,
        orderBy: input
      }
    ));
  }

  const onPageSizeChange = (input) => {
    setPageSize(input);
    dispatch(getStudents(
      {
        keySearch: keySearch,
        fieldSearch: fieldSearch,
        size: input,
        orderBy: orderBy
      }
    ));
  }

  const inviteStudent = (student) => (e) => {
    e.preventDefault();
    dispatch(showStudentPopup(student));
  }

  const resetCount = (product) => (e) => {
    e.preventDefault();
    setSelected(product);
    setShowReset(true);
  }

  const hideResetPopup = (e) => {
    e.preventDefault();
    setShowReset(false);
  }

  const revokeProduct = (product) => (e) => {
    e.preventDefault();
    setSelected(product);
    setShowRevoke(true);
  }

  const hideRevoke = (e) => {
    e.preventDefault();
    setShowRevoke(false);
  }

  const resetPasswordPopup = (student) => (e) => {
    e.preventDefault();
    setSelected(student);
    setShowResetPassword(true);
  }

  const editStudentPopup = (student) => (e) => {
    e.preventDefault();
    dispatch(showStudentProfilePoup(student))
  }

  const hideResetPassword = (e) => {
    e.preventDefault();
    setShowResetPassword(false);
  }

  const deleteStudentClick = (student) => (e) => {
    e.preventDefault();
    setSelected(student);
    setShowDeleteStudent(true);
  }

  const hideDeleteStudentPopup = (e) => {
    e.preventDefault();
    setShowDeleteStudent(false);
  }

  const restoreStudentClick = (student) => (e) => {
    e.preventDefault();
    setSelected(student);
    setShowRestoreStudent(true);
  }

  const hideRestoreStudentPopup = (e) => {
    e.preventDefault();
    setShowRestoreStudent(false);
  }

  const screenPermissionPopup = (student) => (e) => {
    e.preventDefault();
    setSelected(student);
    setShowScreenPermission(true);
  }

  const hideScreenPermissionPopup = (e) => {
    e.preventDefault();
    setShowScreenPermission(false);
  }

  const getAssignedBy = (input) => {
    if (input.logs?.length < 1 || !input.logs) {
      return ''
    }

    return input.logs[0].actionableBy?.name;
  }

  const renderPagination = () => {
    if (list.length === 0 || !list.map) {
      return;
    }

    return (
      <div className='card p-3'>
        <div className="d-flex justify-content-center">
          <Pagination
            totalPages={payload?.totalPages}
            currentPage={payload?.currentPage}
            onPageChange={paginationOnChange} />
        </div>
      </div>
    )
  }

  const renderListProducts = (products) => {
    if (products?.length === 0 || !products?.map) {
      return (
        <tr>
          <td colSpan='6'> {t('shared.noData')} </td>
        </tr>
      )
    }

    return products.map((product, index) => {
      return (
        product.product ?
          <tr key={index}>
            <td> {product.product?.erpId} </td>
            <td> {product.product?.title} </td>
            <td> { getAssignedBy(product) } </td>
            <td> {formatDate(product?.createdAt)} </td>
            <td>
              { product.countScreenshot }
              <a className="ml-2" href="#" onClick={resetCount(product)} data-delay='0.2' data-toggle="tooltip" data-placement="top" title="Reset screenshot">
                <Icon icon="refresh-ccw" />
              </a>
            </td>
            <td>
              <a onClick={revokeProduct(product)} className="ml-2" href="#">
                <Icon icon="trash" />
              </a>
            </td>
          </tr> 
        : 
          <></>
      )
    })
  }

  const renderStudent = () => {

    if (list.length === 0 || !list.map) {
      return (
        <tr>
          <td colSpan="7">{t('shared.noData')}</td>
        </tr>
      )
    }

    return list.map((student, index) => {
      let collapseId = "collapse" + student.id;
      let status = (student.deletedAt === null) ? 'activate' : null;
      let havePermission = (student.haveScreenPermission === true) ? true : null;

      return ( <>
        <tr key={index}>
          <td> { student.name } </td>
          <td> { student.email } </td>
          <td className="text-center"> { formatDate(student.invitedAt) } </td>
          <td> { student?.invitedBy } </td>
          {
            role === "Admin" ?
            <td>
              {/* <PermissionDropdown listPermission={listPermission} value={'ccnefuk'} /> */}
              { havePermission ? 
                <div className="custom-control custom-switch" onClick={screenPermissionPopup(student)}>
                  <input type="checkbox" checked className="custom-control-input" id={"customSwitch" + student.id} />  
                  <label className="custom-control-label" htmlFor={"customSwitch" + student.id}>
                    { t("student.permissionOn") }
                  </label>
                </div>
                :
                <div className="custom-control custom-switch" onClick={screenPermissionPopup(student)}>
                  <input type="checkbox" className="custom-control-input" id={"customSwitch" + student.id} />  
                  <label className="custom-control-label" htmlFor={"customSwitch" + student.id}>
                    { t("student.permissionOff") }
                  </label>
                </div>
              }
            </td>
            :
            <td className="text-center">
              {
                havePermission ? t("student.permissionOn") : t("student.permissionOff")
              }
            </td>
          }
          <td>
            <div className="row">
              { role === "Admin" ?
                <>
                  <a onClick={resetPasswordPopup(student)} className="ml-2" href="/#" data-toggle="tooltip" data-placement="top" title={t("student.titleResetPasswordPopup")}>
                    <Icon icon="key" />
                  </a>
                  <a onClick={editStudentPopup(student)} className="ml-2" href="/#" data-toggle="tooltip" data-placement="top" title={t("student.titleEditStudentPopup")}>
                    <Icon icon="edit" />
                  </a>
                </>
                :
                  <></>
              }
              <a onClick={inviteStudent(student)} className="ml-2" href="/#" data-toggle="tooltip" data-placement="top" title={t("student.titleAddBook")}>
                <Icon icon="book" />
              </a>
              <a className="ml-2" data-toggle="collapse" href={"#" + collapseId} role="button" aria-expanded="false" aria-controls={collapseId} >
                <Icon icon="plus" />
              </a>
            </div>
          </td>
          <td className="text-center">
            { status ?
              <>
                <h5>
                  <span className="badge badge-success"> {t("student.activate")} </span>
                </h5>
              </>
              :
              <>
                <h5>
                  <div>
                    <span className="badge badge-danger"> {t("student.deleted")} </span>
                  </div>
                </h5>
                <div className="mt-1">
                  <CountDownTimer targetDate={student.deletedAt}/>
                </div>  
              </>
            }
            
          </td>
          <td>
            { role === "Admin" ?
                status ?
                  <a onClick={deleteStudentClick(student)} className="ml-2" href="/#" data-toggle="tooltip" data-placement="top" title={t("student.titleDeleteStudentPopup")}>
                    <Icon icon="trash" />
                  </a>
                :
                  <a onClick={restoreStudentClick(student)} className="ml-2" href="/#" data-toggle="tooltip" data-placement="top" title={t("student.titleRestoreStudentPopup")}>
                    <Icon icon="refresh-cw" />
                  </a>
              : 
              <></>
            }
          </td>
        </tr>
        <tr key={'product' + student.id} className="collapse" id={collapseId}>
          <td colSpan="8">
            <table className="table child-table">
              <thead>
                <tr>
                  <th>{t('product.labERP')}</th>
                  <th>{t('product.labTitle')}</th>
                  <th>{t('student.assignedBy')}</th>
                  <th>{t('student.assignedAt')}</th>
                  <th>{t('student.labCountScreenshot')}</th>
                  <th>{t('shared.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {renderListProducts(student.productOwnerships)}
              </tbody>
            </table>
          </td>
        </tr>
      </> )
    })
  }

  return (
    <>
      <div className="card-header row">
        <div className="col-12 col-sm-6 col-md-9">
          <h3 className="card-title">{t('student.title')}</h3>
        </div>

        <div className="col-12 col-sm-6 col-md-3 card-actions d-flex justify-content-end">
          <a href="#" className="btn btn-primary" onClick={inviteStudent(null)}>
            <Icon size="16" icon="plus" />
            {t('student.inviteStudent')}
          </a>
        </div>
      </div>

      <SearchBar
        onSearchChange={onSearchChange}
        onOrderByChange={onOrderByChange}
        onPageSizeChange={onPageSizeChange}
        onFieldSearchChange={onFieldSearchChange}
        fieldSearchList={fieldSearchList} />

      <div className='card-body'>
        <div className='card'>
          <div className="table-responsive">
            <table className="table table-stripped">
              <thead className="text-center">
                <tr>
                  <th> {t('student.name')} </th>
                  <th> {t('student.email')} </th>
                  <th> {t('student.invitedAt')} </th>
                  <th> {t('student.invitedBy')} </th>
                  <th> {t('student.titleScreenPermissionPopup')} </th>
                  <th> {t('shared.actions')} </th>
                  <th> {t('student.status')} </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { renderStudent() }
              </tbody>
            </table>
          </div>
          {renderPagination()}
          <div className="p-3">
            <p className="float-right"> {t('student.totalStudents')} {payload?.totalItems} </p>
          </div>
        </div>
      </div>

      <StudentPopup />

      <StudentProfilePopup />

      <InlinePopup
        isShowing={showingRevoke}
        hide={hideRevoke}
        title={t('student.titleRevokePopup')}
        message={t('student.messageRevokePopup')}
        action={revokeProductOwnership(selected)} />

      <InlinePopup
        isShowing={showingReset}
        hide={hideResetPopup}
        title={t('student.titleResetPopup')}
        message={t('student.messageResetPopup')}
        action={resetScreenshotCount(selected)} />

      <InlinePopup
        isShowing={showingResetPassword}
        hide={hideResetPassword}
        title={t('student.titleResetPasswordPopup')}
        message={t('student.messageResetPasswordPopup')}
        action={resetPassword(selected)} />

      <InlinePopup
        isShowing={showingScreenPermission}
        hide={hideScreenPermissionPopup}
        title={t('student.titleScreenPermissionPopup')}
        message={t('student.messageScreenPermissionPopup')}
        action={changeScreenPermission(selected)} />

      <InlinePopup
        isShowing={showingDeleteStudent}
        hide={hideDeleteStudentPopup}
        title={t('student.deleteStudentTitle')}
        message={t('student.deleteStudentMessage')}
        action={deleteStudent(selected)} />

      <InlinePopup
        isShowing={showingRestoreStudent}
        hide={hideRestoreStudentPopup}
        title={t('student.restoreStudentTitle')}
        message={t('student.restoreStudentMessage')}
        action={restoreStudent(selected)} />

    </>
  );
}