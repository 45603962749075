// import { useTranslation } from 'react-i18next';
// import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

const WelcomePortal = () => {
    // const { t } = useTranslation();

    return (
        <div className='background-landing-page'>
            {/* <MouseParallaxContainer className="parallax" resetOnLeave >
                <div className='mt-3 ml-3'>
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className='img-fluid' src="/img/landing-page/logo.svg"/>
                </div>
                <MouseParallaxChild factorX={0.12} factorY={0.08} >
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}height="250vw" src="/img/landing-page/cloud-1.svg" alt="" />
                </MouseParallaxChild>
                <MouseParallaxChild factorX={0.1} factorY={0.1} >
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}height="200vw" src="/img/landing-page/cloud-2.svg" alt="" />
                </MouseParallaxChild>
                <div className='mt-3 ml-3'>
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className='img-fluid' src="/img/landing-page/apple-store-button.svg"/>
                </div>
                <MouseParallaxChild factorX={0.14} factorY={0.07} >
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}height="200vw" src="/img/landing-page/book.svg" alt="" />
                </MouseParallaxChild>
                <MouseParallaxChild factorX={0.09} factorY={0.12} >
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}height="100vw" src="/img/landing-page/pen.svg" alt="" />
                </MouseParallaxChild>
                <MouseParallaxChild factorX={0.13} factorY={0.08} >
                    <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}height="100vw" src="/img/landing-page/owl.svg" alt="" />
                </MouseParallaxChild>
            </MouseParallaxContainer> */}
            <div className='p-4 '>
                <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className='img-fluid' src="/img/landing-page/logo.svg" alt="logo"/>
            </div>   
        
            <div className='col col-md-12 col-ml-12 text-center align-content-center'>
                <h1 className='text-white'>Discover Knowledge</h1>
                <div className='col-12 col-sm-12 col-md-8'>
                    
                </div>
                <div className="row justify-content-md-center mt-3">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-6">
                        <h5 className='text-white '>Access your educational content from anywhere. 
                    We believe knowledge and learning should be convenient and interactive. No need to carry heavy textbooks any longer. Replace your entire pencil case with just one device.</h5>
                    </div>
                </div>
                <div className='mt-3 ml-3'>
                    <a href='https://apps.apple.com/th/app/handbooq/id1621843851'>
                        <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className='img-fluid' src="/img/landing-page/apple-store-button.svg" alt="app-store"/>
                    </a>
                </div>
            </div>
           
        </div>
    );
};

export default WelcomePortal;

