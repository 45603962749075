// [0: superAdmin, 1: handbooqAdmin, 2: handbooqSubadmin, 3: staffAdmin, 4: staffSubadmin]
export function checkPermission(role, type) {
  
  if (role === "Admin") {
    if (type === 'SuperAdmin') return '0';
    if (type === 'Admin') return '1';
    if (type === 'SubAdmin') return '2';
  }

  if (role === "Staff") {
    if (type === 'Admin') return '3';
    if (type === 'SubAdmin') return '4';
  }

  return false;
}

export function checkTransactionPermission(type) {
  if (type === 'Admin') {
    return false;
  }
  
  return true;
}