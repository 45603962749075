import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState: {
        user: null,
        accessToken: null,
        loading: false,
        error: null
    },
    reducers: {
        forgotPasswordRequested: (state, action) => {
            state.loading = true;
        },
        forgotPasswordSuccess: (state, action) => {
            state.loading = false;
            state.accessToken = action.payload.access_token;
        },
        forgotPasswordError: (state, action) => {
            state.loading = false;
            state.error = action.payload.error;
        },
    }
});

const {
    forgotPasswordRequested,
    forgotPasswordSuccess,
    forgotPasswordError,
} = forgotPasswordSlice.actions;

const forgotPassword = (email) => (dispatch) => {
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/customers/forgot_password/?email=' + email,
                method: "GET",
                data: {
                    responseAlert: 'Password reset link sent to your email!'
                },
                onStart: forgotPasswordRequested.type,
                onSuccess: forgotPasswordSuccess.type,
                onError: forgotPasswordError.type
            }
        )
    );
}

export { forgotPassword };

export default forgotPasswordSlice.reducer;