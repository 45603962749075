import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const adminSlice = createSlice({
    name: "admin",
    initialState: {
        loading: false,
        error: null,
        list: [],
        form: null,
        popup: false,
        payload: null,
        admin: null
    },
    reducers: {
        adminsRequested: (state, action) => {
            state.loading = true;
        },
        adminsReceived: (state, action) => {
            state.loading = false;
            state.list = action.payload.data;
            state.payload = action.payload;
        },
        adminsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        adminRequested: (state, action) => {
            state.loading = true;
        },
        adminReceived: (state, action) => {
            state.loading = false;
            state.form = action.payload;
            state.error = null;
        },
        adminCreated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.list.push(action.payload);
            state.form = null;
        },
        adminUpdated: (state, action) => {
            state.loading = false;
            state.popup = false;
            state.form = null;
            const index = state.list.findIndex(admin => admin.id === action.payload.id);
            state.list[index] = action.payload;
        },
        adminDeleted: (state, action) => {
            state.loading = false;
            state.list = state.list.filter(admin => admin.id !== action.reData.id);
        },
        adminError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        showAdminPopup: (state, action) => {
            state.popup = true;
            state.form = action.payload;
        },
        closePopup: (state, action) => {
            state.popup = false;
            state.form = null;
        }
    }
});

const {
    adminsRequested,
    adminsReceived,
    adminsError,
    adminRequested,
    adminReceived,
    adminError,
    adminCreated,
    adminUpdated,
    adminDeleted,
    showAdminPopup,
    closePopup,
} = adminSlice.actions;

const getAdmins = (params) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const keySearch = params?.keySearch ? '?keySearch=' + params.keySearch : '?keySearch=';
    const fieldSearch = params?.fieldSearch ? '&fieldSearch=' + params.fieldSearch : '&fieldSearch=name';
    const page = params?.page ? '&page=' + params.page : '';
    const size = params?.size ? '&size=' + params.size : '&size=10';
    const orderBy = params?.orderBy ? '&orderBy=' + params.orderBy : '&orderBy=1';
    const paramData = keySearch + fieldSearch + page + size + orderBy;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins/' + paramData,
                method: 'GET',
                headers: {
                    'Authorization': accessToken
                },
                onStart: adminsRequested.type,
                onSuccess: adminsReceived.type,
                onError: adminsError.type
            }
        )
    );
}

const getAdmin = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const id = getState().auth.access.id;
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins/' + id,
                method: 'GET',
                headers: {
                    'Authorization': accessToken
                },
                onStart: adminsRequested.type,
                onSuccess: adminReceived.type,
                onError: adminsError.type
            }
        )
    );
}

const createAdmin = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins',
                method: 'POST',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: adminRequested.type,
                onSuccess: adminCreated.type,
                onError: adminError.type
            }
        )
    );
}

const updateAdmin = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins/' + data.id,
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: adminRequested.type,
                onSuccess: adminUpdated.type,
                onError: adminError.type
            }
        )
    );
}

const updateProfile = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    const id = getState().auth.access.id;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins/' + id + '/update_profile',
                method: 'PUT',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: adminRequested.type,
                onSuccess: adminUpdated.type,
                onError: adminError.type
            }
        )
    );
}

const deleteAdmin = (data) => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;

    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/admins/' + data.id,
                method: 'DELETE',
                headers: {
                    'Authorization': accessToken
                },
                data: data,
                onStart: adminRequested.type,
                onSuccess: adminDeleted.type,
                onError: adminError.type
            }
        )
    );
}

export { getAdmins, getAdmin, createAdmin, updateAdmin, updateProfile, deleteAdmin, showAdminPopup, closePopup };

export default adminSlice.reducer;

