// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getStat } from '../../store/dashboardSlice';
// import Icon from '../common/Icon';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import DashboardTable from './DashboardTable';
import DashboardCard from './DashboardCard';

export default function Dashboard() {

  // let {
  //     userCount, loading
  // } = useSelector(state => state.dashboard);

  let publisherCount = Math.floor(Math.random() * 100);
  let studentCount = Math.floor(Math.random() * 10000);
  let productCount = Math.floor(Math.random() * 100);
  let annotationCount = Math.floor(Math.random() * 10000000);
  let loading = false;
  // let dispatch = useDispatch();
  // useEffect(() => dispatch(getStat()), []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
  )
  
  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const weekLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const tempThead = ['No', 'Name', 'Stat'];
  const tempData = [
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
    { no: '1', name: 'RANDOM DATA', stat: '123123' },
  ];
  const tempThead2 = ['No', 'Name', 'Annotation'];

  const options = ({position = 'top', text = ''}) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: position,
        },
        title: {
          display: true,
          text: text,
        },
      },
    }
  }
  
  const data = ({ labels = [], label = '2022', data = [], borderColor = 'rgb(53, 162, 235)', backgroundColor = 'rgba(53, 162, 235, 0.5)' }) => {
    data = labels.map(() => faker.datatype.number({ min: 0, max: 1000 }));
    
    return {
      labels,
      datasets: [
        {
          fill: true,
          label: label,
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        },
        {
          fill: true,
          label: label,
          data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          borderColor: 'rgb(255, 127, 127)',
          backgroundColor: 'rgb(255, 127, 127, 0.5)',
        },
      ],
    }
  }

  return (
    <div className="container-fluid">
      <div className="row mt-3 mb-3">
        <div className="col">
          <h3>Dashboard</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
          <DashboardCard title="Publishers" value={publisherCount} loading={loading} icon="users" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
          <DashboardCard title="Students" value={studentCount} loading={loading} icon="user" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
          <DashboardCard title="Products" value={productCount} loading={loading} icon="book" />
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3">
          <DashboardCard title="Annotations" value={annotationCount} loading={loading} icon="pen-tool" />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <Line options={options({position: 'top', text: 'Student active'})} data={data({ labels: weekLabels })} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <Bar options={options({position: 'top', text: 'Students'})} data={data({ labels: labels })} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <Line options={options({position: 'top', text: 'Products'})} data={data({ labels: labels })} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <Line options={options({position: 'top', text: 'Annotations'})} data={data({ labels: labels })} />
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <h3>Student active</h3>
          <DashboardTable thead={tempThead} data={tempData} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <h3>Products</h3>
          <DashboardTable thead={tempThead} data={tempData} />
        </div>
      </div>
      {/* Stat in total */}
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <h3>Student with annotations</h3>
          <DashboardTable thead={tempThead2} data={tempData} />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <h3>Product with annotations</h3>
          <DashboardTable thead={tempThead2} data={tempData} />
        </div>
      </div>
    </div>
  );
}