import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import api from './middleware/api';
import uiSlice from './uiSlice';
import authSlice from './authSlice';
import adminSlice from './adminSlice';
import dashboardSlice from './dashboardSlice';
import publisherSlice from './publisherSlice';
import forgotPasswordSlice from './forgotPasswordSlice';
import resetPasswordSlice from './resetPasswordSlice';
import signUpSlice from './signUpSlice';
import productSlice from './productSlice';
import productCategory from './productCategorySlice';
import authorSlice from './authorSlice';
import staffSlice from './staffSlice';
import transactionSlice from './transactionSlice';
import studentSlice from './studentSlice';
import initSlice from './initSlice';

const reducers = combineReducers({
    init: initSlice,
    auth: authSlice,
    ui: uiSlice,
    admin: adminSlice,
    dashboard: dashboardSlice,
    publisher: publisherSlice,
    forgotPassword: forgotPasswordSlice,
    resetPassword: resetPasswordSlice,
    signUp: signUpSlice,
    product: productSlice,
    productCategory: productCategory,
    author: authorSlice,
    staff: staffSlice,
    transaction: transactionSlice,
    student: studentSlice
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["signUp"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware(),
        api
    ]
});

const persistor = persistStore(store);

export { store, persistor };