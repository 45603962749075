import Sidebar from "./ui/Sidebar";
import Navbar from "./ui/Navbar";
import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "./dashboard/Dashboard";
import ConfirmPopup from './common/ConfirmPopup';

const DashboardPortal = () => {
  let history = useHistory();

  useSelector(state => {
    let accessToken = state.auth.accessToken;
    if (accessToken === null) {
      history.push('/login');
      return;
    }
  });

  return (
    <div className="wrapper">
      <Sidebar />

      <div className="main">
        <Navbar />

        <div className="content">
          <Switch>

            <Route path="/dashboard" component={Dashboard} />

          </Switch>
        </div>

        <div className="footer">
        </div>

        <ConfirmPopup />
      </div>
    </div>
  );
};

export default DashboardPortal;