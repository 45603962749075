// import { useTranslation } from "react-i18next";

const TermsOfUsage = () => {
  
  return (
    <div className="container">
      <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="img/logo.svg" className='img img-fluid mb-3 mt-3' alt="handbooq-logo"/>
      <div className='privacy-policy'>
        <h1 className='text-center mb-3'>ข้อตกลงและเงื่อนไขการใช้บริการ</h1>
        <div>
          ยินดีต้อนรับเข้าสู่แพลตฟอร์มออนไลน์ “Handbooq” (“เรา”) เพื่อให้บริการนำเสนอเนื้อหาและผลงานด้านวรรณกรรม ผ่านแอปพลิเคชันบนมือถือ ตลอดจนบริการที่มีให้ในนั้น (“บริการ”) สำหรับท่านที่ต้องการใช้บริการ โปรดอ่านข้อตกลงและเงื่อนไขการใช้บริการนี้อย่างละเอียดก่อนใช้บริการ โดยเมื่อท่านล็อกอินเข้าสู่ระบบ ถือว่าท่านรับทราบและตกลงยินยอมปฏิบัติตามข้อตกลงและเงื่อนไขการใช้บริการแล้ว
        </div>
        <section>
          <h2 className='bg-secondary p-2'>ท่านจะสมัครสมาชิกได้อย่างไร</h2>
          <div>
            <div>
              <span className='ml-4'>
                1.  ท่านจำเป็นต้องให้ข้อมูลส่วนบุคคลโดยกรอกข้อมูลบนแบบฟอร์มที่เรากำหนด เพื่อให้เราดำเนินการสร้างบัญชี บันทึกข้อมูลของผู้สมัครสมาชิก และส่ง Username Password ทางอีเมล  โดยเราสนับสนุนให้ท่านใช้รหัสผ่านที่ไม่ซ้ำและไม่เดาง่ายจนเกินไปเพื่อให้แตกต่างจากรหัสผ่านที่ท่านเคยใช้ในบริการอื่น ๆ
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2.  ท่านจะรักษาอีเมล Username Password และ/หรือรหัสผ่านของการลงทะเบียนใช้บริการไว้เป็นความลับ และจะไม่โอนสิทธิ์การใช้บริการให้แก่บุคคลอื่นใด หากท่านฝ่าฝืนเงื่อนไขให้ถือว่าการกระทำดังกล่าว เป็นการกระทำโดยตัวของท่านเอง และมีผลผูกพันในทางกฎหมาย แม้ว่าท่านจะไม่ได้เป็นผู้ทำกิจกรรมหรือใช้งานดังกล่าวก็ตาม ซึ่งเราจะไม่รับผิดต่อความสูญเสียหรือความเสียหายที่เกิดขึ้นทั้งสิ้น
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3.  ท่านจะมีสิทธิ์ผูกมัดหนึ่งบัญชีต่อdeviceหนึ่งเครื่องเท่านั้น ท่านจะมีโอกาสที่จะเปลี่ยนการผูกมัดบัญชีไปกับdeviceเครื่องอื่น2ครั้งต่อเดือน
              </span>
            </div>
            <div>
              <span className='ml-4'>
                4.  ท่านมีหน้าที่รับผิดชอบในการรักษาข้อมูลบัญชีของท่านให้คงความถูกต้อง สมบูรณ์ หากท่านพบว่ามีการใช้ข้อมูลสมาชิกของท่านโดยไม่ได้รับอนุญาต หรือสงสัยว่ามีผู้เข้าถึงข้อมูลส่วนตัวของท่านโดยไม่ได้รับอนุญาต ท่านควรเปลี่ยนรหัสผ่านทันที
              </span>
            </div>
            <div>
              <span className='ml-4'>
                5.  ท่านจำเป็นต้องเข้าถึงบัญชีของท่านผ่านเว็บเบราว์เซอร์หรือการติดตั้งซอฟต์แวร์ของเราบนเครื่องคอมพิวเตอร์ รวมถึงแท็บเล็ตหรือโทรศัพท์มือถือของท่าน ค่าใช้จ่ายในการใช้อุปกรณ์เหล่านั้น และค่าบริการในการเชื่อมต่อต่าง ๆ ถือเป็นความรับผิดชอบของท่านเอง เราไม่มีหน้าที่รับผิดชอบในเรื่องการเชื่อมต่อสัญญาณอินเตอร์เน็ตและบริการเกี่ยวกับโทรคมนาคมต่าง ๆ ที่จำเป็นต่อการเข้าใช้ระบบของเราแต่อย่างใด
              </span>
            </div>
            <div>
              <span className='ml-4'>
                6.  ท่านจะต้องให้ข้อมูลที่ ถูกต้อง และเป็นความจริง หากมีข้อมูลใดที่ไม่ถูกต้องท่านสามารถแจ้งเราทราบทันที ผ่านช่องทางที่เราให้บริการ เพื่อให้เราดำเนินการปรับปรุง แก้ไขข้อมูลของท่านให้เป็นถูกต้อง โดยเพื่อประโยชน์ในการให้บริการ เราอาจร้องขอเอกสารเพิ่มเติม ในการยืนยันข้อมูลต่าง ๆ 
              </span>
            </div>
            <div>
              <span className='ml-4'>
                7.  ท่านตกลงว่าธุรกรรมอิเล็กทรอนิกส์/อินเทอร์เน็ต (Electronic/internet transactions) ที่ได้มีการประมวลผลและดำเนินการจนเสร็จสิ้นแล้วโดยผ่านทางเว็บไซต์ หรือโปรแกรม หรือ แอพพลิเคชันที่เราจัดทำขึ้นมีผลบังคับใช้ได้อย่างสมบูรณ์ตามกฎหมาย และผูกพันตามพระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ พ.ศ. 2544
              </span>
            </div>      
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>เด็กสามารถสมัครสมาชิกกับเราได้หรือไม่</h2>
          <div>
            <div>
              <span className='ml-4'>
                1.  บริการของเรา เกี่ยวเนื่องกับการให้บริการด้านการศึกษา และเรามั่นใจว่า บริการที่เราพัฒนาขึ้นมีเนื้อหาที่เป็นประโยชน์ในการเรียน การพัฒนาความรู้  ซึ่งแม้จะเป็นกิจกรรมที่ท่านสามารถดำเนินการได้ด้วยตัวเองตามกฎหมายก็ตาม  แต่เราแนะนำว่าการที่ท่านจะเข้ารับบริการนั้นควรต้องอยู่ภายใต้คำแนะนำ ควบคุมดูแล และขออนุญาตจากพ่อแม่ ผู้ปกครอง ก่อนสมัครใช้บริการกับเรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2.  ในกรณีท่านมีอายุ ตั้งแต่ 10-20 ปี ท่านตกลงและรับรองว่าก่อนการสมัครลงทะเบียน ท่านได้แจ้งและได้รับอนุญาตจากผู้ปกครองแล้ว โดยท่านสามารถทำการลงทะเบียนด้วยตนเอง และกรอกข้อมูลส่วนบุคคลของท่าน โดยหากท่านเจตนากรอกข้อมูลส่วนบุคคลที่ใช้ในการลงทะเบียนไม่ถูกต้องในสาระสำคัญที่อาจทำให้เรามีความเสี่ยงทางกฎหมาย ทางธุรกิจ หรือได้รับความเสียหาย เช่น อายุไม่ถึงเกณฑ์ที่เรากำหนด เรามีสิทธิ์ยกเลิกการลงทะเบียนและระงับสิทธิ์ในการใช้บริการทั้งหมด และอาจดำเนินการทางกฎหมายในกรณีที่เรามีสิทธิ์เช่นนั้น
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3.  ในกรณีที่เด็กมีอายุต่ำกว่า 10 ปี ท่านต้องให้ผู้ปกครองเป็นผู้ลงทะเบียนสมัครสมาชิก ดังนั้น ในบางกรณีเราอาจร้องขอเอกสารยืนยัน ซึ่งหากท่านหรือผู้ใช้อำนาจปกครองที่มีอำนาจกระทำแทนผู้เยาว์ปฏิเสธ เราจำเป็นต้องทำการปฏิเสธการรับลงทะเบียนหรือยกเลิกการเป็นสมาชิกนั้น
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>ท่านสามารถแบ่งปันบัญชีของท่านให้คนอื่นได้หรือไม่</h2>
          <div>
            <div>
              <span className='ml-4'>
                บัญชีของเราไม่ควรถูกแบ่งปันให้กับใคร หากท่านแบ่งปันข้อมูลสมาชิกของท่านให้ผู้อื่น บุคคลนั้นอาจจะควบคุมบัญชีของท่านได้ และเราจะไม่สามารถรับรู้ได้ว่าใครเป็นเจ้าของบัญชีตัวจริง และในทางกลับกัน ท่านไม่สามารถใช้อีเมลของผู้อื่นในการเข้าไปใช้บริการสมัครสมาชิกกับเราได้ เนื่องจากข้อมูลของท่าน ซึ่งรวมถึง ข้อมูลสมาชิกจะถูกบันทึกกับบัญชีผู้ใช้ หากมีการเปลี่ยนเปลี่ยนบัญชีผู้ใช้ อาจมีผลกระทบกับการจัดเก็บข้อมูลท่าน
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>เรามีเงื่อนไขการให้บริการอย่างไร</h2>
          <div>
            <div>
              <span className='ml-4'>
                การใช้บริการจะต้องอยู่ภายใต้ข้อตกลงฉบับนี้ รวมถึงวิธีการ และเงื่อนไขต่าง ๆ ที่เราได้กำหนดไว้ในเว็ปไซต์ และท่านจะสามารถเริ่มใช้บริการได้ก็ต่อเมื่อ ท่านได้ลงทะเบียน ยอมรับข้อตกลงและเงื่อนไขการใช้บริการแล้ว
              </span>
            </div>
            <div>
              <span className='ml-4'>
                1.  เรามีการพัฒนา การให้บริการอย่างต่อเนื่อง ซึ่งบริการอาจเปลี่ยนแปลงเป็นครั้งคราว ส่งผลให้เรามีความจำเป็นต้องลบ ปฏิเสธ จำกัดการให้บริการโดยเราจะแจ้งให้ท่านทราบล่วงหน้า ทั้งนี้ ในบางกรณีเราอาจจะรวบรวมความคิดเห็น หรือคำแนะนำของท่านได้ตามที่เราเห็นสมควร
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2.  เราอาจให้ข้อมูลอัพเดทเกี่ยวกับบริการเป็นครั้งคราว การอัพเดทดังกล่าวอาจเกิดขึ้นโดยอัตโนมัติหรือด้วยตนเอง โปรดทราบว่าบริการอาจทำงานไม่ถูกต้องหรือทำงานไม่ได้เลยหากท่านไม่ได้ติดตั้งการอัพเกรดหรือเวอร์ชั่นใหม่ เราไม่รับประกันว่าการอัพเดทดังกล่าวจะยังคงสนับสนุนอุปกรณ์หรือระบบของท่านต่อไป ดังนั้นเป็นความรับผิดชอบของท่าน แต่เพียงผู้เดียวในการตรวจสอบให้แน่ใจว่าอุปกรณ์ของท่านตรงตามข้อกำหนดสำหรับการติดตั้งและใช้บริการตามที่เราต้องการ ระหว่างการติดตั้ง บริการอาจถอนการติดตั้งหรือปิดใช้งานซอฟต์แวร์อื่นที่ทำงานอยู่บนอุปกรณ์ของท่าน หากท่านไม่ปฏิบัติตามคำแนะนำในการติดตั้งที่เราให้ไว้ ท่านอาจไม่สามารถใช้บริการหรือฟังก์ชันบางอย่างอาจไม่พร้อมใช้งานสำหรับท่าน
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3.  เราขอสงวนสิทธิ์ระงับการให้บริการหากเราตรวจพบว่า การใช้บริการมีวัตถุประสงค์ไม่ถูกต้องตามกฎหมาย หรือเพื่อเอื้อให้มีการดำเนินการอันมิชอบด้วยกฎหมายหรือซึ่งเป็นการละเมิดสิทธิ์ใด ๆ ของผู้อื่น หรือกรณีที่ท่านได้สร้างความเสียหายเดือดร้อนแก่บุคคลอื่น หรือกระทำการใด ๆ ที่เป็นอันตรายต่อความปลอดภัยของระบบคอมพิวเตอร์ หรือระบบเน็ตเวิร์ค                
              </span>
            </div>
            <div>
              <span className='ml-4'>
                4.  เราขอสงวนสิทธิ์ในการเก็บรักษาข้อมูลจราจรทางคอมพิวเตอร์ไว้ไม่น้อยกว่าเก้าสิบวัน นับแต่วันที่ข้อมูลนั้นเข้าสู่ระบบคอมพิวเตอร์ ซึ่งเป็นไปตาม พ.ร.บ. ว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ (ฉบับที่ 2) พ.ศ. 2560
              </span>
            </div>
            <div>
              <span className='ml-4'>
                5.  เราจะไม่รับผิดจากการให้บริการ ในกรณีที่มีเหตุสุดวิสัย หรือเหตุใด ๆ อันเนื่องมาจากพฤติการณ์อันหนึ่งอันใด เช่นการเกิดอันตรายใด ๆ กับอุปกรณ์ของท่าน หรือการสูญหายของข้อมูล รวมถึงการไม่สามารถเข้าถึง หรือใช้บริการ อันเนื่องจากการกระทำของบุคคลที่สาม
              </span>
            </div>
            <div>
              <span className='ml-4'>
                6.  เรามีอำนาจในการตรวจสอบ และเปลี่ยนแปลงสิทธิ์ในการใช้บริการของท่าน โดยการตัดสินของเราถือเป็นที่สุด และในกรณีที่พบว่ามีการฉ้อฉล ปลอมแปลง หรือการกระทำทุจริตใด ๆ เพื่อให้ได้มาซึ่งสิทธิประโยชน์จากบริการต่าง ๆ ไม่ว่าจะเป็นเพียงบางส่วน หรือทั้งหมด หรือการที่ท่านยินยอมให้ผู้อื่นกระทำการข้างต้น เรามีสิทธิ์ปฏิเสธหรือเพิกถอนสิทธิ์ดังกล่าวไม่ว่าทั้งหมดหรือบางส่วนโดยเราไม่ต้องชดเชยค่าเสียหายใด ๆ ทั้งสิ้น
              </span>
            </div>
            <div>
              <span className='ml-4'>
                7.  เราขอสงวนสิทธิ์ในการระงับ หรือยุติบัญชีของท่าน รวมถึงดำเนินการใด ๆ ตามที่เราเห็นว่ามีความจำเป็น เพื่อวัตถุประสงค์ ดังต่อไปนี้ โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า รวมทั้งไม่ต้องรับผิดชอบต่อความเสียหายใดอันเนื่องมาจากการกระทำดังกล่าวทั้งสิ้น
              </span>
              <div className="ml-4">
                <div>
                  <span className="ml-3">
                  -  การเข้าถึงที่ไม่ได้รับอนุญาต หรือดำเนินการใดที่ผิดเงื่อนไขเรื่องลิขสิทธิ์ตามที่ระบุในข้อตกลงฉบับนี้
                  </span>
                </div>
                <div>
                  <span className="ml-3">
                    -  การใช้งานที่ละเมิดสิทธิ์ ความประมาทเลินเล่อของท่าน ที่ทำให้เราได้รับความเสียหาย
                  </span>
                </div>
                <div>
                  <span className="ml-3">
                    -  การเผยแพร่ไวรัส, ส่งสแปม, ดักจับข้อมูลซึ่งปรากฏเป็นข้อมูลจราจรทางคอมพิวเตอร์ การเจาะเข้าระบบคอมพิวเตอร์ของเรา
                  </span>
                </div>
                <div>
                  <span className="ml-3">
                    -  การพยายามเข้าถึงโดยมิชอบซึ่งข้อมูลคอมพิวเตอร์ และ/หรือระบบคอมพิวเตอร์ของเราหรือบุคคลอื่น หรือทำการเขียนสคริปต์สร้างข้อความในลักษณะที่จะแทรกแซง หรือสร้างความเสียหายแก่สมาชิกรายอื่น
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>การคุ้มครองข้อมูลส่วนบุคคลของท่าน</h2>
          <div>
            <div>
              <span className='ml-4'>
                ในกรณีเราขอความยินยอมจากท่าน ท่านมีความเป็นอิสระในการให้ความยินยอม โดยเราจะไม่กำหนดเงื่อนไขในการให้ความยินยอม มาเป็นส่วนหนึ่งของการเข้าใช้บริการ ซึ่งท่านสามารถมั่นใจได้ว่าเราจะใช้ข้อมูลตามวัตถุประสงค์ที่เรากำหนด หรือที่ได้รับความยินยอมจากท่านเท่านั้น โดยในกรณีที่เราจะดำเนินการประมวลผลข้อมูลส่วนบุคคลของท่านในลักษณะ และ/หรือ วัตถุประสงค์ที่ไม่สอดคล้องกับที่กำหนดใน นโยบายคุ้มครองข้อมูลส่วนบุคคล เราจะจัดให้มีการประกาศเพิ่มเติม และแจ้งให้ท่านทราบผ่านหน้าแอปพลิเคชั่น
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>ข้อควรปฏิบัติในการใช้บริการมิให้ละเมิดลิขสิทธิ์</h2>
          <div>
            <div>
              <span className='ml-4'>
                1.  เนื้อหา ข้อความ ภาพ ลิขสิทธิ์ และสิทธิ์ในทรัพย์สินทางปัญญาที่แสดงในบริการของเรา (“ทรัพย์สินทางปัญญา”) เป็นทรัพย์สินและเป็นงานอันได้รับความคุ้มครองตามกฎหมาย และอาจมีบางส่วนเป็นทรัพย์สินของบุคคลภายนอกที่เป็นผู้ถือกรรมสิทธิ์ตามกฎหมาย โดยการให้บริการนี้ ไม่มีส่วนใดเป็นการโอนสิทธิ์ในทรัพย์สินทางปัญญา หรืออนุญาตให้ทำสำเนา ลอกเลียนแบบ อ้างสิทธิ์ จากทรัพย์สินทางปัญญาของเรา เพื่อวัตถุประสงค์อื่นในเชิงพาณิชย์ หรือวัตถุประสงค์อื่นที่จะทำให้เราได้รับความเสียหายในเชิงธุรกิจ
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2.  ท่านตกลงที่จะปฏิบัติตามกฎหมายลิขสิทธิ์ โดยการไม่เผยแพร่ต่อสาธารณะ ลอกเลียน ทำซ้ำ คัดลอก ดัดแปลง แก้ไข เรียบเรียง ขาย มีไว้เพื่อขาย เช่า มีไว้เพื่อเช่า เสนอขาย เสนอเช่า จัดพิมพ์เนื้อหา reverse engineer รวมถึงกระทำการอื่นใดที่ก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์ ในลักษณะที่เป็นการแสวงหาประโยชน์ ทางการค้า หรือระโยชน์โดยมิชอบ ไม่ว่าโดยประการใด ๆ จากทรัพย์สินทางปัญญาดังกล่าวข้างต้น เว้นแต่จะได้รับอนุญาตเป็นลายลักษณ์อักษรจากเรา และจากเจ้าของลิขสิทธิ์
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3.  ในบางกรณีท่านจะสามารรถใช้บริการของเราได้เมื่อท่านชำระเงิน และท่านรับทราบว่าการอนุญาตให้ใช้บริการของเรานี้ไม่ได้เป็นการอนุญาตให้ท่านมีสิทธิ ถ่ายโอนเนื้อหาใด ๆ ไม่ว่าทั้งหมดหรือบางส่วนหรือทำสำเนาใด ๆ ซึ่งหากท่านพยายามดึงเนื้อหาออกจากแอปพลิเคชัน แชร์และเผยแพร่เนื้อหา แบ่งปัน ทำซ้ำ ในกรณีที่ท่านบันทึกภาพหน้าจอได้เกิน 5 หน้าต่อการใช้บริการหนังสือ 1 เล่ม เราจะแสดงผลหน้าจอของท่านในหนังสือเล่มนั้น ให้ปรากฎชื่อผู้ใช้, อีเมล, ที่อยู่ IP และหมายเลขอุปกรณ์บนหนังสือนั้นอย่างถาวร และหากท่านยังพยายามบันทึกหรือแชร์หน้าจอแอปพลิเคชันอยู่อีก เราขอสงวนสิทธิในการทำให้ท่านไม่สามารถใช้บริการหนังสือเล่มนั้นได้ โดยไม่มีการแจ้งให้ทราบ และเราไม่มีความรับผิดต่อท่านหากเราใช้สิทธิ์เหล่านี้
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>ข้อจำกัดการใช้บริการ</h2>
          <div>
            ท่านไม่สามารถหรือไม่มีสิทธิอนุญาตให้บุคคลอื่นดำเนินการ ดังนี้ 
          </div>
          <div>
            <div>
              <span className='ml-4'>
                1) ดู คัดลอก หรือจัดหาเนื้อหาหรือข้อมูลจากบริการด้วยวิธีการอัตโนมัติ (เช่น สคริปต์ บอท สไปเดอร์ โปรแกรมรวบรวมข้อมูล หรือสแครปเปอร์) หรือใช้เทคโนโลยีการทำเหมืองข้อมูลหรือกระบวนการอื่นๆ เพื่อเฟรม ปิดบัง ดึงข้อมูลหรือ เอกสารอื่นๆ จากบริการ เว้นแต่ได้รับอนุญาตอย่างเป็นทางการจากเราภายใต้ข้อตกลงเป็นลายลักษณ์อักษร
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2) ใช้บริการในลักษณะใด ๆ หรือเพื่อวัตถุประสงค์ใด ๆ ที่ฝ่าฝืนข้อตกลงนี้หรือกฎหมายและข้อบังคับที่บังคับใช้หรือสนับสนุนให้บุคคลหรือนิติบุคคลใด ๆ ฝ่าฝืนข้อตกลงนี้หรือกฎหมายและข้อบังคับที่บังคับใช้
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3) ใช้บริการในลักษณะใด ๆ หรือเพื่อวัตถุประสงค์ใด ๆ ที่อาจก่อให้เกิดอันตรายหรือความเสียหายต่อเราหรือลูกค้าของเรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                4) ใช้บริการเพื่อเข้าถึงระบบ บัญชี หรือข้อมูลใดๆ โดยไม่ได้รับอนุญาต
              </span>
            </div>
            <div>
              <span className='ml-4'>
                5) เรียกเก็บเงินจากผู้อื่นโดยตรงหรือโดยอ้อมสำหรับการใช้หรือการเข้าถึงบริการ (ยกเว้นในกรณีที่ท่านและเราตกลงกันเป็นอย่างอื่นโดยชัดแจ้ง)
              </span>
            </div>
            <div>
              <span className='ml-4'>
                6) ไม่ใช้อุปกรณ์อัตโนมัติ เครื่องมือ ซอฟท์แวร์ ปล่อย virus worm spyware computer code file หรือ program ใด ๆ ที่จงใจ หรืออาจจะส่งผลเสีย หรือเข้าไปควบคุม ระบบของเรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                7) ทำให้บริการเป็นแบบสาธารณะหรือพร้อมใช้งานบนเครือข่ายใด ๆ สำหรับการคัดลอก ดาวน์โหลด หรือใช้โดยบุคคลหรือบุคคลใด ๆ
              </span>
            </div>
            <div>
              <span className='ml-4'>
                8) ลบ ปิดบัง หรือแก้ไขลิขสิทธิ์ เครื่องหมายการค้า หรือประกาศเกี่ยวกับกรรมสิทธิ์ เครื่องหมายหรือสิ่งบ่งชี้ที่พบในหรือบนบริการ
              </span>
            </div>
            <div>
              <span className='ml-4'>
                9) ให้ยืม จ้าง ให้เช่า ดำเนินการ ขาย แจกจ่าย ให้สิทธิ์ต่อสาธารณะ ทั้งหมดหรือบางส่วนในลักษณะที่ไม่ได้รับอนุญาตโดยชัดแจ้งจากข้อตกลงนี้ หรือพยายามทำสิ่งใดสิ่งหนึ่งข้างต้น
              </span>
            </div>
            <div>
              <span className='ml-4'>
                10) ใช้กลโกง หาประโยชน์ ซอฟต์แวร์ระบบอัตโนมัติหรือซอฟต์แวร์ของบุคคลที่สามที่ไม่ได้รับอนุญาตซึ่งออกแบบมาเพื่อแก้ไขหรือแทรกแซงบริการ
              </span>
            </div>
            <div>
              <span className='ml-4'>
                11) รบกวนหรือสร้างภาระให้คอมพิวเตอร์หรือเซิร์ฟเวอร์ใด ๆ ที่ใช้ในการเสนอหรือสนับสนุนบริการ หรือการใช้บริการของผู้ใช้รายอื่น
              </span>
            </div>
            <div>
              <span className='ml-4'>
                12) พัฒนาปลั๊กอิน ส่วนประกอบภายนอก องค์ประกอบที่เข้ากันได้หรือการเชื่อมต่อหรือเทคโนโลยีอื่น ๆ ที่ทำงานร่วมกับบริการ ยกเว้นในกรณีที่เราอนุญาตโดยชัดแจ้งให้ท่านทำผ่านซอฟต์แวร์ของเรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                13) แก้ไข สร้างงานลอกเลียนแบบ หรือดึงซอร์สโค้ดจากบริการของเรา
              </span>
            </div>
            <div>
              <span className='ml-4'>
                หากท่านละเมิดข้อจำกัดใดๆ ข้างต้น สิทธิ์ของท่านในการใช้บริการจะยุติลงทันที และท่านจะต้องส่งคืนหรือทำลายสำเนาของเนื้อหาที่ท่านทำขึ้น ทั้งนี้ การใช้งานใดๆ ที่ไม่ได้รับอนุญาตโดยชัดแจ้งถือเป็นการละเมิดข้อกำหนดเหล่านี้ และอาจละเมิดลิขสิทธิ์ เครื่องหมายการค้า และกฎหมายทรัพย์สินทางปัญญาอื่นๆ เราอาจใช้มาตรการทางเทคโนโลยีในบริการเพื่อป้องกันการใช้บริการโดยไม่ได้รับอนุญาตหรือไม่ได้รับอนุญาต หรือเพื่อป้องกันการละเมิดข้อตกลงนี้ โดยเราไม่มีความรับผิดต่อท่านหากเราใช้สิทธิ์ดังกล่าว
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>ท่านจะซื้อเนื้อหาและสมัครสมาชิกได้อย่างไร</h2>
          <div>
            <div>
              <span className='ml-4'>
                1) บริการและเนื้อหาของเราบางส่วนมีให้ท่านโดยไม่คิดค่าใช้จ่าย ในขณะที่บริการและเนื้อหาอื่น ๆ ในบางกรณีอาจต้องมีการชำระเงินก่อนที่ท่านจะอ่านหนังสือที่ท่านต้องการ หรือใช้งานได้บริการดังกล่าวได้ โดยหากท่านประสงค์จะใช้บริการท่านมีหน้าที่รับผิดชอบการชำระเงินทั้งหมดและภาระผูกพันในการชำระเงินที่เกี่ยวข้องภายใต้ข้อตกลงนี้
              </span>
            </div>
            <div>
              <span className='ml-4'>
                2) หากการชำระเงินใด ๆ ที่ทำผ่านวิธีการชำระเงินที่ท่านเลือกถูกปฏิเสธ ปฏิเสธ โดยไม่ได้ชำระเงินด้วยเหตุผลใดก็ตาม เราอาจไม่ให้หรือระงับข้อกำหนดของเราในผลิตภัณฑ์หรือบริการที่มีเนื้อหาที่เกี่ยวข้องจนกว่าการชำระเงินจะได้รับการดำเนินการอย่างเหมาะสม และท่านต้องรับผิดต่อค่าธรรมเนียม ต้นทุน ค่าใช้จ่าย หรือจำนวนเงินอื่น ๆ ที่เราเกิดขึ้นจากการปฏิเสธ การปฏิเสธ หรือการคืนสินค้าดังกล่าว (และเราอาจเรียกเก็บเงินจากท่านโดยอัตโนมัติสำหรับจำนวนเงินดังกล่าว)
              </span>
            </div>
            <div>
              <span className='ml-4'>
                3) เราขอสงวนสิทธิ์ในการคืนเงินทุกกรณี โดยหากท่านเชื่อว่าเราเรียกเก็บเงินจากท่านโดยผิดพลาด และอยู่ภายใต้กฎหมายและระเบียบข้อบังคับที่บังคับใช้ ท่านต้องติดต่อเราที่ <a href="mailto:handbooq@ondemand.in.th">handbooq@ondemand.in.th</a> ภายใน 7 วัน
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>การโฆษณา</h2>
          <div>
            <div>
              <span className='ml-4'>
                เราอาจให้บริการทั้งหมดหรือบางส่วนแก่ท่านโดยไม่คิดค่าใช้จ่าย ในการพิจารณาดังกล่าว ท่านรับทราบและตกลงว่าเราอาจอนุญาตให้แสดงโฆษณาของบุคคลที่สาม โดยโฆษณาอาจมีลิงก์ไปยังเว็บไซต์และบริการออนไลน์ของบุคคลที่สามที่เรา ไม่ได้เป็นเจ้าของหรือไม่มีอำนาจควบคุมเว็บไซต์และบริการออนไลน์ดังกล่าว และเราไม่มีความรับผิดเกี่ยวกับการโต้ตอบที่ท่านอาจตัดสินใจมีกับโฆษณาดังกล่าว ซึ่งรวมถึงแต่ไม่จำกัดเพียงการพึ่งพาโฆษณาหรือธุรกรรมเชิงพาณิชย์ใดๆ ที่อาจเกิดขึ้นระหว่างท่านและ ผู้โฆษณา พึงระวังเมื่อท่านออกจากบริการ เราขอแนะนำให้ท่านอ่านข้อกำหนดและนโยบายความเป็นส่วนตัวของเว็บไซต์บุคคลที่สามและบริการออนไลน์ที่ท่านเยี่ยมชม
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>การยกเลิกการใช้บริการ</h2>
          <div>
            <div>
              <span className='ml-4'>
                หากท่านไม่ต้องการใช้บริการของเราอีกต่อไปและต้องการลบบัญชีของท่าน โปรดส่งอีเมลถึงเราที่ <a href="mailto:handbooq@ondemand.in.th">handbooq@ondemand.in.th</a> เราจะให้ความช่วยเหลือเพิ่มเติมและแนะนำท่านตลอดกระบวนการ เมื่อท่านเลือกที่จะลบบัญชีของท่าน ท่านจะไม่สามารถเปิดใช้งานบัญชีของท่านอีกครั้งหรือเรียกข้อมูลใด ๆ ของเนื้อหาหรือข้อมูลที่ท่านเพิ่ม (รวมถึงเนื้อหาฟรีและเนื้อหาที่ต้องชำระเงิน)
              </span>
            </div>
            <div>
              <span className='ml-4'>
              เราอาจใช้ดุลยพินิจ แต่เพียงผู้เดียวในการระงับหรือยุติบัญชีของท่าน ลบหรือลบเนื้อหาของท่าน (ตามที่กำหนดไว้ในที่นี้) และ/หรือปฏิเสธการใช้บริการใด ๆ และทั้งหมดในปัจจุบันหรือในอนาคตได้ตลอดเวลาโดยมีหรือไม่มีการแจ้งให้ทราบหากตามสมควร ในกรณีบัญชีของท่านไม่มีการใช้งานเป็นเวลานาน และท่านไม่ได้ใช้บัญชีของท่านอีกหลังจากแจ้งว่าไม่มีการใช้งานดังกล่าวถูกส่งไปยังที่อยู่อีเมลที่เชื่อมโยงกับบัญชีของท่านหรือทางข้อความในกล่องจดหมาย 
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>ข้อจำกัดความรับผิด</h2>
          <div>
            <div>
              <span className='ml-4'>
                ท่านรับทราบว่าเป็นดุลยพินิจของเราแต่เพียงผู้เดียวในการยุติการเข้าถึงการใช้บริการของท่าน และ/หรือบล็อกการเข้าถึงบริการของท่าน หากเราพิจารณาว่าท่านได้ละเมิด ข้อตกลงและเงื่อนไขที่เกี่ยวข้องกับการใช้บริการ ทั้งนี้ ขอบเขตความรับผิดชอบของเราจะถูกจำกัดแค่จำนวนเงินที่ท่านได้ชำระเพื่อซื้อสินค้าและหรือบริการเท่านั้น 
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>การเปลี่ยนแปลงข้อตกลงและเงื่อนไขการใช้บริการ </h2>
          <div>
            <div>
              <span className='ml-4'>
                เราสงวนสิทธิ์ในการเปลี่ยนแปลง ระงับ ยกเลิกบริการทั้งหมด หรือบางส่วนได้ตลอดเวลา ซึ่งเงื่อนไขเหล่านี้อาจได้รับการแก้ไขเนื่องจากเทคโนโลยีใหม่ หรือแม้แต่ในกรณีที่มีกฎหมายใหม่เกิดขึ้น ดังนั้น กรุณาตรวจสอบเงื่อนไขนี้เป็นครั้งคราวเมื่อเข้าใช้บริการ เราจะแจ้งให้ท่านทราบหากมีการเปลี่ยนแปลงอย่างมีนัยยะสำคัญเกิดขึ้นและจะขอคำยินยอมจากท่านในกรณีที่จำเป็นด้วยเช่นกัน
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>กฎหมายที่ใช้บังคับ</h2>
          <div>
            <div>
              <span className='ml-4'>
                การระงับข้อพิพาทที่เกิดจากข้อตกลงและเงื่อนไขการใช้บริการนี้ จะอยู่ภายใต้กฎหมายไทย และเขตอำนาจของศาลไทย โดยไม่พิจารณาถึงหลักกฎหมายขัดกัน นอกจากนี้ หากมีส่วนหนึ่งส่วนใดของ ข้อตกลงและเงื่อนไขนี้ ขัดหรือแย้งกับกฎหมายที่ใช้บังคับอยู่ในปัจจุบัน หรือที่จะถูกประกาศขึ้นในอนาคต เป็นเหตุให้ส่วนหนึ่งส่วนใดเช่นว่านั้นไม่สมบูรณ์ หรือไม่มีผลบังคับได้ หรือเป็นโมฆียะ หรือเป็นโมฆะ ไม่ว่าด้วยสาเหตุใดก็ตาม ให้ส่วนหนึ่งส่วนใดเฉพาะส่วนนั้นไม่สมบูรณ์ หรือไม่มีผลบังคับ หรือเป็นโมฆียะ หรือตกเป็นโมฆะ แล้วแต่กรณี แต่ทั้งนี้ไม่กระทบถึง ข้อตกลงและเงื่อนไขในส่วนที่เหลือซึ่งยังคงมีผลบังคับอยู่ และมีผลสมบูรณ์ตามกฎหมาย
              </span>
            </div>
          </div>
        </section>
        <section>
          <h2 className='bg-secondary p-2'>การติดต่อระหว่างท่านกับเรา</h2>
          <div>
            <div>
              <span className='ml-4'>
                เราจะดำเนินการติดต่อ แจ้งข้อมูล ข่าวสาร ที่ท่านควรทราบผ่านช่องออนไลน์ในแอปพลิเคชันเป็นหลัก โดยหากท่านมีปัญหาการใช้บริการ หรือต้องการสอบถามเกี่ยวกับบริการ สามารถติดต่อได้ที่ <a href="mailto:handbooq@ondemand.in.th">handbooq@ondemand.in.th</a> 
              </span>
            </div>
            <div className="mb-4">
              ประกาศเมื่อวันที่ 01/06/2565
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default TermsOfUsage;