import Menu from "./Menu";

export default function Navbar() {

  return (
    <div className="navbar-menu">
      <nav className="navbar d-none d-md-none d-lg-block">
        <div className="nav-actions">
          <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/logo.svg" className='mr-3' alt="logo"/>
        </div>
      </nav>
      <nav className="navbar navbar-expand-lg navbar-light bg-light d-block d-md-block d-lg-none">
        <div className="row col-12">
          <div className="col-10 col-sm-10 col-md-10">
            <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/logo.svg" className='mr-3' alt="logo"/>
          </div>
          <div className="col-2 col-sm-2 col-md-2">
            <button className="navbar-toggler float-right" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="navbarMenu">
          <Menu />
        </div>
      </nav>
    </div>
  );
};
