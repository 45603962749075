import { useDispatch } from "react-redux";
import { resetSuccess } from '../../store/resetPasswordSlice';

export default function CreatePasswordSucess() {
  let dispatch = useDispatch();
  dispatch(resetSuccess());
  return (
    <div className="wrapper-login background-login">
      <div className="card-login">
        <div className="">
          <h1 className="text-success">You're all signed up</h1>
          <p className="mt-3">
          To access your Handbooq account,  perform the following steps:
          </p>
          <p className="mt-3">
            1. Download Handbooq from the App Store here:<a className="text-success" href="https://apps.apple.com/th/app/handbooq/id1621843851"> DOWNLOAD HANDBOOQ</a>
          </p>
          <p className="mt-3">
            2. Once you’ve installed the Handbooq app, log in using the email you have provided us and the password you have just created.
          </p>
          <p className="mt-3">
            3. You’re in! Enjoy your Handbooq experience!
          </p>
        </div>
      </div>
    </div>
  );
}