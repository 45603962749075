import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaff, updateProfile } from '../../store/staffSlice';
import { 
    convertBlankInput,
    validateName,
    validateWebPassword,
    inputIsEmpty,
    validatePasswordConfirm, } from '../common/Validation';
import { useTranslation } from "react-i18next";

export default function StaffProfile() {
    const { t } = useTranslation();
    let form = useSelector(state => state.staff.form);

    const initialState = {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        ...form
    };

    let [{ name, email, currentPassword, password, passwordConfirmation }, setState] = useState(initialState);
    let [ nameAlert, setUserAlert ] = useState();
    let [ passAlert, setPassAlert ] = useState();
    let [ passConfirmAlert, setPassConfirmAlert ] = useState();
    let [ currentPassAlert, setCurrentPassAlert ] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        setState(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        dispatch(getStaff());
    }, [])

    const validation = (e) => {
        
        if (inputIsEmpty(currentPassword)) {
            setCurrentPassAlert(t('staff.popup.currentPasswordAlert'));
            return false;            
        } else {
            setCurrentPassAlert('');
        } 
        
        if (validateName(name)) {
            setUserAlert(t('staff.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }
        
        if (validateWebPassword(password) && password !== '') {
            setPassAlert(t('staff.popup.passAlert'));
            return false;
        } else {
            setPassAlert("");
        }

        if (validatePasswordConfirm(password, passwordConfirmation)){
            setPassConfirmAlert(t('staff.popup.passConfirmAlert'));
            return false;
        } else {
            setPassConfirmAlert("");
        }
        return true;
    }

    const save = (e) => {
        e.preventDefault();
        if (!validation()) { return }
        dispatch(updateProfile({
            name: convertBlankInput(name),
            currentPassword: currentPassword,
            password: password,
            responseAlert: t('staff.updateProfileSuccess')
        }));
        
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }))

        if (name === 'name' && validateName(value)) {
            setUserAlert(t('staff.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }

        if (name === 'currentPassword' && inputIsEmpty(value)) {
            setCurrentPassAlert(t('staff.popup.passAlert'));
            return false;
        } else {
            setCurrentPassAlert("");
        }
        
        if (name === 'password' && validateWebPassword(value)) {
            setPassAlert(t('staff.popup.passAlert'));
            return false;
        } else {
            setPassAlert("");
        }

        if (name === 'passwordConfirmation' && validatePasswordConfirm(password, value)){
            setPassConfirmAlert(t('staff.popup.passConfirmAlert'));
            return false;
        } else {
            setPassConfirmAlert("");
        }
    }

    return (
        <div className="">
            <div className="card-header">
                <h3>{ t('publisher.profile.title') }</h3>
            </div>
            
            <div className="card-body">
                <form encType="multipart/form-data" className="p-5">
                    <div className="form-group">
                        <label>{ t('staff.email') }</label>
                        <input type="text" className="form-control" value={email} name="email" readOnly={form} onChange={change} />
                    </div>

                    <div className="form-group">
                        <label>{ t('staff.name') }</label>
                        <input type="text" className="form-control" value={name} name="name" onChange={change} />
                        <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                    </div>

                    <div className="form-group">
                        <label>{ t('staff.currentPassword') }</label>
                        <input type="password" value={currentPassword} className="form-control" name="currentPassword" onChange={change} />
                        <small className={"form-text text-danger font-italic " + (currentPassAlert ? "" : "hidden")}>{currentPassAlert}</small>
                    </div>

                    <div className="form-group">
                        <label>{ t('staff.password') }</label>
                        <input type="password" value={password} className="form-control" name="password" onChange={change} />
                        <small className={"form-text text-danger font-italic " + (passAlert ? "" : "hidden")}>{passAlert}</small>
                    </div>
            
                    <div className="form-group">
                        <label>{ t('staff.passConfirm') }</label>
                        <input type="password" value={passwordConfirmation} className="form-control" name="passwordConfirmation" onChange={change} />
                        <small className={"form-text text-danger font-italic " + (passConfirmAlert ? "" : "hidden")}>{passConfirmAlert}</small>
                    </div>
                   
                    <div className="row">
                        <div className="col-8 md-8"></div>
                        <div className="actions col-2 md-2">
                            <button className="btn btn-secondary btn-submit" onClick={save}>{ t('shared.save') }</button>
                            <div className="fill"></div>
                        </div>
                    </div>                        
                </form>
            </div>
        </div>
    );
}