import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getProduct, updateProduct, uploadCoverImage, uploadPdf } from '../../store/productSlice';
import { getProductCategories } from '../../store/productCategorySlice';
import { getAuthors } from '../../store/authorSlice';
import { getPublishers } from '../../store/publisherSlice';
import { convertBlankInput, validateContentPage, validatePublicationYear, validateIsbn } from '../common/Validation';
import { RequiredIcon } from '../common/Icon';
import { FullScreenLoading } from '../common/FullScreenLoading';
import { useTranslation } from "react-i18next";
import { Loading } from '../common/Loading';

export default function ProductUpdate() {
  const { t } = useTranslation();
  let history = useHistory();
  if (useSelector(state => state.product.listChanged)) {
    history.push('/products');
  }

  let productId = useParams();
  let form = useSelector(state => state.product.form);
  let loadingUploadImg = useSelector(state => state.product.loadingUploadImg);
  let disableCreateBtn = useSelector(state => state.product.disableCreateBtn);
  let newCoverImgId = useSelector(state => state.product.coverImg);
  let newCoverImg = useSelector(state => state.product.coverImgLink);
  let loading = useSelector(state => state.product.loading);

  const initialState = {
    title: '',
    productCategoryId: '',
    subtitle: '',
    authorId: '',
    publicationYear: '',
    isbn: '',
    description: '',
    previewPageNumbers: '',
    contentPageNumber: '',
    coverImageId: null,
    coverImage: null,
    publisherId: null,
    erpId: null,
    pdf: null,
    ...form
  };

  let [{ coverImage, erpId, pdf, title, productCategoryId, subtitle, authorId, publicationYear, coverImageId, isbn, description, contentPageNumber, previewPageNumbers, publisherId }, setState] = useState(initialState);
  let [pdfAlert, setPdfAlert] = useState();
  let [imageAlert, setImageAlert] = useState();
  let [contentPageNumberAlert, setContentPageAlert] = useState();
  let [publicationYearAlert, setPublicationYearAlert] = useState();
  let [isbnAlert, setIsbnAlert] = useState();

  let listCategories = useSelector(state => state.productCategory.list);
  let listAuthor = useSelector(state => state.author.list);
  let listPublisher = useSelector(state => state.publisher.list);
  let role = useSelector(state => state.auth.role);
  let pdfError = useSelector(state => state.product.pdfError);
  let loadingUploadPdf = useSelector(state => state.product.loadingUploadPdf);
  let currentLogo = (newCoverImgId) ? newCoverImg : coverImage?.link;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductCategories({ size: '999999' }));
    dispatch(getAuthors({ size: '999999' }));
    dispatch(getProduct(productId.id));
    if (role === 'Admin') {
      dispatch(getPublishers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState(initialState);
  }, [form])

  let corruptedPdfId = useSelector(state => state.product.corruptedPdf);
  let pdfId = (corruptedPdfId) ? corruptedPdfId : pdf?.id;

  const onChangeImg = (data) => {
    if (typeof data.target.files[0] !== 'undefined') {
      dispatch(uploadCoverImage({
        img: data.target.files[0],
      }));
      setImageAlert(data.target.files[0].name);
    } else {
      setImageAlert('');
    }
  }

  const onChangePdf = (data) => {
    if (typeof data.target.files[0] !== 'undefined') {
      dispatch(uploadPdf({
        pdf: data.target.files[0],
      }));
      setPdfAlert(data.target.files[0].name);
    } else {
      setPdfAlert('');
    }
  }

  const reviewPageToArray = (preview) => {
    if (preview === '') {
      return null;
    }
    return "[" + preview + "]";
  }

  const save = (e) => {
    e.preventDefault();
    coverImageId = (newCoverImgId) ? newCoverImgId : coverImageId;
    pdfId = (corruptedPdfId) ? corruptedPdfId : pdfId;
    dispatch(updateProduct({
      id: productId.id,
      title: convertBlankInput(title),
      subtitle: convertBlankInput(subtitle),
      productCategoryId: convertBlankInput(productCategoryId),
      authorId: authorId,
      // publicationYear: convertBlankInput(publicationYear),
      // isbn: convertBlankInput(isbn),
      description: convertBlankInput(description),
      coverImageId: coverImageId,
      // previewPageNumbers: reviewPageToArray(previewPageNumbers),
      // contentPageNumber: convertBlankInput(contentPageNumber),
      publisherId: publisherId,
      erpId: erpId,
      pdfId: pdfId,
      // responseAlert: t('product.updateSuccess')
    }));
  }

  const change = (e) => {
    let { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
    // if (name === "contentPageNumber" && validateContentPage(value)) {
    //   setContentPageAlert(t('product.contentPageAlert'));
    //   return false;
    // } else if (name === "contentPageNumber") {
    //   setContentPageAlert("");
    // }

    // if (name === "publicationYear" && validatePublicationYear(value)) {
    //   setPublicationYearAlert(t('product.publicationAlert'));
    //   return false;
    // } else if (name === "publicationYear") {
    //   setPublicationYearAlert("");
    // }

    // if (name === "isbn" && validateIsbn(value)) {
    //   setIsbnAlert(t('product.isbnAlert'));
    //   return false;
    // } else if (name === "isbn") {
    //   setIsbnAlert("");
    // }
  }

  const renderSelectOptions = (data) => {
    return data.map((key) => {
      return (
        <option value={key.id}>{key.name}</option>
      )
    })
  }

  const renderSelectOptionPublishers = (data) => {
    return data.map((key) => {
      return (
        <option value={key.id}>{key.name}</option>
      )
    });
  }

  return (
    <div className="card">
      {loading && <FullScreenLoading />}
      <div className="card-header">
        <h3>{t('product.editProduct')}</h3>
      </div>
      <div className="card-body">
        <form encType="multipart/form-data">
          {(role === 'Admin') ?
            <div className="form-group">
              <label>{t('product.labPublisher')} <RequiredIcon /></label>
              <select name="publisherId" className="form-control" value={publisherId} onChange={change}>
                <option selected="true" disabled="disabled">{t('product.selectPublisher')}</option>
                {renderSelectOptionPublishers(listPublisher)}
              </select>
            </div>
            : null}

          <div className="row">
            <div className="form-group col-8 mb-8">
              <label>{t('product.labTitle')} <RequiredIcon /></label>
              <input name="title" type="text" className="form-control" value={title} onChange={change} placeholder={t('product.labTitle')} />
            </div>
            <div className="form-group col-4 mb-4">
              <label>{t('product.labERP')}</label>
              <input name="erpId" value={erpId} type="text" className="form-control" onChange={change} placeholder={t('product.labERP') + t('product.optional')} />
            </div>
          </div>

          <div className="form-group">
            <label>{t('product.labSubTitle')}</label>
            <input name="subtitle" type="text" className="form-control" value={subtitle} onChange={change} placeholder={t('product.labSubTitle') + t('product.optional')} />
          </div>

          <div className="row">
            <div className="form-group col-6 md-6">
              <label>{t('product.labAuthor')}</label>
              <select name="authorId" className="form-control" value={authorId} onChange={change}>
                <option value=''>{t('product.labSubTitle') + t('product.optional')}</option>
                {renderSelectOptions(listAuthor)}
              </select>
            </div>
            <div className="form-group col-6 md-6">
              <label>{t('product.labCategory')}</label>
              <select name="productCategoryId" className="form-control selectpicker" value={productCategoryId} onChange={change} >
                <option selected="true" disabled="disabled" value="">
                  {t('product.labCategory') + t('product.optional')}
                </option>
                {renderSelectOptions(listCategories)}
              </select>
            </div>
          </div>

          {/* <div className="row">
            <div className="form-group col-8 mb-8">
              <label>{t('product.labPublicationYear')}</label>
              <input name="publicationYear" type="number" min="1" className="form-control" value={publicationYear} onChange={change} placeholder={t('product.labPublicationYear') + t('product.optional')} />
              <small className={"form-text text-danger font-italic " + (publicationYearAlert ? "" : "hidden")}>{publicationYearAlert}</small>
            </div>
            <div className="form-group col-4 mb-4">
              <label>{t('product.labIsbn')}</label>
              <input name="isbn" type="text" className="form-control" value={isbn} onChange={change} placeholder={t('product.labIsbn') + t('product.optional')} />
              <small className={"form-text text-danger font-italic " + (isbnAlert ? "" : "hidden")}>{isbnAlert}</small>
            </div>
          </div> */}

          <div className="form-group">
            <label>{t('product.labDescription')}</label>
            <input required name="description" type="text" className="form-control" value={description} onChange={change} placeholder={t('product.labCategory') + t('product.optional')} />
          </div>

          {/* <div className="form-group">
            <label>{t('product.labContentPage')}</label>
            <input name="contentPageNumber" type="number" min="1" className="form-control" value={contentPageNumber} onChange={change} placeholder={t('product.labContentPage') + t('product.optional')} />
            <small className={"form-text text-danger font-italic " + (contentPageNumberAlert ? "" : "hidden")}>{contentPageNumberAlert}</small>
          </div>
          <div className="form-group">
            <label>{t('product.labPreviewPage')}</label>
            <input name="previewPageNumbers" type="text" className="form-control" value={previewPageNumbers} onChange={change} placeholder={t('product.plaPreviewPage')} />
          </div> */}

          <div className="form-group">
            <label>{t('product.labPdf')}</label>
            <input readOnly type="text" className="form-control" value={(pdf) ? pdf.fileName : ''} />
          </div>

          <div className="row">
            <div className="col-12 col-sm-4 col-md-4">
              <div className="form-group col-6 mb-6 input-upload">
                <label for='upload-pdf'>
                  <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/upload-pdf.svg" alt="Upload PDF" /> <RequiredIcon />
                </label>
                <input disabled={disableCreateBtn} name="pdf" id="upload-pdf" type="file" className="form-control" onChange={onChangePdf} accept="application/pdf" />
                <div className='row p-1'>
                  <small className={"ml-2 form-text text-danger font-italic " + (pdfAlert ? "" : "hidden")}>
                    {pdfError ? 'Upload failed, please try again' : pdfAlert}
                  </small>
                  {loadingUploadPdf &&
                    <div className="loading ml-3">
                      <Loading />
                    </div>
                  }
                </div>
                <div><small>[{t('shared.pdfType')}]</small></div>
              </div>
            </div>

            <div className="row col-12 col-sm-8 col-md-8">
              <div className="form-group col-6 mb-6 input-upload">
                <label for='upload-cover-image'>
                  <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/change-cover-image.svg" alt="Change Cover" />
                </label>
                <input name="img" id="upload-cover-image" type="file" className="form-control" onChange={onChangeImg} accept="image/png, image/jpeg" />
                <div className='row p-1'>
                  <small className={"form-text font-italic " + (imageAlert ? "" : "hidden")}>{imageAlert}</small>
                  {loadingUploadImg &&
                    <div className="loading ml-3">
                      <div className="threedots">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="form-group col-6 mb-6">
                <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}className="img-product" src={currentLogo} alt="product" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 md-8"></div>
            <div className="actions col-2 md-2">
              <button disabled={disableCreateBtn} className="btn btn-secondary btn-submit" onClick={save}>{t('shared.save')}</button>
              <div className="fill"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}