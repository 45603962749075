import { DateTime } from "luxon";
import { inputIsEmpty } from "./Validation";

export function formatDate(input) {
  return DateTime.fromISO(input).toFormat('dd/MM/yyyy');
}

export function formatPhoneNumber(input) {
  if (inputIsEmpty(input)) {
    return '';
  }
  return input.replace(/\s+/g, '');
}