import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const signUpSlice = createSlice({
    name: 'signUp',
    initialState: {
        user: null,
        accessToken: null,
        isSignUp: false,
        loading: false,
        error: null
    },
    reducers: {
        signUpRequested: (state, action) => {
            state.loading = true;
            state.isSignUp = false;
        },
        signUpSuccess: (state, action) => {
            state.loading = false;
            state.isSignUp = true;
        },
        signUpError: (state, action) => {
            state.loading = false;
            state.isSignUp = false;
            state.error = action.payload.error;
        },
    }
});

const {
    signUpRequested,
    signUpSuccess,
    signUpError,
} = signUpSlice.actions;

const signUp = (email, username, password) => (dispatch) => {
    dispatch(
        actions.apiCallBegan(
            {
                url: '/api/v1/publishers/sign_up',
                method: "POST",
                data: {
                    email: email,
                    username: username,
                    password: password,
                },
                onStart: signUpRequested.type,
                onSuccess: signUpSuccess.type,
                onError: signUpError.type
            }
        )
    );
}

export { signUp };

export default signUpSlice.reducer;