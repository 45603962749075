import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createAuthor, updateAuthor } from '../../store/authorSlice';
import Icon from "../common/Icon";
import { t } from 'i18next';

const AuthorPopup = () => {
    let loading = useSelector(state => state.author.loading);
    let popup = useSelector(state => state.author.popup);
    let form = useSelector(state => state.author.form);

    const initialState = {
        name: '',
        description: '',
        ...form
    };

    let [{ name, description }, setState] = useState(initialState);
    let [ nameAlert, setNameAlert ] = useState();
    
    useEffect(() => { 
        setState(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        dispatch(closePopup());
    };

    const validation = (e) => {
        if ( name === '') {
            setNameAlert(t('author.nameAlert')); 
            return false;
        } else {
            setNameAlert("");
        }
        return true;
    }

    const create = (e) => {
        if (!validation()){
            return;
        }

        dispatch(createAuthor({
            name: name,
            description: description,
            responseAlert: t('author.createSuccess')
        }));
    }

    const update = (e) => {
        e.preventDefault();
        dispatch(updateAuthor({
            id: form.id,
            name: name,
            description: description,
            responseAlert: t('author.updateSuccess')
        }));
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }))
    }

    return (
        <div className={"popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">{ t('author.editAuthor') }</h5>
                                :
                                <h5 className="card-title">{ t('author.newAuthor') }</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">
                            <form >
                                <div className="form-group">
                                    <label>{ t('author.name') }</label>
                                    <input type="text" className="form-control" value={name} name="name" onChange={change} />
                                    <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                                </div>

                                <div className="form-group">
                                    <label>{ t('author.description') }</label>
                                    <textarea className="form-control" value={description} name="description" onChange={change} ></textarea>
                                </div>
                            </form>

                            <div className="actions">
                                {form ?
                                    <button onClick={update} className="btn btn-primary">{ t('shared.update') }</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">{ t('shared.create') }</button>
                                }
                                <div className="fill"></div>

                                {loading &&
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthorPopup;