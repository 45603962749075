import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createProduct, uploadPdf, uploadCoverImage } from '../../store/productSlice';
import { getProductCategories } from '../../store/productCategorySlice';
import { getAuthors } from '../../store/authorSlice';
import { getPublishers } from '../../store/publisherSlice';
import { 
    inputIsEmpty,
    convertBlankInput, 
    validateUploadImage, 
    validateContentPage, 
    validatePublicationYear,
    validateIsbn } from '../common/Validation';
import { Loading } from '../common/Loading';
import { FullScreenLoading } from '../common/FullScreenLoading';
import { RequiredIcon } from '../common/Icon';
import { useTranslation } from "react-i18next";

export default function ProductCreate() {
    const { t } = useTranslation();
    let form = useSelector(state => state.product.form);
    let role = useSelector(state => state.auth.role);
    let access = useSelector(state => state.auth.access.publisher?.id);

    const initialState = {
        title: '',
        category: '',
        subTitle: '',
        author: '',
        description: '',
        publisherId: null,
        erpId: null,
        // publicationYear: '',
        // isbn: '',
        // previewPageNumbers: '',
        // contentPageNumber: '',
        ...form
    };
    

    let [{ title, erpId, category, subTitle, author, description, publisherId }, setState] = useState(initialState);
    // publicationYear, isbn, contentPageNumber, previewPageNumbers, 
    let [ pdfAlert, setPdfAlert ] = useState();
    let [ imageAlert, setImageAlert ] = useState();
    // let [ contentPageNumberAlert, setContentPageAlert ] = useState();
    // let [ publicationYearAlert, setPublicationYearAlert ] = useState();
    // let [ isbnAlert, setIsbnAlert ] = useState();
    let [ titleAlert, setTitleAlert ] = useState();

    useEffect(() => {
        dispatch(getProductCategories({size: '999999'}));
        dispatch(getAuthors({size: '999999'}));
        if (role === 'Admin') {
            dispatch(getPublishers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setState(initialState);
    }, [form]);

    let history = useHistory();
    let loadingUploadPdf = useSelector(state => state.product.loadingUploadPdf);
    let loadingUploadImg = useSelector(state => state.product.loadingUploadImg);
    let disableCreateBtn = useSelector(state => state.product.disableCreateBtn);
    let listCategories = useSelector(state => state.productCategory.list);
    let listAuthor = useSelector(state => state.author.list);
    let listPublisher = useSelector(state => state.publisher.list);
    let coverImgId = useSelector(state => state.product.coverImg);
    let corruptedPdfId = useSelector(state => state.product.corruptedPdf);
    let loading = useSelector(state => state.product.loading);
    let pdfError = useSelector(state => state.product.pdfError);
    let imageError = useSelector(state => state.product.imageError);

    if (useSelector( state => state.product.listChanged )) {
        history.push('/products');
    }

    const dispatch = useDispatch();

    const onChangePdf = (data) => {
        if (typeof data.target.files[0] !== 'undefined') {
            dispatch(uploadPdf({
                pdf: data.target.files[0],
            }));
            setPdfAlert(data.target.files[0].name);
        } else {
            setPdfAlert('');
        }
    }

    const onChangeImg = (data) => {
        let file = data.target.files[0];
        if (!validateUploadImage(file)) {
            dispatch(uploadCoverImage({
                img: file,
            }));
            setImageAlert(file.name);
        } else {
            setImageAlert(t('shared.fileSizeError'));
        }
    }

    const reviewPageToArray = (preview) => {
        if (preview === '') {
            return null;
        } 

        return "[" + preview + "]";
    }

    const validation = () => {
        if (inputIsEmpty(title)) {
            setTitleAlert(t('product.titleAlert')); 
            return false;
        } else {
            setTitleAlert("");
        }

        if (corruptedPdfId === null) {
            setPdfAlert(t('product.pdfAlert'));
            return false;
        } else {
            setPdfAlert("");
        }

        return true;
    }

    const publish = (e) => {
        e.preventDefault();
        if (!validation()) {
            return false;
        }
        publisherId = (role === 'Admin') ? publisherId : access;
        dispatch(createProduct({
            title: convertBlankInput(title),
            subtitle: convertBlankInput(subTitle),
            productCategoryId: convertBlankInput(category),
            authorId: convertBlankInput(author),
            // publicationYear: convertBlankInput(publicationYear),
            // isbn: convertBlankInput(isbn),
            description: convertBlankInput(description),
            pdfId: corruptedPdfId,
            coverImageId: coverImgId,
            // previewPageNumbers: reviewPageToArray(previewPageNumbers),
            // contentPageNumberNumber: convertBlankInput(contentPageNumber),
            publisherId: publisherId,
            status: 'published',
            erpId: erpId,
            responseAlert: t('product.createSuccess')
        }));
    }

    const save = (e) => {
        e.preventDefault();
        if (!validation()) {
            return false;
        }
        publisherId = (role === 'Admin') ? publisherId : access;
        dispatch(createProduct({
            title: convertBlankInput(title),
            subtitle: convertBlankInput(subTitle),
            productCategoryId: convertBlankInput(category),
            authorId: convertBlankInput(author),
            // publicationYear: convertBlankInput(publicationYear),
            // isbn: convertBlankInput(isbn),
            description: convertBlankInput(description),
            pdfId: corruptedPdfId,
            coverImageId: coverImgId,
            // previewPageNumbers: reviewPageToArray(previewPageNumbers),
            // contentPageNumberNumber: convertBlankInput(contentPageNumber),
            publisherId: publisherId,
            status: 'unpublished',
            erpId: erpId,
            responseAlert: t('product.createSuccess')
        }));
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
        // if (name === "contentPageNumber" && validateContentPage(value)) {
        //     setContentPageAlert(t('product.contentPageAlert')); 
        //     return false;
        // } else if (name === "contentPageNumber") {
        //     setContentPageAlert("");
        // }

        // if (name === "publicationYear" && validatePublicationYear(value)) {
        //     setPublicationYearAlert(t('product.publicationAlert')); 
        //     return false;
        // } else if (name === "publicationYear") {
        //     setPublicationYearAlert("");
        // }

        // if (name === "isbn" && validateIsbn(value)) {
        //     setIsbnAlert(t('product.isbnAlert')); 
        //     return false;
        // } else if (name === "isbn") {
        //     setIsbnAlert("");
        // }
    }

    const renderSelectOptions = (data) => {
        if (data.map) {
            return data.map((key) => {
                return (
                    <option value={key.id}>{key.name}</option>
                )
            });
        }
    }

    const renderSelectOptionPublishers = (data) => {
        if (data.map) {
            return data.map((key) => {
                return (
                    <option value={key.id}>{key.name}</option>
                )
            });
        }
    }

    return (
        <div className="card">
            { loading && <FullScreenLoading /> }
            <div className="card-header">
                <h3>{ t('product.newProduct') }</h3>
            </div>
            <div className="card-body">
                <form encType="multipart/form-data">
                    
                { (role === 'Admin') ?
                    <div className="form-group">
                        <label>{ t('product.labPublisher') }<RequiredIcon /></label>
                        <select name="publisherId" className="form-control" value={publisherId} onChange={change}>
                            <option selected="true" disabled="disabled">{ t('product.selectPublisher') }</option>
                            { renderSelectOptionPublishers(listPublisher) }
                        </select>
                    </div>
                : null }

                    <div className="row">        
                        <div className="form-group col-8 mb-8">
                            <label>{ t('product.labTitle') } <RequiredIcon /></label>
                            <input name="title" type="text" className="form-control" onChange={change} placeholder={ t('product.labTitle') } />
                            <small className={"form-text text-danger font-italic " + (titleAlert ? "" : "hidden")}>{titleAlert}</small>
                        </div>
                        <div className="form-group col-4 mb-4">  
                            <label>{ t('product.labERP') }</label>
                            <input name="erpId" type="text" className="form-control" onChange={change} placeholder={ t('product.labERP') + t('product.optional') } />                          
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>{ t('product.labSubTitle') }</label>
                        <input name="subTitle" type="text" className="form-control" value={subTitle} onChange={change} placeholder={ t('product.labSubTitle') + t('product.optional') } />
                    </div>

                    <div className="row">        
                        <div className="form-group col-6 mb-6">
                            <label>{ t('product.labAuthor') }</label>
                            <select name="author" className="form-control" value={author} onChange={change}>
                                <option value=''>{ t('product.labAuthor') + t('product.optional') }</option>
                                { renderSelectOptions(listAuthor) }
                            </select>
                        </div>
                        <div className="form-group col-6 mb-6">
                            <label>{ t('product.labCategory') }</label>
                            <select name="category" className="form-control selectpicker" onChange={change} >
                                <option selected="true" disabled="disabled">
                                    { t('product.labCategory') + t('product.optional') }
                                </option>
                                { renderSelectOptions(listCategories) }
                            </select>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="form-group col-8 mb-8">
                            <label>{ t('product.labPublicationYear') }</label>
                            <input name="publicationYear" type="number" min="1" className="form-control" onChange={change} placeholder={ t('product.labPublicationYear') + t('product.optional') } />
                            <small className={"form-text text-danger font-italic " + (publicationYearAlert ? "" : "hidden")}>{publicationYearAlert}</small>
                        </div>
                        <div className="form-group col-4 mb-4">
                            <label>{ t('product.labIsbn') }</label>
                            <input name="isbn" type="text" className="form-control" onChange={change} placeholder={ t('product.labIsbn') + t('product.optional') } />
                            <small className={"form-text text-danger font-italic " + (isbnAlert ? "" : "hidden")}>{isbnAlert}</small>
                        </div>
                    </div> */}

                    <div className="form-group">
                        <label>{ t('product.labDescription') }</label>
                        <input name="description" type="text" className="form-control" value={description} onChange={change} placeholder={ t('product.labDescription') + t('product.optional') } />
                    </div>
        
                    {/* <div className="form-group">
                        <label>{ t('product.labContentPage') }</label>
                        <input name="contentPageNumber" type="number" min="1" className="form-control" onChange={change} placeholder={ t('product.labContentPage') + t('product.optional') } />
                        <small className={"form-text text-danger font-italic " + (contentPageNumberAlert ? "" : "hidden")}>{contentPageNumberAlert}</small>
                    </div>
                    <div className="form-group"> 
                        <label>{ t('product.labPreviewPage') }</label>                           
                        <input name="previewPageNumbers" type="text" className="form-control" onChange={change} placeholder={ t('product.plaPreviewPage') } />
                    </div> */}


                    <div className="row">
                        <div className="form-group col-6 mb-6 input-upload">
                            <label for='upload-pdf'>
                                <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/upload-pdf.svg" alt="Upload PDF" /> <RequiredIcon />
                            </label>
                            <input disabled={disableCreateBtn} name="pdf" id="upload-pdf" type="file" className="form-control"  onChange={onChangePdf} accept="application/pdf"/>
                            <div className='row p-1'>
                                <small className={"ml-2 form-text text-danger font-italic " + (pdfAlert ? "" : "hidden")}>
                                    { pdfError ? 'Upload failed, please try again' : pdfAlert }
                                </small>
                                {loadingUploadPdf &&
                                    <div className="loading ml-3">
                                        <Loading />
                                    </div>
                                }                                
                            </div>
                            <div><small>[{ t('shared.pdfType') }]</small></div>
                        </div>

                        <div className="form-group col-6 mb-6 input-upload">
                            <label for='upload-cover-image'>
                                <img onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src="/img/unupload-logo.svg";
                            }}src="/img/upload-cover-image.svg" alt="Upload Cover" />
                            </label>
                            <input name="img" id="upload-cover-image" type="file" className="form-control"  onChange={onChangeImg} accept="image/png, image/jpeg"/>
                            <div className='row p-1'>
                                <small className={"ml-2 form-text text-danger font-italic " + (imageAlert ? "" : "hidden")}>
                                    { imageError ? 'Upload failed. please try again' : imageAlert }
                                </small>
                                {loadingUploadImg &&
                                    <div className="loading ml-3">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div><small>[{ t('shared.imageType') }]</small></div>
                            <div><small>[{ t('shared.imageSize') }]</small></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8 md-8"></div>

                        <div className="actions col-2 md-2">
                            <button disabled={disableCreateBtn} className="btn btn-secondary btn-submit" onClick={save}>{ t('shared.draft') }</button>
                            <div className="fill"></div>
                        </div>

                        <div className="actions col-2 md-2">
                            <button disabled={disableCreateBtn} className="btn btn-primary btn-submit" onClick={publish}>{ t('shared.publish') }</button>
                            <div className="fill"></div>
                        </div>
                    </div>                        
                </form>
            </div>
        </div>
    );
}