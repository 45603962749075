const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]|[!@#$%^&()_+\-=\[\]{};':"\\|,.<>\/?]).{8,32}$/;
const oldPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{5,40}$/;
const isBnRegex = /^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/;
const numberRegex = /^\d+$/;
const otpRegex = /^\d{6}/;
const phoneRegex = /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/;

export function existsValue(val) {
  if (val === 'undefined') {
    return '';
  }

  return val;
}

export function inputIsEmpty(input) {
  if ( input === "" || input === null || input === undefined) {
    return true;
  }

  return false;
}

export function validateEmail(email) {
  if (inputIsEmpty(email) || !emailRegex.test(email)) {
    return true;
  }

  return false;
}

export function validateName(name) {
  if (inputIsEmpty(name)) {
    return true;
  }

  return false;
}

export function validatePassword(password) {  
  if (inputIsEmpty(password) || !passwordRegex.test(password)) {
    return true;
  }  
  
  return false;
}

export function validateWebPassword(password) {
  if (inputIsEmpty(password) || !oldPasswordRegex.test(password )) {
    return true;
  }

  return false;
}

export function validatePasswordConfirm(password, passwordConfirm) {
  if (password !== passwordConfirm) {
    return true;
  }

  return false;
}

export function convertBlankInput(input) {
  if (input === '' || input === null){
    return null
  }
  let converted = input.toString().replace(/ /g, "");
  if (converted === '') {
    return null;
  }

  return input;
}

export function validateUploadImage(file) {
  if (typeof file === 'undefined' || file.size > process.env.IMAGE_MAX_SIZE) {
    return true;
  }

  return false;
}

export function validateContentPage(input) {
  if (input !== '') {
    if (input <=0 || !numberRegex.test(input)) {
      return true;
    }
  } 

  return false;
}

export function validatePublicationYear(input) {
  if (input !== '') {
    if (input <=0 || !numberRegex.test(input)) {
      return true;
    }
  } 
  
  return false;
}

export function validateIsbn(input) {
  if (input !== '' && !isBnRegex.test(input)) {
    return true;
  }

  return false;
}

export function validateOTP(input) {
  if (inputIsEmpty(input) || !otpRegex.test(input)) {
    return true;
  }

  return false;
}

export function validateBirthday(input) {
  return false;
}

export function validatePhone(input) {
  if (inputIsEmpty(input) || !phoneRegex.test(input)) {
    return true;
  }
  
  return false;
}