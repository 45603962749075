import { createSlice } from "@reduxjs/toolkit";
import * as actions from './api';

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        loading: false,
        error: null,
        user_count: 0,
        popup: false,
    },
    reducers: {
        dashboardRequested: (state, action) => {
            state.loading = true;
        },
        statReceived: (state, action) => {
            state.loading = false;
            state.userCount = action.payload.user_count;
        },
        dashboardError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    }
});

const {
    dashboardRequested,
    statReceived,
    dashboardError
} = dashboardSlice.actions;

const getStat = () => (dispatch, getState) => {
    const accessToken = getState().auth.accessToken;
    dispatch(
        actions.apiCallBegan(
            {
                url: `/dashboard`,
                method: 'GET',
                headers: {
                    'X-Access-Token': accessToken
                },
                onStart: dashboardRequested.type,
                onSuccess: statReceived.type,
                onError: dashboardError.type
            }
        )
    );
}

export { getStat };

export default dashboardSlice.reducer;

