import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AdminPortal from './components/AdminPortal';
import PublisherPortal from './components/PublisherPortal';
import MessageBlock from './components/common/MessageBlock';
import CreatePassword from './components/CreatePassword';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import CustomerSignUp from './components/customers/CustomerSignUp';
import AdminLogin from './components/admins/AdminLogin';
import StaffLogin from './components/staffs/StaffLogin';
import StaffPortal from './components/StaffPortal';
import CustomerLogin from './components/customers/CustomerLogin';
import ProductPortal from './components/ProductPortal';
import ProductCategoryPortal from './components/ProductCategoryPortal'
import AuthorPortal from './components/AuthorPortal';
import TransactionPortal from './components/TransactionPortal';
import ResetPasswordSucess from './components/alertPage/ResetPasswordSuccess';
import CreatePasswordSucess from './components/alertPage/CreatePasswordSuccess';
import StudentPortal from './components/StudentPortal';
import Welcome from './components/Welcome';
import Policy from './components/Policy';
import TermsOfUsage from './components/TermsOfUsage';
import ErrorPage  from './components/ErrorPage';
import Test from './components/temp/Test';
import DashboardPortal from './components/DashboardPortal';

function App() {
  return (
    <Router>
      <Switch>

        <Route path="/aqua/test">
          <Test />
        </Route>

        <Route path="/dashboard">
          <DashboardPortal />
        </Route>

        <Route path="/terms-of-usage">
          <TermsOfUsage />
        </Route>

        <Route path="/privacy-policy">
          <Policy />
        </Route>

        <Route path="/students">
          <StudentPortal />
        </Route>
        
        <Route path="/transactions">
          <TransactionPortal />
        </Route>

        <Route path="/authors">
          <AuthorPortal />
        </Route>

        <Route path="/product-categories">
          <ProductCategoryPortal />
        </Route>

        <Route path="/products">
          <ProductPortal />
        </Route>

        <Route path="/login">
          <StaffLogin />
        </Route>

        <Route path="/admins/login">
          <AdminLogin />
        </Route>

        <Route path="/admins">
          <AdminPortal />
        </Route>

        <Route path="/profile">
          <StaffPortal />
        </Route>

        <Route path="/staffs">
          <StaffPortal />
        </Route>

        <Route path="/customers/login">
          <CustomerLogin />
        </Route>

        <Route path="/customer/sign-up">
          <CustomerSignUp />
        </Route>

        <Route path="/publishers">
          <PublisherPortal />
        </Route>

        <Route path="/staffs">
          <StaffPortal />
        </Route>

        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>

        <Route path="/reset-password/success">
          <ResetPasswordSucess />
        </Route>

        <Route path="/reset-password">
          <ResetPassword />
        </Route>

        <Route path="/create-password/success">
          <CreatePasswordSucess />
        </Route>

        <Route path="/create-password">
          <CreatePassword />
        </Route>

        <Route exact path="/">
          <Welcome />
        </Route>

        <Route path="*">
          <ErrorPage />
        </Route>

      </Switch>

      <MessageBlock />
    </Router>
  );
}

export default App;